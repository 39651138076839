import React, { Component } from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import PerfectScrollbar from "react-perfect-scrollbar";

export default class ChatExample extends Component {

    render() {
        return (
          <>
            <div className="scroll-area-sm">
              <PerfectScrollbar>
                <div className="p-3">
                  <VerticalTimeline layout="1-column" className="vertical-without-time">
                    <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-success"> {" "} </i>
                      }>
                      <h4 className="timeline-title">Chiller Electricity Metering</h4>
                      <p>
                      Scheduled today, at{" "}
                        <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>
                          12:00 PM
                        </a>
                      </p>
                    </VerticalTimelineElement>
                    {/* <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-warning"> {" "} </i>
                      }>
                      <p>
                        IoT Edge Training today, at{" "}
                        <b className="text-danger">13:00 PM</b>
                      </p>
                      <p>
                        CRAC Maintenance today, at{" "}
                        <span className="text-success">15:00 PM</span>
                      </p>
                    </VerticalTimelineElement> */}
                    <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-danger"> {" "} </i>
                      }>
                      <h4 className="timeline-title">
                        Review Energy Consumption
                      </h4>
                      <p>
                        Compile energy consumption report indicating where enhancements can be made for efficiency improvement.
                      </p>
                    </VerticalTimelineElement>
                    {/* <VerticalTimelineElement
                      className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-primary"> {" "} </i>
                      }>
                      <h4 className="timeline-title text-success">
                        Something not important
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amit,consectetur elit enim at minim
                        veniam quis nostrud
                      </p>
                    </VerticalTimelineElement> */}
                    {/* <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-success">
                          {" "}
                        </i>
                      }>
                      <h4 className="timeline-title">All Hands Meeting</h4>
                      <p>
                        Lorem ipsum dolor sic amet, today at{" "}
                        <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()}>
                          12:00 PM
                        </a>
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-warning">
                          {" "}
                        </i>
                      }>
                      <p>
                        Another meeting today, at{" "}
                        <b className="text-danger">12:00 PM</b>
                      </p>
                      <p>
                        Yet another one, at{" "}
                        <span className="text-success">15:00 PM</span>
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-danger">
                          {" "}
                        </i>
                      }>
                      <h4 className="timeline-title">
                        Build the production release
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amit,consectetur eiusmdd tempor
                        incididunt ut labore et dolore magna elit enim at minim
                        veniam quis nostrud
                      </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item"
                      icon={
                        <i className="badge badge-dot badge-dot-xl badge-primary">
                          {" "}
                        </i>
                      }>
                      <h4 className="timeline-title text-success">
                        Something not important
                      </h4>
                      <p>
                        Lorem ipsum dolor sit amit,consectetur elit enim at minim
                        veniam quis nostrud
                      </p>
                    </VerticalTimelineElement> */}
                  </VerticalTimeline>
                </div>
              </PerfectScrollbar>
            </div>
          </>
        );
    }
}