import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';
import { BuildingState } from '../../../redux/types/building.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../../components/redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
    Buildings: BuildingState;
};

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

const MTSDashboard: React.FC<Props> = ({Buildings}) => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Sensor"
                        subheading="Lighting sensors are sophisticated devices used to measure and respond to various properties of light within an environment. These sensors can detect lux, the unit of illumination that quantifies how much light falls on a surface, helping to maintain optimal lighting levels for activities and energy efficiency. They also measure lumens, which indicate the total amount of visible light emitted by a source, and nits, a term used to describe the brightness of displays by measuring the light intensity per unit area. Furthermore, lighting sensors can analyze the color temperature of light, expressed in Kelvin, which affects how warm or cool the light appears—crucial for setting the right mood or functional ambiance in a space. Advanced sensors may also track the heat patterns associated with different lighting installations, aiding in the management of heat outputs in temperature-sensitive environments."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 buildings={Buildings}/>
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

// // const mapStateToProps = (state: StoreState.All): ArticleState => {
// //     return {
// //       state: state
// //     };
// //   };

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
        Buildings: state.Buildings
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <MTSDashboard {...props} />;
});