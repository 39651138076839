import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState
  };

type Props = ConnectedProps<typeof connector> & OwnProps;

const SPACEDashboard: React.FC<Props> = () => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Space Utilisation"
                        subheading="Understand daily, weekly and seasonal patterns of occupancy to analyse the value of leased space or coordinate services like catering, cleaning and scheduling."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 />
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <SPACEDashboard {...props} />;
});