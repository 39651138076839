import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryLegend } from 'victory';

import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 
import { useTable, Column, useSortBy } from "react-table";

import METER_TYPE_1 from '../../BMSComponents/components/meter_type_1';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import LuminaireDataService from "../../../../firebase/services/luminaire.service";
import CallbackQueue from "../../../../firebase/services/callback.service";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

import {ILcmData, ILcmMetaData, ExtendedILcmData} from '../../../../firebase/types/lcm.type';
import {MeterChartData, MeterChartDisplay} from '../../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen } from '../../../../utils/timestamp';
import MeterFilterData from '../../../components/meterFilterData';

import { BuildingState } from '../../../../redux/types/building.type';

const makeNewData: IExtendedEnergyData =
      {
        meta: {
          name: { present_value: "Energy" },
          timestamp: { present_value: "2023-03-28T09:30:00Z" },
          alarm: { present_value: "Healthy" },
        },
        metering:[
        {
          name:"CO-2",
          energy_use:{
            percent:{present_value: 42},
            kw:{present_value:139}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
        {
          name:"CO-3",
          energy_use:{
            percent:{present_value: 35},
            kw:{present_value:126}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
         {
          name:"CO-4",
          energy_use:{
            percent:{present_value: 0},
            kw:{present_value:0}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
         {
          name:"CO-5",
          energy_use:{
            percent:{present_value: 0},
            kw:{present_value:0}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
        {
          name:"TX-14",
          energy_use:{
            percent:{present_value: 9},
            kw:{present_value:197.1}},
          max_capacity:{present_value: 2000},
          current_target:{present_value: 1150}
        },
        {
          name:"TX-15",
          energy_use:{
            percent:{present_value: 57},
            kw:{present_value:1147.4}},
          max_capacity:{present_value: 2000},
          current_target:{present_value: 1150}
        }
        ]
      }

function Meter_Type_1_DeviceHolder () {
  let data = {
    "chiller_removed_from_sequence": {
      "present_value":  "false"
    },
    "chiller_enable_status": {
      "present_value":  "false"
    },
    "isolation_valve_failed": {
      "present_value":  "false"
    },
    "percentage_total_capacity": {
      "present_value":  0
    },
    "entering_water_temp": {
      "present_value":  9.78
    },
    "leaving_water_temp": {
      "present_value":  10.06
    },
    "chiller_shutdown_alarm": {
      "present_value":  "false"
    },
    "isolation_valve_open": {
      "present_value":  "false"
    },
    "isolation_valve_enable": {
      "present_value":  "false"
    },
    "chiller_enabled": {
      "present_value":  "false"
    },
    "chiller_required": {
      "present_value":  "false"
    },
    "status": {
      "present_value":  "true"
    },
    "partial_alarm": {
      "present_value":  "false"
    },
    "shutdown_alarm": {
      "present_value":  "false"
    },
    "suction_pressure_a": {
      "present_value":  316.59
    },
    "suction_pressure_b": {
      "present_value":  325.01
    },
    "discharge_pressure_a": {
      "present_value":  344.51
    },
    "discharge_pressure_b": {
      "present_value":  349.78
    },
    "machine_operation_hours": {
      "present_value":  16903
    },
    "machine_starts": {
      "present_value":  33865
    },
    "pump_duty": {
      "present_value":  2
    },
    "pump_flow_proving": {
      "present_value":  "false"
    },
    "compressor_a_hours_run": {
      "present_value":  8561
    },
    "compressor_a_starts": {
      "present_value":  17926
    },
    "compressor_b_hours_run": {
      "present_value":  10897
    },
    "compressor_b_starts": {
      "present_value":  17693
    },
    "chiller_fan_a1_hours_run": {
      "present_value":  8486.25
    },
    "chiller_fan_a2_hours_run": {
      "present_value":  8185.25
    },
    "chiller_fan_a3_hours_run": {
      "present_value":  7747.5
    },
    "chiller_fan_a4_hours_run": {
      "present_value":  7035.75
    },
    "chiller_fan_a5_hours_run": {
      "present_value":  6574.75
    },
    "chiller_fan_a6_hours_run": {
      "present_value":  6012.25
    },
    "chiller_fan_a7_hours_run": {
      "present_value":  8681.75
    },
    "chiller_fan_b1_hours_run": {
      "present_value":  10818
    },
    "chiller_fan_b2_hours_run": {
      "present_value":  10420.5
    },
    "chiller_fan_b3_hours_run": {
      "present_value":  9761.75
    },
    "chiller_fan_b4_hours_run": {
      "present_value":  9155.25
    },
    "chiller_fan_b5_hours_run": {
      "present_value":  9869.5
    }
  };
return (
  
      <div className='meter-holder'>

        <METER_TYPE_1 data={data}/>

      </div>

);
}

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  interface ReactTableProps<T extends object> {
    data: T[];
    columns: Column<T>[];
  }



  function ReactTable<T extends object>({ data, columns }: ReactTableProps<ExtendedILcmData>) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid grey' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '2px solid grey' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '5px',
                    backgroundColor: '#545cd8',
                    border: '1px solid grey',
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: rowIndex !== rows.length - 1 ? '1px solid grey' : undefined }}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '5px',
                        backgroundColor: cellIndex % 2 === 0 ? 'white' : 'lightgrey',
                        textAlign: 'center'
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
    
    
  }
  
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
  `
  
  type Props = {
    buildings: BuildingState
  };

  type State = {
      flattenedPointset: Array<ExtendedILcmData>,
      metadata: Array<ILcmMetaData>,
      brickschema: {},
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: [],
      chartData: MeterChartData;
      chartDisplay: MeterChartDisplay;
      meteringData: IExtendedEnergyData;
  };

  const callbackQueue = new CallbackQueue()
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);
      

      this.state = {
        flattenedPointset: [],
        metadata: [],
        brickschema: {},
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        chartData:[
          { timestamp: "2022-08-28T10:50:00.000Z", 
            // total_energy:0,
            // import_Varh:3074196,
            // vAh: 5508558848,
            // phase_1_line_to_neutral_volts: 243.2,
            // phase_2_line_to_neutral_volts: 244.1,
            // phase_3_line_to_neutral_volts:242.6,
            phase_1_current: 6,
            phase_2_current:33.8,
            phase_3_current: 35.2,
            compare_phase_1_current: 6,
            compare_phase_2_current:33.8,
            compare_phase_3_current: 35.2,
            // total_system_power_factor: -0.8,
            // total_system_phase_angle: -38.4,
            // total_system_frequency:49.9,
            // line_1_to_line_2_volts: 421.3,
            // line_2_to_line_3_volts:423.3,
            // line_3_to_line_1_volts:422.3,
            // neutral_current: 1.5,
            // energy_at_start_of_period: 4396481,
            todays_energy: 462.9,
            yesterdays_energy:487.9,
            last_weeks_energy: 16567.3,
            this_weeks_energy:3428.9,
            last_months_energy: 40059.4,
            this_months_energy: 49993.2,
            compare_todays_energy: 462.9,
            compare_yesterdays_energy:487.9,
            compare_last_weeks_energy: 16567.3,
            compare_this_weeks_energy:3428.9,
            compare_last_months_energy: 40059.4,
            compare_this_months_energy: 49993.2,
            // total_volt_amps: 24.7,
            phase_1_power:6,
            phase_2_power: 6.6,
            phase_3_power:6.7,
            total_power: 19.3,
            compare_phase_1_power:6,
            compare_phase_2_power: 6.6,
            compare_phase_3_power:6.7,
            compare_total_power: 19.3
          },
          { timestamp: "2022-09-04T08:30:00.000Z", 
            // total_energy:4414390,
            // import_Varh:3078220,
            // vAh: 5526636032,
            // phase_1_line_to_neutral_volts: 246.7,
            // phase_2_line_to_neutral_volts: 247.9,
            // phase_3_line_to_neutral_volts:246.2,
            phase_1_current: 112.7,
            phase_2_current:119.3,
            phase_3_current: 116.8,
            compare_phase_1_current: 112.7,
            compare_phase_2_current:119.3,
            compare_phase_3_current: 116.8,
            // total_system_power_factor:-1.0,
            // total_system_phase_angle: 2.6,
            // total_system_frequency:50,
            // line_1_to_line_2_volts: 427.1,
            // line_2_to_line_3_volts:427.1,
            // line_3_to_line_1_volts:428.4,
            // neutral_current: 1.9,
            // energy_at_start_of_period: 4413706,
            todays_energy: 683.5,
            yesterdays_energy:2721.3,
            last_weeks_energy: 3447.8,
            this_weeks_energy:17422.8,
            last_months_energy: 0,
            this_months_energy: 9062.4,
            compare_todays_energy: 683.5,
            compare_yesterdays_energy:2721.3,
            compare_last_weeks_energy: 3447.8,
            compare_this_weeks_energy:17422.8,
            compare_last_months_energy: 0,
            compare_this_months_energy: 9062.4,
            // total_volt_amps: 85.8,
            phase_1_power:27.6,
            phase_2_power:29.4 ,
            phase_3_power:28.7,
            total_power: 85.8,
            compare_phase_1_power:27.6,
            compare_phase_2_power:29.4 ,
            compare_phase_3_power:28.7,
            compare_total_power: 85.8
          },
        ],
        chartDisplay:{
          // total_energy:true,
          // import_Varh:true,
          // vAh: true,
          // phase_1_line_to_neutral_volts: true,
          // phase_2_line_to_neutral_volts: true,
          // phase_3_line_to_neutral_volts:true,
          phase_1_current: true,
          phase_2_current:true,
          phase_3_current: true,
          compare_phase_1_current: false,
          compare_phase_2_current:false,
          compare_phase_3_current: false,
          // total_system_power_factor: true,
          // total_system_phase_angle: true,
          // total_system_frequency:true,
          // line_1_to_line_2_volts: true,
          // line_2_to_line_3_volts:true,
          // line_3_to_line_1_volts:true,
          // neutral_current: true,
          // energy_at_start_of_period: true,
          todays_energy: true,
          yesterdays_energy:false,
          last_weeks_energy: false,
          this_weeks_energy:false,
          last_months_energy: false,
          this_months_energy: false,
          compare_todays_energy: false,
          compare_yesterdays_energy:false,
          compare_last_weeks_energy: false,
          compare_this_weeks_energy:false,
          compare_last_months_energy: false,
          compare_this_months_energy: false,
          // total_volt_amps: true,
          phase_1_power:true,
          phase_2_power: true,
          phase_3_power:true,
          total_power: true,
          compare_phase_1_power:false,
          compare_phase_2_power: false,
          compare_phase_3_power:false,
          compare_total_power: false,
        },
        meteringData:makeNewData
      };

      this.fetchTableData = this.fetchTableData.bind(this);
      this.handleFilterData = this.handleFilterData.bind(this);
      this.onDataChange = this.onDataChange.bind(this);

      this.unsubscribe = () => { };
    }
  
    componentDidUpdate(prevProps: Props) {
      if (this.props.buildings !== prevProps.buildings) {
          if (this.unsubscribe) {
              this.unsubscribe();
          }
          this.fetchTableData();
      }
    }

    componentDidMount() {
      this.fetchTableData();
    }
  
    componentWillUnmount() { 
      if (this.unsubscribe) {
        this.unsubscribe();
    }
    }

    fetchTableData(){
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      let subscriptions: any = []
      for (const [BuildingName, BuildingObject] of Object.entries(this.props.buildings)) {
        for (const [LevelName, LevelObject] of Object.entries(BuildingObject)) {
          for (const deviceName of LevelObject.registries.device) {
            const pattern = /^(LAC)-\d+$/;
            if(pattern.test(deviceName)){
              const unsubscribe = LuminaireDataService.getDevice(LevelName, deviceName).onSnapshot((snapshot) => {
                callbackQueue.enqueue(() => {
                  this.onDataChange(snapshot, deviceName);
                  return Promise.resolve();
                });
              });
              subscriptions.push(unsubscribe);
            }
          }
        }
      }
      this.unsubscribe = () => {
        for (const unsubscribe of subscriptions) {
            unsubscribe();
        }
      };
    }

    handleFilterData(chartData: MeterChartData, chartDisplay: MeterChartDisplay): Promise<void> {
      console.log('meter variation1 handleFilterData');
      return new Promise<void>((resolve) => {
        this.setState({
          chartData: chartData,
          chartDisplay: chartDisplay
        }, () => {
          resolve(); // Resolve the promise after the state has been updated
        });
      });
    }

    onDataChange(items: any, deviceName: string) {
      console.log("onDataChange");
  
      items.forEach((item: any) => {
          let id:string = item.id;
          let data = item.data();
          console.log(deviceName)
  
          if(id === 'pointset'){
              // Ensure that 'data.timestamp' and 'data.timestamp.seconds' exist
              if (data.timestamp && typeof data.timestamp.seconds === 'number') {
                  const dateMillis = data.timestamp.seconds * 1000
                  const deviceDate = new Date(dateMillis)
                  
                  const isoTimestamp = deviceDate.toISOString();
                  const formattedTimestamp = createHumanTimestamp(isoTimestamp);

                  const deviceOnline = isDeviceOnline(deviceDate);
                  const lastSeen = calculateLastSeen(dateMillis);
                  const lastSeenDays = lastSeen[0];
                  const lastSeenHours = lastSeen[1];
                  const lastSeenMins = lastSeen[2];
  
                  const flattenedPoint: ExtendedILcmData = {
                      ...data.points as any,  
                      version: data.version,
                      name: { present_value: deviceName },  
                      timestamp: { present_value: formattedTimestamp },
                      online: {present_value: deviceOnline},
                      last_seen_days: {present_value: lastSeenDays},
                      last_seen_hours: {present_value: lastSeenHours},
                      last_seen_mins: {present_value: lastSeenMins}
                  };
  
                  this.setState(prevState => {
                    const index = prevState.flattenedPointset.findIndex(point => point.name.present_value === deviceName);
                    
                    if (index !== -1) {
                        // If the device exists in the array, create a new array with updated data for that device
                        const updatedPoints = [...prevState.flattenedPointset];
                        updatedPoints[index] = flattenedPoint;
                        return {
                            ...prevState,
                            flattenedPointset: updatedPoints
                        };
                    } else {
                        // If the device doesn't exist in the array, append it
                        return {
                            ...prevState,
                            flattenedPointset: [...prevState.flattenedPointset, flattenedPoint]
                        };
                    }
                });
              } else {
                  console.error('Timestamp data is missing or in incorrect format', data);
              }
          } else if(id === 'metadata'){
              // Handle metadata logic here
          }
      });
    }

    calculateTicks(data:MeterChartData, numTicks:number) {
      const step = Math.floor(data.length / numTicks);
      return data
        .filter((_, index) => index % step === 0)
        .map(item => item.timestamp);
    }

      render() {
        const { flattenedPointset, chartData, chartDisplay, meteringData } = this.state;

        console.log(flattenedPointset);

        const calculateMaxYValue = (chartData:MeterChartData, chartDisplay:MeterChartDisplay) => {
          if (!Array.isArray(chartData) || chartData.length === 0) {
            return 10000;
          }

          return Math.max(
            ...chartData.map(d => Math.max(
              chartDisplay.todays_energy ? d.todays_energy ?? 0 : 0,
              chartDisplay.yesterdays_energy ? d.yesterdays_energy ?? 0 : 0,
              chartDisplay.last_weeks_energy ? d.last_weeks_energy ?? 0 : 0,
              chartDisplay.this_weeks_energy ? d.this_weeks_energy ?? 0 : 0,
              chartDisplay.last_months_energy ? d.last_months_energy ?? 0 : 0,
              chartDisplay.this_months_energy ? d.this_months_energy ?? 0 : 0,
              chartDisplay.compare_todays_energy ? d.compare_todays_energy ?? 0 : 0,
              chartDisplay.compare_yesterdays_energy ? d.compare_yesterdays_energy ?? 0 : 0,
              chartDisplay.compare_last_weeks_energy ? d.compare_last_weeks_energy ?? 0 : 0,
              chartDisplay.compare_this_weeks_energy ? d.compare_this_weeks_energy ?? 0 : 0,
              chartDisplay.compare_last_months_energy ? d.compare_last_months_energy ?? 0 : 0,
              chartDisplay.compare_this_months_energy ? d.compare_this_months_energy ?? 0 : 0
            ))
          );
        };

        const RenderRechart = (chartData:MeterChartData, chartDisplay:MeterChartDisplay) => {
          return(
            <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
            <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={createHumanTimestamp}
                // ticks={this.calculateTicks(chartData, 7)}
                angle={-45} 
                textAnchor="end"
                height={120}
              />
              <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, calculateMaxYValue]}/> 
              {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
              {/* <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 40]} interval={0}/> */}
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {chartDisplay.todays_energy ? (<Line yAxisId="left" type="monotone" dataKey="todays_energy" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.yesterdays_energy ? (<Line yAxisId="left" type="monotone" dataKey="yesterdays_energy" stroke="#82ca9d" />) : null}
              {chartDisplay.last_weeks_energy ? (<Line yAxisId="left" type="monotone" dataKey="last_weeks_energy" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.this_weeks_energy ? (<Line yAxisId="left" type="monotone" dataKey="this_weeks_energy" stroke="#fff200" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.last_months_energy ? (<Line yAxisId="left" type="monotone" dataKey="last_months_energy" stroke="#8e67bf" />) : null}
              {chartDisplay.this_months_energy ? (<Line yAxisId="left" type="monotone" dataKey="this_months_energy" stroke="#a2d39c" />) : null}

              {chartDisplay.compare_todays_energy ? (<Line yAxisId="left" type="monotone" dataKey="compare_todays_energy" stroke="#f26d7d" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_yesterdays_energy ? (<Line yAxisId="left" type="monotone" dataKey="compare_yesterdays_energy" stroke="#548b72" />) : null}
              {chartDisplay.compare_last_weeks_energy ? (<Line yAxisId="left" type="monotone" dataKey="compare_last_weeks_energy" stroke="#b23a48" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_this_weeks_energy ? (<Line yAxisId="left" type="monotone" dataKey="compare_this_weeks_energy" stroke="#b2a300" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_last_months_energy ? (<Line yAxisId="left" type="monotone" dataKey="compare_last_months_energy" stroke="#5c3d6f" />) : null}
              {chartDisplay.compare_this_months_energy ? (<Line yAxisId="left" type="monotone" dataKey="compare_this_months_energy" stroke="#6b8d73" />) : null}
            </LineChart>
          </ResponsiveContainer>
          );
        };

        const RenderVictoryChart = (chartData:MeterChartData, chartDisplay:MeterChartDisplay) => {
          const tickValues = chartData.map(data => new Date(data.timestamp));

          return(
            <VictoryChart
              width={500}
              height={300}
              scale={{ x: "time" }}
              containerComponent={
                <VictoryVoronoiContainer
                  voronoiDimension="x"
                  labels={({ datum }) => `y: ${datum.y}`}
                  labelComponent={<VictoryTooltip cornerRadius={0} flyoutStyle={{ fill: "white" }}/>}
                />
              }
            >
              {/* X Axis */}
              <VictoryAxis
                tickValues={tickValues}
                tickFormat={(x) => createHumanTimestamp(x)}
                style={
                  { tickLabels: { angle: -45, textAnchor: 'end', fontSize: 6 },
                  axisLabel: { fontSize: 8 } 
                }}
              />

              {/* Y Axis */}
              <VictoryAxis
                dependentAxis
                label="kWh"
                domain={[0, 30000]}
                tickValues={[0, 6000, 12000, 18000, 24000, 30000]}
                style={{ 
                  tickLabels: { fontSize: 6 },
                  axisLabel: { padding: 30, fontSize: 8 } 
                }}
              />

              {/* Lines */}
              {chartDisplay.todays_energy && (
                <VictoryLine
                  data={chartData}
                  x="timestamp"
                  y="todays_energy"
                  style={{ data: { stroke: "#6fa8dc" } }}
                />
              )}
              {chartDisplay.yesterdays_energy && (
                <VictoryLine
                  data={chartData}
                  x="timestamp"
                  y="yesterdays_energy"
                  style={{ data: { stroke: "#82ca9d" } }}
                />
              )}
              {chartDisplay.last_weeks_energy && (
                <VictoryLine
                  data={chartData}
                  x="timestamp"
                  y="last_weeks_energy"
                  style={{ data: { stroke: "#f26d7d" } }}
                />
              )}
              {chartDisplay.this_weeks_energy && (
                <VictoryLine
                  data={chartData}
                  x="timestamp"
                  y="this_weeks_energy"
                  style={{ data: { stroke: "#fff200" } }}
                />
              )}
              {chartDisplay.last_months_energy && (
                <VictoryLine
                  data={chartData}
                  x="timestamp"
                  y="last_months_energy"
                  style={{ data: { stroke: "#8e67bf" } }}
                />
              )}
              {chartDisplay.this_months_energy && (
                <VictoryLine
                  data={chartData}
                  x="timestamp"
                  y="this_months_energy"
                  style={{ data: { stroke: "#a2d39c" } }}
                />
              )}
              <VictoryLegend x={50} y={5}
                title="Legend"
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{ 
                  border: { stroke: "black" }, 
                  title: {fontSize: 8 },
                  labels: { fontSize: 6 }
                }}
                data={[
                  { name: "Today", symbol: { fill: "#6fa8dc" } },
                  { name: "Yesterday", symbol: { fill: "#82ca9d" } },
                  { name: "Last Weeks", symbol: { fill: "#f26d7d" } },
                  { name: "This Weeks", symbol: { fill: "#fff200" } },
                  { name: "Last Months", symbol: { fill: "#8e67bf" } },
                  { name: "This Months", symbol: { fill: "#a2d39c" } },
                ]}
              />
            </VictoryChart>
          );
        };

        const renderMenuItems = (metering: IEnergyData[]) => {
          return metering.map((item: IEnergyData) => {
            const value = item.energy_use.percent.present_value;
            let cardClass = "card mb-3 widget-chart widget-chart2 text-left card-btm-border ";
          
            if (value < 70) {
              cardClass += "card-shadow-secondary border-secondary";
            } else if (value >= 70 && value < 100) {
              cardClass += "card-shadow-warning border-warning";
            } else if (value >= 100) {
              cardClass += "card-shadow-danger border-danger";
            }
            return(
              <Col md="6" xl="4">
                  <div className={cardClass}>
                    <div className="widget-chat-wrapper-outer">
                      <div className="widget-chart-content pt-3 pl-3 pb-1">
                        <h4 className="widget-subheading mb-0 opacity-5">
                          {item.name}
                        </h4>
                      </div>
                      <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                        <ReactGaugeMeter
                          firstColor="green"
                          secondColor="green"
                          thirdColor="orange"
                          value={item.energy_use.percent.present_value}
                          style={{height: '400px', width: '100%'}}
                        />
                        
                      </Row>
                      <div style={{textAlign: "center", marginTop: "-130px"}}>
                      <h4 >{item.energy_use.kw.present_value} kW</h4>
                      <p>Max Capacity {item.max_capacity.present_value} kW</p>
                      <p>Current Target {item.current_target.present_value} kW</p>
                      </div>
                    </div>
                  </div>
                </Col>
            )
          });
        };

        return (
            <>
        {/* <Meter_Type_1_DeviceHolder /> */}
        <Container fluid>
        {/*<Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Luminaire Graph Filter
                  </div>*/}
                  {/* <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Settings</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                {/* </Card.Header>
                <Card.Body>
                  <MeterFilterData
                    deviceId={'TX-00'}
                    handleFilterData={this.handleFilterData}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>Luminaire Timeseries</Card.Title>
                 {RenderRechart(chartData, chartDisplay)}
                </Card.Body>
              </Card>
            </Col>
          </Row>   */}
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Area Controller Health
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Actions</Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Generate Report</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                    <div className="-striped -highlight -fixed">
                  <ReactTable data={flattenedPointset}
                     columns={[
                      {
                        Header: "Luminaire",
                        columns: [
                          {
                            Header: "Online",
                            id: "deviceOnline",
                            accessor: (row: ExtendedILcmData) => row.online.present_value,
                            Cell: ({ value }: { value: boolean }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                            width: 100,
                          },
                          {
                            Header: "Name",
                            id: "deviceName",
                            accessor: (row: ExtendedILcmData) => row.name.present_value,
                            width: 100,
                          },
                          
                          // {
                          //   Header: "Alarm",
                          //   id: "ahuAlarm",
                          //   accessor: (row: ExtendedIMeterData) => row.alarm.present_value,
                          //   width: 100,
                          // }
                        ]
                      },
                      {
                        Header: "Last Seen",
                        columns: [
                          {
                            Header: "Timestamp",
                            id: "deviceTimestamp",
                            accessor: (row: ExtendedILcmData) => row.timestamp.present_value,
                            width: 200,
                          },
                          {
                            Header: "Days",
                            id: "deviceLastSeenDays",
                            accessor: (row: ExtendedILcmData) => row.last_seen_days.present_value,
                            width: 100,
                          },
                          {
                            Header: "Hours",
                            id: "deviceLastSeenHours",
                            accessor: (row: ExtendedILcmData) => row.last_seen_hours.present_value,
                            width: 100,
                          },
                          {
                            Header: "Mins",
                            id: "deviceLastSeenMins",
                            accessor: (row: ExtendedILcmData) => row.last_seen_mins.present_value,
                            width: 100,
                          },
                        ]
                      },
                      {
                        Header: "Diagnosis",
                        columns: [
                          {
                            Header: "operational_status",
                            id: "operational_status",
                            accessor: (row: ExtendedILcmData) =>
                              row.operational_status?.present_value|| '-',
                            width: 120,
                          },
                          {
                            Header: "is_consequence",
                            id: "is_consequence",
                            accessor: (row: ExtendedILcmData) =>
                              row.is_consequence?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "operational_status_due_to",
                            id: "operational_status_due_to",
                            accessor: (row: ExtendedILcmData) =>
                              row.operational_status_due_to?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "other_assets_affected",
                            id: "other_assets_affected",
                            accessor: (row: ExtendedILcmData) =>
                              row.other_assets_affected?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "operational_status_details",
                            id: "operational_status_details",
                            accessor: (row: ExtendedILcmData) =>
                              row.operational_status_details?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "remedy",
                            id: "remedy",
                            accessor: (row: ExtendedILcmData) =>
                              row.remedy?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "remedy_details",
                            id: "remedy_details",
                            accessor: (row: ExtendedILcmData) =>
                              row.remedy_details?.present_value || '-',
                            width: 120,
                          },
                        ]
                      }
                    ]}
                    />
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container> 
            </>
    );
  }
}