import React, { useCallback } from "react";
import cx from "classnames";

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { StoreState } from '../../redux/types/store.type';

import {setEnableMobileMenu, ThemeOptionsState} from "../../redux/types/themeOptions.type";
import {sEnableMobileMenu} from "../../redux/reducers/ThemeOptions";

import PerfectScrollbar from "react-perfect-scrollbar";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import NavWrapper from "../AppNav/VerticalNavWrapper";
import HeaderLogo from "../AppLogo";
import firebase from "firebase/compat/app";

interface OwnProps {
    user: firebase.User | null
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
};

type MapDispatchToPropsType = {
    setEnableMobileMenu: (enable: boolean) => void;
};
  
type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType & MapDispatchToPropsType;

const useThemeActions = () => {
    const dispatch = useDispatch<ThunkDispatch<setEnableMobileMenu, unknown, AnyAction>>();
  
    const memoizedSetEnableMobileMenu = useCallback((enable: boolean) => dispatch(sEnableMobileMenu(enable)), [dispatch]);

    return {
        setEnableMobileMenu: memoizedSetEnableMobileMenu,
    };
};


const AppSideBar: React.FC<Props> = ({ ThemeOptions, setEnableMobileMenu, user }) => {

    const toggleMobileSidebar = () => {
        setEnableMobileMenu(!ThemeOptions.enableMobileMenu);
      };

    return (
        <>
            <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar}/>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="SidebarAnimation"
                    timeout={{
                    appear: 1500,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                        <div
                        className={cx("app-sidebar", ThemeOptions.backgroundColor, {
                            "sidebar-shadow": ThemeOptions.enableSidebarShadow,
                        })} style={{ backgroundColor: "#0A1929" }}
                        >
                            <HeaderLogo user={user} setEnableClosedSidebar={() => undefined} setEnableMobileMenu={() => undefined} setEnableMobileMenuSmall={() => undefined}/>
                            <PerfectScrollbar>
                                {/* <div className="app-sidebar__inner"> */}
                                <NavWrapper user={user} setEnableMobileMenu={() => undefined} />
                                {/* </div> */}
                            </PerfectScrollbar>
                            <div className={cx("app-sidebar-bg", ThemeOptions.backgroundImageOpacity)}
                                style={{
                                backgroundImage: ThemeOptions.enableBackgroundImage
                                    ? "url(" + ThemeOptions.backgroundImage + ")"
                                    : undefined,
                                }}>
                            </div>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
        </>
    );
};
  
const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
    };
};
  
const connector = connect(mapStateToProps);

export default connector((props: Props) => {
    const { setEnableMobileMenu } = useThemeActions();

    return <AppSideBar {...props} setEnableMobileMenu={setEnableMobileMenu} />;
});