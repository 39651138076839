import { ThunkAction } from "redux-thunk";
import firebase, { functions } from "../../firebase";
import { AssetAction, AssetState } from "../types/asset.type";
import * as actionTypes from "../types/asset.action.type";

export function fetchAssets(): ThunkAction<
  void,
  AssetState,
  unknown,
  AssetAction
> {
  return async (dispatch) => {
    const path = "Assets/Types";
    firebase
      .doc(path)
      .get()
      .then((doc) => {
        dispatch({
          type: actionTypes.GET_ASSET,
          Types: doc.data() as any,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
}
