import { SvgProps } from "../types/svg";

const Svg = ({
  className = "",
  fill = "none",
  stroke = "currentColor",
  viewBox = "0 0 24 24",
  strokeLineCape = "round",
  strokeLineJoin = "round",
  strokeWidth = "2",
  d,
  onClick = () => null,
}: SvgProps) => {
  return (
    <svg
      className={className}
      fill={fill}
      stroke={stroke}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        strokeLinecap={strokeLineCape}
        strokeLinejoin={strokeLineJoin}
        strokeWidth={strokeWidth}
        d={d}
      ></path>
    </svg>
  );
};

export default Svg;
