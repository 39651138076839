import React, { Component } from "react";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import PerfectScrollbar from "react-perfect-scrollbar";

export default class TimelineEx extends Component {
    render() {
        return (
            <>
            <div className="scroll-area-sm">
            <PerfectScrollbar>
                <div className="p-3">
                <VerticalTimeline className="vertical-time-simple vertical-without-time" layout="1-column">
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <h4 className="timeline-title">LONDON-WENLOCK-22 FCU Maintenance</h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <p>
                        Scheduled maintenance today, at{" "}
                        <b className="text-danger">12:00 PM</b>
                    </p>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <h4 className="timeline-title">
                        RS Components Delivery
                    </h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <h4 className="timeline-title">LONDON-WENLOCK-22 UPS Fault</h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <h4 className="timeline-title text-success">
                        Energy Analytics
                    </h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <h4 className="timeline-title">
                        LONDON-WENLOCK-22 LTHW Test
                    </h4>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement className="vertical-timeline-item">
                    <p>
                        CRAC Maintenance today, at{" "}
                        <b className="text-warning">15:00 PM</b>
                    </p>
                    </VerticalTimelineElement>
                </VerticalTimeline>
                </div>
            </PerfectScrollbar>
            </div>
            </>
        );
    }
}