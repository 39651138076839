import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { GraphicEditorSettings } from "../../types/settings";

interface Props {
  children?: React.ReactNode;
}

interface GraphicDeviceTypeMappingProps {
  [deviceCategory: string]: Array<string>;
}

interface ContextProps {
  graphicDeviceTypeMapping: GraphicDeviceTypeMappingProps;
  setGraphicDeviceTypeMapping: Dispatch<
    SetStateAction<GraphicDeviceTypeMappingProps>
  >;
}

const GraphicDeviceTypeMapping = createContext<ContextProps>({
  graphicDeviceTypeMapping: {},
  setGraphicDeviceTypeMapping: () => {},
});

const GraphicDeviceTypeMappingProvider: FC<Props> = ({ children }) => {
  const [graphicDeviceTypeMapping, setGraphicDeviceTypeMapping] =
    useState<GraphicDeviceTypeMappingProps>({});

  return (
    <GraphicDeviceTypeMapping.Provider
      value={{ graphicDeviceTypeMapping, setGraphicDeviceTypeMapping }}
    >
      {children}
    </GraphicDeviceTypeMapping.Provider>
  );
};

export const useGraphicDeviceTypeMapping = () =>
  useContext(GraphicDeviceTypeMapping);

export default GraphicDeviceTypeMappingProvider;
