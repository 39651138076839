import { DragEvent, useState } from "react";

interface Props {
  setUploadedImage: (graphic: File) => void;
}

function GraphicLoader({ setUploadedImage }: Props) {
  const [dragActive, setDragActive] = useState<Boolean>(false);

  // handle drag events
  const handleDrag = function (
    event: DragEvent<HTMLDivElement> | DragEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (event: DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      // at least one file has been dropped
      setUploadedImage(event.dataTransfer.files[0]);
    }
  };

  return (
    <div className="grid h-full w-full place-items-center">
      <form
        className="flex justify-center items-center max-w-xl min-w-max w-3/4 relative border-gray-300 border-dashed cursor-pointer"
        onDragEnter={handleDrag}
        onSubmit={(e) => e.preventDefault()}
      >
        <label
          htmlFor="dropzone-file"
          className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100"
        >
          <div className="flex flex-col justify-center items-center pt-5 pb-6">
            <svg
              aria-hidden="true"
              className="mb-3 w-10 h-10 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
              ></path>
            </svg>
            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span className="font-semibold">Click to upload</span> or drag and
              drop
            </p>
            <p className="text-xs text-gray-500 dark:text-gray-400">
              Import your device graphic here.
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            name="graphic"
            className="hidden"
            onChange={(event) => setUploadedImage(event.target.files![0])}
          />
        </label>
        {dragActive && (
          <div
            className="absolute w-full h-full inset-0 rounded-lg bg-gray-400/[.09]"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          ></div>
        )}
      </form>
    </div>
  );
}

export default GraphicLoader;
