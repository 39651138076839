import React, { Component } from "react";

// import Ionicon from 'react-ionicons';
import {
  IoIosGrid,
  IoIosNotificationsOutline,
} from "react-icons/io";

import {
    Dropdown,
    Nav,
    Col,
    Row,
    Button,
    NavItem,
  } from "react-bootstrap";

import '../../../../assets/overides/header/header-dots.scss'


import bg4 from "../../../../assets/utils/images/dropdown-header/abstract4.jpg";
import city2 from "../../../../assets/utils/images/dropdown-header/city2.jpg";
import city3 from "../../../../assets/utils/images/dropdown-header/city3.jpg";

import Flag from "react-flagkit";

import { TabsManager, Tab, TabPanel, TabPanels } from "@react-md/tabs";

// Dropdown Tabs Content
import ChatExample from "./TabsContent/ChatExample";
import TimelineEx from "./TabsContent/TimelineExample";
import SysErrEx from "./TabsContent/SystemExample";

type Props = {
  name?: string;
}
  
type State = {
  active: boolean
};

const tabsContent = [
  {
    title: "Messages",
    content: <ChatExample />,
  },
  {
    title: "Events",
    content: <TimelineEx />,
  },
  {
    title: "System Alarms",
    content: <SysErrEx />,
  },
];

function getTabs() {
  return tabsContent.map((tab, index) => ({
    title: tab.title,
    getContent: () => tab.content,
    key: index,
  }));
}

export default class HeaderDots extends Component<Props, State> {
    unsubscribe: () => void;
  
    constructor(props: Props) {
      super(props);

      this.state = {
        active: false,
      };
  
      this.unsubscribe = () => { };
    }



    render() {
        const tabs = getTabs();
        const { active } = this.state;
        return (
          <>
            <div className="header-dots">
              {/* <Dropdown className="headerDots">
                <Dropdown.Toggle className="p-0 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-primary" />
                    <IoIosGrid color="#3f6ad8" fontSize="23px" />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="dropdown-menu-xl rm-pointers">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-plum-plate">
                      <div className="menu-header-image"
                        style={{
                          backgroundImage: "url(" + bg4 + ")",
                        }}/>
                      <div className="menu-header-content text-white">
                        <h5 className="menu-header-title">Control Panel</h5>
                        <h6 className="menu-header-subtitle">
                          Easy access control panel components
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="grid-menu grid-menu-xl grid-menu-3col">
                    <Row className="no-gutters">
                      <Col xl="4" sm="6">
                        <Button className="btn-icon-vertical btn-square btn-transition" variant="outline" color="link">
                          <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">  {" "} </i>
                          Automation
                        </Button>
                      </Col>
                      <Col xl="4" sm="6">
                        <Button className="btn-icon-vertical btn-square btn-transition" variant="outline" color="link">
                          <i className="pe-7s-piggy icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3">
                            {" "}
                          </i>
                          Reports
                        </Button>
                      </Col>
                      <Col xl="4" sm="6">
                        <Button className="btn-icon-vertical btn-square btn-transition" variant="outline" color="link">
                          <i className="pe-7s-config icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                          Settings
                        </Button>
                      </Col>
                      <Col xl="4" sm="6">
                        <Button className="btn-icon-vertical btn-square btn-transition" variant="outline" color="link">
                          <i className="pe-7s-browser icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                          Content
                        </Button>
                      </Col>
                      <Col xl="4" sm="6">
                        <Button className="btn-icon-vertical btn-square btn-transition" variant="outline" color="link">
                          <i className="pe-7s-hourglass icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                          Activity
                        </Button>
                      </Col>
                      <Col xl="4" sm="6">
                        <Button className="btn-icon-vertical btn-square btn-transition" variant="outline" color="link">
                          <i className="pe-7s-world icon-gradient bg-night-fade btn-icon-wrapper btn-icon-lg mb-3"> {" "} </i>
                          Contacts
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Nav>
                    <NavItem className="nav-item-divider" />
                    <NavItem className="nav-item-btn text-center">
                      <Button size="sm" className="btn-shadow" color="primary">
                        Follow-ups
                      </Button>
                    </NavItem>
                  </Nav>
                </Dropdown.Menu>
              </Dropdown> */}
              <Dropdown className="headerDots">
                <Dropdown.Toggle className="p-0 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-danger" />
                    <IoIosNotificationsOutline color="#d92550" fontSize="23px" />
                    <div className="badge badge-dot badge-dot-sm badge-danger">
                      Notifications
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="dropdown-menu-xl rm-pointers">
                  <div className="dropdown-menu-header mb-0">
                    <div className="dropdown-menu-header-inner bg-deep-blue">
                      <div className="menu-header-image opacity-1"
                        style={{
                          backgroundImage: "url(" + city3 + ")",
                        }}/>
                      <div className="menu-header-content text-dark">
                        <h5 className="menu-header-title">Notifications</h5>
                        <h6 className="menu-header-subtitle">
                          You have <b>0</b> unread messages
                        </h6>
                      </div>
                    </div>
                  </div>
                  <TabsManager
                    tabs={tabs.map((tab, index) => (
                        <Tab
                        key={index}
                        id={`tab-${index}`}
                        active={active}
                        >
                        {tab.title}
                        </Tab>
                    ))}
                    tabsId="my-tabs"
                    >
                    <TabPanels
                        className="body-tabs body-tabs-alt"
                        disableTransition={true}
                        aria-orientation="horizontal"
                    >
                    {tabs.map((tab, index) => (
                    <TabPanel key={index} id={`tab-panel-${index}`}>
                        {tab.getContent()}
                    </TabPanel>
                    ))}
                    </TabPanels>
                </TabsManager>
                  {/* <Nav>
                    <NavItem className="nav-item-divider" />
                    <NavItem className="nav-item-btn text-center">
                      <Button size="sm" className="btn-shadow btn-wide btn-pill" color="focus">
                        View Latest Changes
                      </Button>
                    </NavItem>
                  </Nav> */}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="headerDots">
                <Dropdown.Toggle className="p-0 mr-2" color="link">
                  <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div className="icon-wrapper-bg bg-focus" />
                    <div className="language-icon">
                      <Flag className="mr-3 opacity-8" country="GB" size={40} />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end" className="rm-pointers">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
                      <div className="menu-header-image opacity-05"
                        style={{
                          backgroundImage: "url(" + city2 + ")",
                        }}/>
                      <div className="menu-header-content text-center text-white">
                        <h6 className="menu-header-subtitle mt-0">
                          Choose Language
                        </h6>
                      </div>
                    </div>
                  </div>
                  <Dropdown.Item header>Popular Languages</Dropdown.Item>
                  <Dropdown.Item active>
                    <Flag className="mr-3 opacity-8" country="GB" />
                    Great Britain
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Flag className="mr-3 opacity-8" country="CH" />
                    Switzerland
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Flag className="mr-3 opacity-8" country="FR" />
                    France
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Flag className="mr-3 opacity-8" country="ES" />
                    Spain
                  </Dropdown.Item>
                  <Dropdown.Item divider />
                  <Dropdown.Item header>Others</Dropdown.Item>
                  <Dropdown.Item>
                    <Flag className="mr-3 opacity-8" country="DE" />
                    Germany
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <Flag className="mr-3 opacity-8" country="IT" />
                    Italy
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
      </>
    );
  }
}