import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

import ReactECharts from 'echarts-for-react';

import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryLegend } from 'victory';

import CountUp from "react-countup";

import styled from 'styled-components'
import {GaugeContainer, GaugeText} from './styles'
import FirstDial from "./firstDial";
import SecondDial from "./secondDial";
import BarGraphStacked2 from "./barGraph"
import BarGraphStacked from "./apexGraphChart";

import ReactGaugeMeter from 'react-gauge-meter'; 
import { useTable, Column, useSortBy } from "react-table";

import METER_TYPE_1 from '../../BMSComponents/components/meter_type_1';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import LuminaireDataService from "../../../../firebase/services/luminaire.service";
import CallbackQueue from "../../../../firebase/services/callback.service";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

import {ICtNetData, ICtNetMetaData, ExtendedICtNetData} from '../../../../firebase/types/ctnet.type';
import {CtNetChartData, CtNetChartDataMap, CtNetChartDisplay, DeviceName, PureCtNetChartDataItem} from '../../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen } from '../../../../utils/timestamp';
import CtNetFilterData from '../../../components/ctNetFilterData';
import CtNetAnalyseData from "../../../components/ctNetFilterData/analyse";
import CarbonIntensity from "./carbonIntensity";
import { RegionalData, DataPoint, GenerationMix } from "../../../../firebase/types/carbon.type";

import { BuildingState } from '../../../../redux/types/building.type';

const makeNewData: IExtendedEnergyData =
      {
        meta: {
          name: { present_value: "Energy" },
          timestamp: { present_value: "2023-03-28T09:30:00Z" },
          alarm: { present_value: "Healthy" },
        },
        metering:[
        {
          name:"CO-2",
          energy_use:{
            percent:{present_value: 42},
            kw:{present_value:139}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
        {
          name:"CO-3",
          energy_use:{
            percent:{present_value: 35},
            kw:{present_value:126}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
         {
          name:"CO-4",
          energy_use:{
            percent:{present_value: 0},
            kw:{present_value:0}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
         {
          name:"CO-5",
          energy_use:{
            percent:{present_value: 0},
            kw:{present_value:0}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
        {
          name:"TX-14",
          energy_use:{
            percent:{present_value: 9},
            kw:{present_value:197.1}},
          max_capacity:{present_value: 2000},
          current_target:{present_value: 1150}
        },
        {
          name:"TX-15",
          energy_use:{
            percent:{present_value: 57},
            kw:{present_value:1147.4}},
          max_capacity:{present_value: 2000},
          current_target:{present_value: 1150}
        }
        ]
      }

function Meter_Type_1_DeviceHolder () {
  let data = {
    "chiller_removed_from_sequence": {
      "present_value":  "false"
    },
    "chiller_enable_status": {
      "present_value":  "false"
    },
    "isolation_valve_failed": {
      "present_value":  "false"
    },
    "percentage_total_capacity": {
      "present_value":  0
    },
    "entering_water_temp": {
      "present_value":  9.78
    },
    "leaving_water_temp": {
      "present_value":  10.06
    },
    "chiller_shutdown_alarm": {
      "present_value":  "false"
    },
    "isolation_valve_open": {
      "present_value":  "false"
    },
    "isolation_valve_enable": {
      "present_value":  "false"
    },
    "chiller_enabled": {
      "present_value":  "false"
    },
    "chiller_required": {
      "present_value":  "false"
    },
    "status": {
      "present_value":  "true"
    },
    "partial_alarm": {
      "present_value":  "false"
    },
    "shutdown_alarm": {
      "present_value":  "false"
    },
    "suction_pressure_a": {
      "present_value":  316.59
    },
    "suction_pressure_b": {
      "present_value":  325.01
    },
    "discharge_pressure_a": {
      "present_value":  344.51
    },
    "discharge_pressure_b": {
      "present_value":  349.78
    },
    "machine_operation_hours": {
      "present_value":  16903
    },
    "machine_starts": {
      "present_value":  33865
    },
    "pump_duty": {
      "present_value":  2
    },
    "pump_flow_proving": {
      "present_value":  "false"
    },
    "compressor_a_hours_run": {
      "present_value":  8561
    },
    "compressor_a_starts": {
      "present_value":  17926
    },
    "compressor_b_hours_run": {
      "present_value":  10897
    },
    "compressor_b_starts": {
      "present_value":  17693
    },
    "chiller_fan_a1_hours_run": {
      "present_value":  8486.25
    },
    "chiller_fan_a2_hours_run": {
      "present_value":  8185.25
    },
    "chiller_fan_a3_hours_run": {
      "present_value":  7747.5
    },
    "chiller_fan_a4_hours_run": {
      "present_value":  7035.75
    },
    "chiller_fan_a5_hours_run": {
      "present_value":  6574.75
    },
    "chiller_fan_a6_hours_run": {
      "present_value":  6012.25
    },
    "chiller_fan_a7_hours_run": {
      "present_value":  8681.75
    },
    "chiller_fan_b1_hours_run": {
      "present_value":  10818
    },
    "chiller_fan_b2_hours_run": {
      "present_value":  10420.5
    },
    "chiller_fan_b3_hours_run": {
      "present_value":  9761.75
    },
    "chiller_fan_b4_hours_run": {
      "present_value":  9155.25
    },
    "chiller_fan_b5_hours_run": {
      "present_value":  9869.5
    }
  };
return (
  
      <div className='meter-holder'>

        <METER_TYPE_1 data={data}/>

      </div>

);
}

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  interface ReactTableProps<T extends object> {
    data: T[];
    columns: Column<T>[];
  }

  interface HourlyAverage {
    hour: string; // Format: 'YYYY-MM-DDTHH'
    averageTotal: number;
  }

  function ReactTable<T extends object>({ data, columns }: ReactTableProps<ExtendedICtNetData>) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid grey' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '2px solid grey' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '5px',
                    backgroundColor: '#545cd8',
                    border: '1px solid grey',
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: rowIndex !== rows.length - 1 ? '1px solid grey' : undefined }}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '5px',
                        backgroundColor: cellIndex % 2 === 0 ? 'white' : 'lightgrey',
                        textAlign: 'center'
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
    
    
  }
  
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
  `
  
  type Props = {
    buildings: BuildingState
  };

  type DeviceMap = Map<string, { BuildingName: string; LevelName: string }>

  type State = {
      flattenedPointset: Array<ExtendedICtNetData>,
      metadata: Array<ICtNetMetaData>,
      brickschema: {},
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: [],
      chartData: CtNetChartData;
      chartDataMap:CtNetChartDataMap[];
      chartDisplay: CtNetChartDisplay;
      meteringData: IExtendedEnergyData;
      deviceMap: DeviceMap;
      regionalData:RegionalData;
  };

  const callbackQueue = new CallbackQueue()
  
  export default class AnalyticsDashboardNetvox extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);
      

      this.state = {
        flattenedPointset: [],
        metadata: [],
        brickschema: {},
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        chartData:[
          { timestamp: "2022-08-28T10:50:00.000Z", 
            phase_1: 1,
            phase_2:1,
            phase_3: 1,
            total:33.8,
            sensor_battery: 3.5,
            compare_phase_1: 1,
            compare_phase_2:1,
            compare_phase_3: 1,
            compare_total:33.8,
            compare_sensor_battery: 3.5
          },
          { timestamp: "2022-09-04T08:30:00.000Z", 
            phase_1: 1,
            phase_2:1,
            phase_3: 1,
            total:33.8,
            sensor_battery: 3.5,
            compare_phase_1: 1,
            compare_phase_2:1,
            compare_phase_3: 1,
            compare_total:33.8,
            compare_sensor_battery: 3.5
          },
        ],
        chartDataMap:[
          {
            name:"EM",
            chartData:[
              { timestamp: "2022-08-28T10:50:00.000Z", 
                  phase_1: 1,
                  phase_2:1,
                  phase_3: 1,
                  total:33.8,
                  sensor_battery: 3.5,
              },
              { timestamp: "2022-09-04T08:30:00.000Z", 
                  phase_1: 1,
                  phase_2:1,
                  phase_3: 1,
                  total:33.8,
                  sensor_battery: 3.5,
              },
            ]
          }
        ],
        chartDisplay:{
          phase_1: true,
          phase_2:true,
          phase_3: true,
          total:true,
          sensor_battery: true,
          compare_phase_1: false,
          compare_phase_2: false,
          compare_phase_3:false,
          compare_total: false,
          compare_sensor_battery:false,
        },
        meteringData:makeNewData,
        deviceMap: new Map(),
        regionalData:{
          "regionid": 13,
          "dnoregion": "UKPN London",
          "shortname": "London",
          "postcode": "DA16",
          "data": [
              {
                  "from": "2024-12-01T20:00Z",
                  "to": "2024-12-01T20:30Z",
                  "intensity": {
                      "forecast": 125,
                      "index": "moderate"
                  },
                  "generationmix": [
                      {
                          "fuel": "biomass",
                          "perc": 0
                      },
                      {
                          "fuel": "coal",
                          "perc": 0
                      },
                      {
                          "fuel": "imports",
                          "perc": 42.5
                      },
                      {
                          "fuel": "gas",
                          "perc": 16.8
                      },
                      {
                          "fuel": "nuclear",
                          "perc": 0
                      },
                      {
                          "fuel": "other",
                          "perc": 0
                      },
                      {
                          "fuel": "hydro",
                          "perc": 1.6
                      },
                      {
                          "fuel": "solar",
                          "perc": 0
                      },
                      {
                          "fuel": "wind",
                          "perc": 39.1
                      }
                  ]
              }
          ]
        }
      };

      this.fetchTableData = this.fetchTableData.bind(this);
      this.handleFilterData = this.handleFilterData.bind(this);
      this.handleAnalyseData = this.handleAnalyseData.bind(this);
      this.handleCarbonData = this.handleCarbonData.bind(this);
      this.onDataChange = this.onDataChange.bind(this);

      this.unsubscribe = () => { };
    }
  
    componentDidUpdate(prevProps: Props) {
      if (this.props.buildings !== prevProps.buildings) {
          if (this.unsubscribe) {
              this.unsubscribe();
          }
          this.fetchTableData();
      }
    }

    componentDidMount() {
      this.fetchTableData();
    }
  
    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
    }
    }

    fetchTableData(){
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      let deviceMap: DeviceMap = new Map();
      let subscriptions: any = []
      for (const [BuildingName, BuildingObject] of Object.entries(this.props.buildings)) {
        for (const [LevelName, LevelObject] of Object.entries(BuildingObject)) {
          for (const deviceName of LevelObject.registries.device) {
            const pattern = /^(EM)-\d+$/;
            if(pattern.test(deviceName)){
              deviceMap.set(deviceName, { BuildingName: BuildingName, LevelName: LevelName });
              const unsubscribe = LuminaireDataService.getDevice(LevelName, deviceName).onSnapshot((snapshot) => {
                callbackQueue.enqueue(() => {
                  this.onDataChange(snapshot, deviceName);
                  return Promise.resolve();
                });
              });
              subscriptions.push(unsubscribe);
            }
          }
        }
      }
      this.setState({deviceMap:deviceMap});
      this.unsubscribe = () => {
        for (const unsubscribe of subscriptions) {
            unsubscribe();
        }
      };
    }

    handleFilterData(chartData: CtNetChartData, chartDisplay: CtNetChartDisplay): Promise<void> {
      console.log('meter variation1 handleFilterData');
      return new Promise<void>((resolve) => {
        this.setState({
          chartData: chartData,
          chartDisplay: chartDisplay
        }, () => {
          resolve(); // Resolve the promise after the state has been updated
        });
      });
    }

    handleAnalyseData(chartDataMap: CtNetChartDataMap[]): Promise<void> {
      console.log('meter variation1 handleAnalyseData');
      // for(const device of chartDataMap){
      //   console.log(device.name + " size:" + device.chartData.length);
      // }
      return new Promise<void>((resolve) => {
        this.setState({
          chartDataMap: chartDataMap,
        }, () => {
          resolve(); // Resolve the promise after the state has been updated
        });
      });
    }

    handleCarbonData(regionalData: RegionalData): Promise<void> {
      console.log('meter variation1 regionalData:' + JSON.stringify(regionalData));
      return new Promise<void>((resolve) => {
        this.setState({
          regionalData: regionalData,
        }, () => {
          resolve(); // Resolve the promise after the state has been updated
        });
      });
    }

    onDataChange(items: any, deviceName: string) {
      const { deviceMap } = this.state;
      console.log("onDataChange");
      let areaName ='-';
      let roomName ='';
      items.forEach((item: any) => {
          let id:string = item.id;
          let data = item.data();
          console.log(deviceName)
          const deviceInfo = deviceMap.get(deviceName);
          let levelName = deviceInfo ? deviceInfo.LevelName : '-';
          let buildingName = deviceInfo ? deviceInfo.BuildingName : '-';

          if(id === 'metadata'){
            console.log('metadata');
            console.log(JSON.stringify(data));
            //may need to get metadata and save to state
            let regex = /1st Floor|Ground|GF|1st/gi;
            if (data.LocationDescription) {
              roomName = data.LocationDescription.replace(regex, '').trim();
            } else if (data.system && data.system.location && data.system.location.room) {
                roomName = data.system.location.room;
            } else {
                roomName = '-';
            }

          }
          if(id === 'pointset'){
            console.log('pointset');
              // Ensure that 'data.timestamp' and 'data.timestamp.seconds' exist
              if (data.timestamp && typeof data.timestamp.seconds === 'number') {
                  const dateMillis = data.timestamp.seconds * 1000
                  const deviceDate = new Date(dateMillis)
                  
                  const isoTimestamp = deviceDate.toISOString();
                  const formattedTimestamp = createHumanTimestamp(isoTimestamp);

                  const deviceOnline = isDeviceOnline(deviceDate);
                  const lastSeen = calculateLastSeen(dateMillis);
                  const lastSeenDays = lastSeen[0];
                  const lastSeenHours = lastSeen[1];
                  const lastSeenMins = lastSeen[2];
  
                  const flattenedPoint: ExtendedICtNetData = {
                      ...data.points as any,  
                      version: data.version,
                      name: { present_value: deviceName }, 
                      level: { present_value: levelName },
                      area: { present_value: areaName },
                      room: { present_value: roomName },   
                      timestamp: { present_value: formattedTimestamp },
                      online: {present_value: deviceOnline},
                      last_seen_days: {present_value: lastSeenDays},
                      last_seen_hours: {present_value: lastSeenHours},
                      last_seen_mins: {present_value: lastSeenMins}
                  };
  
                  this.setState(prevState => {
                    const index = prevState.flattenedPointset.findIndex(point => point.name.present_value === deviceName);
                    
                    if (index !== -1) {
                        // If the device exists in the array, create a new array with updated data for that device
                        const updatedPoints = [...prevState.flattenedPointset];
                        updatedPoints[index] = flattenedPoint;
                        return {
                            ...prevState,
                            flattenedPointset: updatedPoints
                        };
                    } else {
                        // If the device doesn't exist in the array, append it
                        return {
                            ...prevState,
                            flattenedPointset: [...prevState.flattenedPointset, flattenedPoint]
                        };
                    }
                });
              } else {
                  console.error('Timestamp data is missing or in incorrect format', data);
              }
          }
      });
    }

    calculateTicks(data:CtNetChartData, numTicks:number) {
      const step = Math.floor(data.length / numTicks);
      return data
        .filter((_, index) => index % step === 0)
        .map(item => item.timestamp);
    }

    roundToTwo(value:number) {
      return(Math.round(value * 100) / 100);
    }

    aggregateHourlyValues(chartDataMap: CtNetChartDataMap[]){
      let deviceChartMap: Map<string, HourlyAverage[]> = new Map<string, HourlyAverage[]>();
      for(const device of chartDataMap){
        // console.log(device.name + " size:" + device.chartData.length);
        
        const groupedData = device.chartData.reduce((accumulator: Map<string, number[]>, current: PureCtNetChartDataItem) => {
          const date = new Date(current.timestamp);
          const hour = date.toISOString().substring(0, 13); // Extract 'YYYY-MM-DDTHH'
        
          if (!accumulator.has(hour)) {
            accumulator.set(hour, []);
          }
        
          accumulator.get(hour)!.push(current.total);
        
          return accumulator;
        }, new Map<string, number[]>());

        const hourlyAverages: HourlyAverage[] = [];

        for (const [hour, totals] of groupedData.entries()) {
          const sum = totals.reduce((a, b) => a + b, 0);
          const avg = sum / totals.length;

          hourlyAverages.push({
            hour,
            averageTotal: this.roundToTwo(avg),
          });
        }

        // Optionally, sort the results by hour
        hourlyAverages.sort((a, b) => a.hour.localeCompare(b.hour));
        deviceChartMap.set(device.name, hourlyAverages);
      }
      return deviceChartMap;
    }

    createGraphSeries(deviceChartMap:Map<string, HourlyAverage[]>){
      const series = [];

      for (const [name, hourlyAverages] of deviceChartMap.entries()) {
        const averageTotals = hourlyAverages.map((entry) => entry.averageTotal);
        series.push({
          name: name,
          data: averageTotals,
        });
      }
      return series;
  }

  calculateTotalsForToday(deviceChartMap:Map<string, HourlyAverage[]>){
    const todayDateStr = new Date().toISOString().substring(0, 10);
    const totalsByKey: Map<string, number> = new Map<string, number>();

    for (const [key, hourlyAverages] of deviceChartMap.entries()) {
      const todaysEntries = hourlyAverages.filter((entry) => {
        const entryDateStr = entry.hour.substring(0, 10);
        return entryDateStr === todayDateStr;
      });

      const totalForToday = todaysEntries.reduce((sum, entry) => sum + entry.averageTotal, 0);

      totalsByKey.set(key, totalForToday);
    }
    return totalsByKey;
  }

  removeZeroPercValues(arr: GenerationMix[]) {
    return arr.filter((item) => item.perc !== 0);
  }

  filterCarbonDataLastRecord(data: DataPoint[]) {
    const temp = [
      // { "fuel": "biomass", "perc": 0 },
      // { "fuel": "coal", "perc": 0 },
      { "fuel": "imports", "perc": 42.5 },
      { "fuel": "gas", "perc": 16.8 },
      // { "fuel": "nuclear", "perc": 0 },
      // { "fuel": "other", "perc": 0 },
      { "fuel": "hydro", "perc": 1.6 },
      // { "fuel": "solar", "perc": 0 },
      { "fuel": "wind", "perc": 39.1 }
    ];
  
    if (!Array.isArray(data)) {
      console.error("Data is not an array:", data);
      return temp; 
    }
  
    const now = new Date();
    const fiveMinutesInMillis = 5 * 60 * 1000;
  
    const filtered = data.filter((record) => {
      const toDate = new Date(record.to);
      const diff = Math.abs(toDate.getTime() - now.getTime());
      return diff <= fiveMinutesInMillis;
    });
  
    
    if (!filtered || filtered.length === 0) {
      return temp;
    } else {
      console.log("Filtered:", filtered);
      // If filtered is an array of DataPoint, and you want to return generationmix of the first record
      return this.removeZeroPercValues(filtered[0].generationmix) || temp;
    }
  }

      render() {
        const { flattenedPointset, chartData, chartDataMap, chartDisplay, meteringData, regionalData } = this.state;

        console.log(flattenedPointset);

        const calculateMaxYValue = (chartData:CtNetChartData, chartDisplay:CtNetChartDisplay) => {
          if (!Array.isArray(chartData) || chartData.length === 0) {
            return 3;
          }

          return Math.max(
            ...chartData.map(d => Math.max(
              chartDisplay.phase_1 ? d.phase_1 ?? 0 : 0,
              chartDisplay.phase_2 ? d.phase_2 ?? 0 : 0,
              chartDisplay.phase_3 ? d.phase_3 ?? 0 : 0,
              chartDisplay.total ? d.total ?? 0 : 0,
              chartDisplay.sensor_battery ? d.sensor_battery ?? 0 : 0,
              chartDisplay.compare_phase_1 ? d.compare_phase_1 ?? 0 : 0,
              chartDisplay.compare_phase_2 ? d.compare_phase_2 ?? 0 : 0,
              chartDisplay.compare_phase_3 ? d.compare_phase_3 ?? 0 : 0,
              chartDisplay.compare_total ? d.compare_total ?? 0 : 0,
              chartDisplay.compare_sensor_battery ? d.compare_sensor_battery ?? 0 : 0
            ))
          );
        };

        const RenderRechart = (chartData:CtNetChartData, chartDisplay:CtNetChartDisplay) => {
          return(
            <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
            <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={createHumanTimestamp}
                // ticks={this.calculateTicks(chartData, 7)}
                angle={-45} 
                textAnchor="end"
                height={120}
              />
              <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, 40]}/> 
              {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
              <YAxis label={{ value: "A", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 25]} interval={0}/>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {chartDisplay.phase_1 ? (<Line yAxisId="left" type="monotone" dataKey="phase_1" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.phase_2 ? (<Line yAxisId="left" type="monotone" dataKey="phase_2" stroke="#82ca9d" />) : null}
              {chartDisplay.phase_3 ? (<Line yAxisId="left" type="monotone" dataKey="phase_3" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.total ? (<Line yAxisId="right" type="monotone" dataKey="total" stroke="#C06000" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.sensor_battery ? (<Line yAxisId="right" type="monotone" dataKey="sensor_battery" stroke="#8e67bf" />) : null}

              {chartDisplay.compare_phase_1 ? (<Line yAxisId="left" type="monotone" dataKey="compare_phase_1" stroke="#f26d7d" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_phase_2 ? (<Line yAxisId="left" type="monotone" dataKey="compare_phase_2" stroke="#548b72" />) : null}
              {chartDisplay.compare_phase_3 ? (<Line yAxisId="left" type="monotone" dataKey="compare_phase_3" stroke="#b23a48" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_total ? (<Line yAxisId="right" type="monotone" dataKey="compare_total" stroke="#b2a300" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_sensor_battery ? (<Line yAxisId="right" type="monotone" dataKey="compare_sensor_battery" stroke="#5c3d6f" />) : null}
            </LineChart>
          </ResponsiveContainer>
          );
        };

        const renderMenuItems = (metering: Array<ExtendedICtNetData>) => {
          return metering.map((item: ExtendedICtNetData) => {
            let name = item.name.present_value;
            let max_capacity = 20;
            let current_target = 20;
            
            if (name === "EM-00301") {
              max_capacity = 20;
              current_target = 10;
            }
            
            if (name === "EM-00302") {
              max_capacity = 20;
              current_target = 7;
            }
            
            if (name === "EM-00303") {
              max_capacity = 20;
              current_target = 9;
            }
            
            if (name === "EM-00304") {
              max_capacity = 20;
              current_target = 9.5;
            }
            let percentage = Math.round((item.total.present_value / max_capacity) * 100);

            return(
              <Col>
                <Card className="mb-4">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    {name}
                  </div>
                  </Card.Header>
                  <Card.Body>
                    <SecondDial item={item}  percentage={percentage} max_capacity={max_capacity} current_target={current_target}/>
                  </Card.Body>
                </Card>
              </Col>
            );
          })
        };

        const deviceNames: DeviceName = [
          {name:"EM-00301", checked:true},
          {name:"EM-00302", checked:false},
          {name:"EM-00303", checked:false},
          {name:"EM-00304", checked:false},
      ];
      
      
      let deviceChartMap:Map<string, HourlyAverage[]> = this.aggregateHourlyValues(chartDataMap)
      let totalsByKey:Map<string, number> = this.calculateTotalsForToday(deviceChartMap);

      let unit1 = 0;
      let unit2 = 0;
      let unit3 = 0;
      let unit4 = 0;

      for (const [key, total] of totalsByKey.entries()) {
        if (key === 'EM-00301') unit1 = total;
        if (key === 'EM-00302') unit2 = total;
        if (key === 'EM-00303') unit3 = total;
        if (key === 'EM-00304') unit4 = total;
      }


      const unit1_kw_rate = 0.3042;
      const unit2_kw_rate = 0.3363;
      const unit3_kw_rate = 0.3046;
      const unit4_kw_rate = 0.2865;

      const total_kwh_day = this.roundToTwo(unit1 + unit2 + unit3 + unit4);
      const total_gbp_day = this.roundToTwo((unit1 * unit1_kw_rate) + (unit2 * unit2_kw_rate) + (unit3 * unit3_kw_rate) + (unit4 * unit4_kw_rate));

      const total_co2e_day = this.roundToTwo(((total_kwh_day * 136) / 1000));
      console.log("regionalData.data")

      console.log("filterCarbonDataLastRecord:" + JSON.stringify(regionalData.data));
      const carbon_intensity = this.filterCarbonDataLastRecord(regionalData.data);

      const COLORS = [
        '#D35400', // Burnt Orange
        '#27AE60', // Medium Green
        '#2980B9', // Deep Blue
        '#8E44AD', // Soft Purple
        '#F39C12', // Amber
        '#16A085', // Teal
        '#C0392B', // Brick Red
        '#7F8C8D', // Slate Gray
        '#BDC3C7'  // Light Gray
      ];

      let graphSeries = this.createGraphSeries(deviceChartMap);

      // console.log(JSON.stringify(graphSeries));
        return (
            <>
        {/* <Meter_Type_1_DeviceHolder /> */}
        <Container fluid>
        <Card className="mb-3">
            <Card.Header className="card-header-tab z-index-6">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-charts icon-gradient bg-happy-green"> {" "} </i>
                Energy Performance
              </div>
              <div className="btn-actions-pane-right text-capitalize">
                <span className="d-inline-block ml-2" style={{ width: 200 }}>
                  {/* <Select value={selectedOption} onChange={this.handleChange} options={options}/> */}
                </span>
              </div>
            </Card.Header>
            <Row className="no-gutters">
              <Col sm="6" md="4" xl="4">
                <CarbonIntensity
                  postcode="EN11"
                  handleCarbonData={this.handleCarbonData}
                />
                <CtNetAnalyseData
                  handleAnalyseData={this.handleAnalyseData}
                  deviceNames={deviceNames}
                  level={'Level-00'}
                />
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Current Day Power Total</div>
                    <div className="widget-numbers">{total_kwh_day} kWh</div>
                    <div className="widget-description opacity-8 text-focus">
                    Increased by
                      <div className="d-inline text-danger pr-1">
                        <FontAwesomeIcon icon={faAngleDown} />
                        <span className="pl-1">1.1%</span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="6" md="4" xl="4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-9 bg-danger" />
                    <i className="lnr-graduation-hat text-white" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Current Day Carbon Emissions</div>
                    <div className="widget-numbers">
                      <CountUp start={0} end={total_co2e_day} separator="" decimals={0} decimal=","
                        prefix="" useEasing={false} suffix=" kgCO₂e"/>
                    </div>
                    <div className="widget-description opacity-8 text-focus">
                      Decreased by
                      <span className="text-info pl-1">
                        <FontAwesomeIcon icon={faAngleDown} />
                        <span className="pl-1">4.1%</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="12" md="4" xl="4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-9 bg-success" />
                    <i className="lnr-apartment text-white" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Current Day Power Cost</div>
                    <div className="widget-numbers text-success">
                      <CountUp start={0} end={total_gbp_day} separator="" decimals={0} decimal="."
                        prefix="£" useEasing={false} suffix=""/>
                    </div>
                    <div className="widget-description text-focus">
                      Increased by
                      <span className="text-warning pl-1">
                        <FontAwesomeIcon icon={faAngleUp} />
                        <span className="pl-1">1.1%</span>
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
           
            <Card.Footer className="text-center d-block p-3">
              {/* <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                <span className="mr-2 opacity-7">
                  <Ionicon color="#ffffff" icon="ios-analytics-outline" beat={true}/>
                  <IoIosAnalytics color="#ffffff" />
                </span>
                <span className="mr-1">View Complete Report</span>
              </Button> */}
            </Card.Footer>
          </Card>
        <Row> 
                {renderMenuItems(flattenedPointset)}
        </Row>
        <Row>
        <Col md="12" lg="6" xl="7">
              <Card className="main-card mb-3">
                <BarGraphStacked series={graphSeries} buildings={this.props.buildings}/>
              </Card>
            </Col>
            <Col md="12" lg="6" xl="5">
              <Card className="main-card mb-3">
              <Card.Header className="card-header-tab z-index-6">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-charts icon-gradient bg-happy-green"> {" "} </i>
                Electricity Grid Carbon Intensity
              </div>
            </Card.Header>
                <Card.Body>
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={carbon_intensity}
                      dataKey="perc"
                      nameKey="fuel"
                      outerRadius={150}
                      fill="#8884d8"
                      label
                    >
                      {carbon_intensity.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={36} />
                  </PieChart>
                </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
        </Row>
        <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    3 Phase CT Clamp Graph Filter
                  </div>
                  {/* <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Settings</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                </Card.Header>
                <Card.Body>
                  <CtNetFilterData
                    deviceId={'EM-00301'}
                    handleFilterData={this.handleFilterData}
                    deviceNames={deviceNames}
                    level={'Level-00'}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>3 Phase CT Clamp Timeseries</Card.Title>
                 {RenderRechart(chartData, chartDisplay)}
                </Card.Body>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    3 Phase CT Clamp Status
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Actions</Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Generate Report</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                    <div className="-striped -highlight -fixed">
                  <ReactTable data={flattenedPointset}
                     columns={[
                      {
                        Header: "3 Phase CT Clamp",
                        columns: [
                          {
                            Header: "Online",
                            id: "deviceOnline",
                            accessor: (row: ExtendedICtNetData) => row.online.present_value,
                            Cell: ({ value }: { value: boolean }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                            width: 100,
                          },
                          {
                            Header: "Name",
                            id: "deviceName",
                            accessor: (row: ExtendedICtNetData) => row.name.present_value,
                            width: 100,
                          },
                          {
                            Header: "Level",
                            id: "level",
                            accessor: (row: ExtendedICtNetData) => row.level.present_value,
                            width: 100,
                          },
                          {
                            Header: "Area",
                            id: "area",
                            accessor: (row: ExtendedICtNetData) => row.area.present_value,
                            width: 100,
                          },
                          {
                            Header: "Room",
                            id: "room",
                            accessor: (row: ExtendedICtNetData) => row.room.present_value,
                            width: 100,
                          },
                          // {
                          //   Header: "Alarm",
                          //   id: "ahuAlarm",
                          //   accessor: (row: ExtendedIMeterData) => row.alarm.present_value,
                          //   width: 100,
                          // }
                        ]
                      },
                      {
                        Header: "Last Seen",
                        columns: [
                          {
                            Header: "Timestamp",
                            id: "deviceTimestamp",
                            accessor: (row: ExtendedICtNetData) => row.timestamp.present_value,
                            width: 200,
                          },
                          {
                            Header: "Days",
                            id: "deviceLastSeenDays",
                            accessor: (row: ExtendedICtNetData) => row.last_seen_days.present_value,
                            width: 100,
                          },
                          {
                            Header: "Hours",
                            id: "deviceLastSeenHours",
                            accessor: (row: ExtendedICtNetData) => row.last_seen_hours.present_value,
                            width: 100,
                          },
                          {
                            Header: "Mins",
                            id: "deviceLastSeenMins",
                            accessor: (row: ExtendedICtNetData) => row.last_seen_mins.present_value,
                            width: 100,
                          },
                        ]
                      },
                      {
                        Header: "State",
                        columns: [
                          {
                            Header: "phase_1",
                            id: "phase_1",
                            accessor: (row: ExtendedICtNetData) =>
                              row.phase_1?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "phase_2",
                            id: "phase_2",
                            accessor: (row: ExtendedICtNetData) =>
                              row.phase_2?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "phase_3",
                            id: "phase_3",
                            accessor: (row: ExtendedICtNetData) =>
                              row.phase_3?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "total",
                            id: "total",
                            accessor: (row: ExtendedICtNetData) =>
                              row.total?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "sensor_battery",
                            id: "sensor_battery",
                            accessor: (row: ExtendedICtNetData) =>
                              row.sensor_battery?.present_value || '-',
                            width: 120,
                          }
                        ],
                      },
                    ]}
                    />
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container> 
            </>
    );
  }
}