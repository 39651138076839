import React, { useCallback, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import firebase from 'firebase/compat/app';
import { IArticle, ArticleState } from '../redux/types/article.type';
import { Article } from '../redux/article';
import { AddArticle } from '../redux/add-article';
import { fetchArticles, addArticle, removeArticle } from '../redux/actions/article.action';

import { StoreState } from '../redux/types/store.type';

interface OwnProps {
  user: firebase.User | null;
}

type MapDispatchToPropsType = {
  fetchArticles: () => void;
  saveArticle: (article: IArticle) => void;
};

type Props = ConnectedProps<typeof connector> & OwnProps & ArticleState & MapDispatchToPropsType;

const useArticleActions = () => {
  const dispatch = useDispatch<ThunkDispatch<ArticleState, unknown, AnyAction>>();

  const memoizedFetchArticles = useCallback(() => dispatch(fetchArticles()), [dispatch]);
  const memoizedSaveArticle = useCallback((article: IArticle) => dispatch(addArticle(article)), [dispatch]);

  return {
    fetchArticles: memoizedFetchArticles,
    saveArticle: memoizedSaveArticle,
  };
};

const ArticlesList: React.FC<Props> = ({ user, articles, fetchArticles, saveArticle }) => {
  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  return (
    <>
      {user ? (
        <div className="list row">
          <div className="container mt-3">
            <h2>My Articles</h2>
            <AddArticle saveArticle={saveArticle} />
            {articles?.map((article: IArticle) => (
              <Article key={article.id} article={article} removeArticle={removeArticle} />
            ))}
          </div>
        </div>
      ) : (
        <p className="text-center">Articles Access denied</p>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState.All): ArticleState => {
  return {
    articles: state.Articles.articles
  };
};

const connector = connect(mapStateToProps);

export default connector((props: Props) => {
  const { fetchArticles, saveArticle } = useArticleActions();

  return <ArticlesList {...props} fetchArticles={fetchArticles} saveArticle={saveArticle} />;
});
