import {
  ChangeEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import update from "immutability-helper";
import { ColorResult } from "react-color";
import { useGraphicComponentsContext } from "../../providers/graphic-components-provider";
import { useSelectorContext } from "../../providers/selector-provider";
import { LabelComponentProps } from "../../../types/draggable-component";
import SizeInput from "./shared/size-input";
import ColorPicker from "./shared/color-picker";
import TextInput from "./shared/text-input";

function LabelEditor() {
  const { selectedComponent } = useSelectorContext();
  const { components, setComponents } = useGraphicComponentsContext();

  const initialLabelText = selectedComponent?.label?.text
    ? selectedComponent?.label?.text
    : "";
  const initialLabelFontSize = selectedComponent?.label?.fontSize
    ? selectedComponent.label.fontSize
    : 12;

  const initialLabelColor = selectedComponent?.label?.color
    ? selectedComponent.label.color
    : "#374151";

  const [labelText, setLabelText] = useState<string>(initialLabelText);
  const [labelFontSize, setLabelFontSize] =
    useState<number>(initialLabelFontSize);
  const [labelColor, setLabelColor] = useState<string>(initialLabelColor);

  useEffect(() => {
    setLabelText(selectedComponent!.label!.text!.toString());
    setLabelFontSize(selectedComponent!.label!.fontSize!);
    setLabelColor(selectedComponent!.label!.color!);
  }, [selectedComponent?.id]);

  const onLabelTextChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setLabelText(event.target.value);
    let text: string = event.target.value.toString();
    updateLabel(selectedComponent!.id, {
      ...components[selectedComponent!.id].label,
      text,
    });
  };

  const onLabelFontSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newFontSize: number = +event.target.value;
    if (!isFontSizeValid(newFontSize)) return;
    setLabelFontSize(newFontSize);
    updateLabel(selectedComponent!.id, {
      ...components[selectedComponent!.id].label,
      fontSize: newFontSize,
    });
  };

  const updateLabelFontSize = (offset: number) => {
    let newFontSize: number =
      components[selectedComponent!.id].label!.fontSize! + offset;
    if (!isFontSizeValid(newFontSize)) return;
    setLabelFontSize(newFontSize);
    updateLabel(selectedComponent!.id, {
      ...components[selectedComponent!.id].label,
      fontSize: newFontSize,
    });
  };

  const isFontSizeValid = (newFontSize: number) => {
    return newFontSize >= 1 && newFontSize <= 72;
  };

  const onColorChange = (color: ColorResult) => {
    setLabelColor(color.hex);
    updateLabel(selectedComponent!.id, {
      ...components[selectedComponent!.id].label,
      color: color.hex,
    });
  };

  const updateLabel = useCallback(
    (id: string, label: LabelComponentProps) => {
      setComponents(
        update(components, {
          [id]: {
            $merge: { label },
          },
        })
      );
    },
    [components]
  );

  return (
    <div className="p-2.5 rounded shadow bg-white">
      <h4 className="font-normal text-sm mb-4">Label</h4>
      <TextInput title="Text" value={labelText} onChange={onLabelTextChange} />

      <div className="flex" style={{ justifyContent: "space-between" }}>
        <ColorPicker
          title="Color"
          color={labelColor}
          onColorChange={onColorChange}
        />
        <SizeInput
          title="size"
          fontSize={labelFontSize}
          updateFontSize={updateLabelFontSize}
          onFontSizeChange={onLabelFontSizeChange}
        />
      </div>
    </div>
  );
}

export default LabelEditor;
