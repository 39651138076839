import firebase from "..";
// import {IFcuData} from "../types/fcu.type"

const db = firebase.collection("Buildings/UK-LONDON-WENLOCK-22/Level-01/registries/device/FCU-1/types/");

class FcuDataService {
  getAll() {
    return db;
  }
  
  getDevice(device:string) {
    return firebase.collection("Buildings/UK-LONDON-WENLOCK-22/Level-01/registries/device/" + device + "/types/");
  }

  getDeviceTimeseries(device:string, start:Date, end:Date) {
    return firebase.collection('/Buildings/UK-LONDON-WENLOCK-22/Level-01/registries/device/' + device + '/history/').where('timestamp', '>=', start).where('timestamp', '<=', end);
  }

  // create(tutorial: ITutorialData) {
  //   return db.add(tutorial);
  // }

  // update(id: string, value: any) {
  //   return db.doc(id).update(value);
  // }

  // delete(id: string) {
  //   return db.doc(id).delete();
  // }
}

export default new FcuDataService();
