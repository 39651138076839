import React, { Component } from "react";
import cx from "classnames";

// import TitleComponent1 from "./PageTitleExamples/Variation1";
// import TitleComponent2 from "./PageTitleExamples/Variation2";
// import TitleComponent3 from "./PageTitleExamples/Variation3";

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../redux/types/store.type';

import {ThemeOptionsState} from "../../redux/types/themeOptions.type";

interface OwnProps {
    heading: string;
    subheading: string;
    icon: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState
  };

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;


const PageTitle: React.FC<Props> = ({ ThemeOptions, heading, icon, subheading }) => {
    // const randomize = (myArray) => {
    //     return myArray[Math.floor(Math.random() * myArray.length)];
    //   }
    return (
        <>
            <div className="app-page-title">
                <div className="page-title-wrapper">
                <div className="page-title-heading">
                    <div className={cx("page-title-icon", {
                        "d-none": !ThemeOptions.enablePageTitleIcon,
                    })}>
                    <i className={icon} />
                    </div>
                    <div>
                    {heading}
                    <div className={cx("page-title-subheading", {
                        "d-none": !ThemeOptions.enablePageTitleSubheading,
                        })}>
                        {subheading}
                    </div>
                    </div>
                </div>
                {/* <div className="page-title-actions">{this.randomize(arr)}</div> */}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions
    };
};

const connector = connect(mapStateToProps);

export default connector((props: Props) => {

return <PageTitle {...props} />;
});