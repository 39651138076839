import React from 'react';
import styled from 'styled-components';

interface ScoreSectionProps {
    title: string;
    value: string | string[];  // Now value can be either a string or an array of strings
  }

const Section = styled.div`
  justify-content: center; /* Centers horizontally */
  width: 100%;
  text-align: center;
`;

const ScoreTitle = styled.h3`
  font-size: 1.1em;
  color: #000;
  margin: 0;
`;

const ScoreValue = styled.h3`
  font-size: 1em;
  color: #000;
  display: block;  // Each value will be in a new line
`;

export const ScoreSection: React.FC<ScoreSectionProps> = ({ title, value }) => (
  <Section>
    <ScoreTitle>{title}</ScoreTitle>
    {/* Check if value is an array and map over it, otherwise just display the single value */}
    {Array.isArray(value) ? (
      value.map((val, index) => <ScoreValue key={index}>{val}</ScoreValue>)
    ) : (
      <ScoreValue>{value}</ScoreValue>
    )}
  </Section>
);