import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import GraphicLoader from "./components/graphic-loader";
import SettingsProvider from "./components/providers/settings-provider";
import GraphicComponentsProvider from "./components/providers/graphic-components-provider";
import SelectorProvider from "./components/providers/selector-provider";
import DragPreview from "./components/draggable-components/drag-preview";
import ComponentDeletionProvider from "./components/providers/component-deletion-provider";
import Sidebar from "./components/gaphic-editor-sidebar";
import GraphicContainer from "./components/graphic-container";
import GraphicDeviceTypeMappingProvider from "./components/providers/graphic-device-type-mapping-provider";

const GraphicEditor = () => {
  const [uploadedGraphic, setUploadedImage] = useState<File | null>(null);

  return (
    <DndProvider backend={HTML5Backend}>
      {/* 
          TODO: parent div height "h-[73vh]" have to be revisited.
          This is a temporary fix - Ultimately will have to fix the 
          outter dashboard layout to allow child div to fill parent completely 
      */}
      <div className="graphic-editor-page-container h-[79vh] overflow-hidden box-content">
        {uploadedGraphic && (
          <GraphicDeviceTypeMappingProvider>
            <SettingsProvider>
              <GraphicComponentsProvider graphicName={uploadedGraphic?.name}>
                <SelectorProvider>
                  <DragPreview />
                  <ComponentDeletionProvider>
                    <Sidebar />
                    <GraphicContainer graphic={uploadedGraphic!} />
                  </ComponentDeletionProvider>
                </SelectorProvider>
              </GraphicComponentsProvider>
            </SettingsProvider>
          </GraphicDeviceTypeMappingProvider>
        )}

        {!uploadedGraphic && (
          <GraphicLoader setUploadedImage={setUploadedImage} />
        )}
      </div>
    </DndProvider>
  );
};

export default GraphicEditor;
