import React from 'react';
import './fcu_type_1.css';
import fcu_heating_cooling from '../images/fcu_heating_cooling.png';
import supply_on from '../images/supply_on.gif';
import { GraphicComponents } from '../GraphicComponents';

import {IFcuData, ExtendedIFcuData} from '../../../../firebase/types/fcu.type';

type Props = {
  data?: IFcuData;
};

const FCU_TYPE_1: React.FC<Props> = ({data}) => {
  try {
    const isEmpty = (obj: IFcuData): boolean => {
      return Object.keys(obj).length === 0;
    }

    if (data === undefined || data === null || isEmpty(data)
    ) {
      return <h5>Loading...</h5>;
    }
    const rtn_temp_sp = RoundNumber(data.return_temperature_setpoint.present_value) + ' °C';
    const rtn_temp = RoundNumber(data.return_air_temperature_sensor.present_value) + ' °C';
    const flt_sts = data.filter_alarm_status_dirty.present_value;
    const sup_temp = RoundNumber(data.supply_air_temperature_sensor.present_value) +' °C';
    //const sup_press = RoundNumber(data.supply_air_static_pressure_sensor.present_value) +' Pa';
    const htn_valve = RoundNumber(data.heating_valve_percentage_command.present_value) +' %';
    const fan_enbl = data.supply_fan_run_command.present_value;
    const fan_sts = data.supply_fan_run_status.present_value;
    const fan_speed = RoundNumber(data.supply_fan_speed_percentage_command.present_value) + ' %';
    return (
        <>
        <div
          className='titleName'
        >
          
        <h1>FCU TYPE 1</h1>
        </div>
        {/* <TransformWrapper>
        <TransformComponent> */}
        <section
          style={{
            backgroundImage: `url(${fcu_heating_cooling})`
            
          }}
          className='fcu_type_1_wrapper'
        >
          <GraphicComponents.ImageBox
          top={115}
          left={420}
          value={fan_enbl}
          imageOn={supply_on}
          />

          <GraphicComponents.VLabelBox
            top={220}
            left={310}
            label='Return Temperature'
            value={rtn_temp}
          />

        <GraphicComponents.Box
            top={295}
            left={310}
            value={rtn_temp_sp}
            backgroundColor='green'
            color='white'
          />

          <GraphicComponents.VLabelBox
            top={10}
            left={370}
            label='Filter Status'
            value={flt_sts}
          />
           <GraphicComponents.Label 
           top={210} 
           left={435} 
           label='Supply Fan Operation'
           />

          <GraphicComponents.HLabelBox
            top={245}
            left={400}
            label='Enable'
            value={fan_enbl}
          />
          <GraphicComponents.HLabelBox
            top={285}
            left={400}
            label='Speed'
            value={fan_speed}
          />  
          <GraphicComponents.HLabelBox
            top={325}
            left={400}
            label='Status'
            value={fan_sts}
          />
          <GraphicComponents.VLabelBox
            top={210}
            left={735}
            label='Supply Temperature'
            value={sup_temp}
          />       
        <GraphicComponents.VLabelBox
            top={255}
            left={675}
            label='Heating Valve'
            value={htn_valve}
          />
        </section>
        {/* </TransformComponent>
        </TransformWrapper> */}
        </>
    );
  } catch (exception) {
    console.log(exception);
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p>Device Graphic not available.</p>
      </div>
    );
  }
};

function RoundNumber(number: number) {
  return Math.round(number * 100) / 100;
}

export default FCU_TYPE_1;
