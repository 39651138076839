export function modeToNumber(mode: string): number {
  switch (mode) {
    case 'Auto' || '-':
        return 0;
    case 'Low' || 'Cool':
        return 1;
    case 'Med' || 'Heat':
        return 2;
    case 'High':
        return 3;
    default:
        return -1;
  }
}
