import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { GraphicEditorSettings } from "../../types/settings";

interface Props {
  children?: React.ReactNode;
}

interface ContextProps {
  settings: GraphicEditorSettings;
  setSettings: Dispatch<SetStateAction<GraphicEditorSettings>>;
}

// Default settings onLoad
const defaultSettings: GraphicEditorSettings = {
  snapToGrid: false,
  componentDragTransparency: false,
};

const SettingsContext = createContext<ContextProps>({
  settings: defaultSettings,
  setSettings: () => {},
});

const SelectorProvider: FC<Props> = ({ children }) => {
  const [settings, setSettings] =
    useState<GraphicEditorSettings>(defaultSettings);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);

export default SelectorProvider;
