export default class CallbackQueue {
    private queue: Array<() => Promise<void>> = [];
    private isProcessing: boolean = false;
    private counter: number = 0;

    enqueue(callback: () => Promise<void>) {
        // console.log("enqueue:", this.counter += 1);
        this.queue.push(callback);
        if (!this.isProcessing) {
            this.processNext();
        }
    }

    private async processNext() {
        let queueLength = this.queue.length;
        // console.log("queueLength:" + queueLength)
        if (queueLength === 0) {
            this.isProcessing = false;
            return;
        }

        this.isProcessing = true;

        const callback = this.queue.shift();
        if (callback) {
            try {
                await callback();
            } catch (error) {
                console.error("An error occurred while processing the callback:", error);
            }
            this.processNext(); // recursively process the next item
        }
    }
}