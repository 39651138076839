import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";


import CountUp from "react-countup";

import styled from 'styled-components'
import { useTable, Column, useSortBy } from "react-table";

import FCU_TYPE_1 from '../../BMSComponents/components/fcu_type_1';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import FcuDataService from "../../../../firebase/services/fcu.service";
import CallbackQueue from "../../../../firebase/services/callback.service";
import {IFcuData, IFcuMetaData, ExtendedIFcuData} from '../../../../firebase/types/fcu.type';
import {FcuChartData, FcuChartDisplay} from '../../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen } from '../../../../utils/timestamp';
import FilterData from '../../../components/filterData';

import { BuildingState } from '../../../../redux/types/building.type';

function Fcu_Type_1_DeviceHolder () {
  let data = {
    return_temperature_setpoint: {present_value: 22.50},
    return_air_temperature_sensor: {present_value: 21.47},
    filter_alarm_status_dirty: {present_value: "clean"},
    supply_air_temperature_sensor: {present_value: 23.63},
    heating_valve_percentage_command: {present_value: 38.12},
    cooling_valve_percentage_command: {present_value: 0.00},
    supply_fan_run_command: {present_value: "enabled"},
    supply_fan_run_status: {present_value: "running"},
    supply_fan_speed_percentage_command: {present_value: 67.19},
    space_air_temperature_sensor: {present_value: 40.02}
  };
return (
  
      <div className='fcu-holder'>

        <FCU_TYPE_1 data={data}/>

      </div>

);
}

  function makeNewData(){
    return [
      {
        name: {present_value: "FCU-001"},
        timestamp: {present_value: "2022-08-10T09:30:00Z"},
        alarm: {present_value: "Healthy"},
        version:1,
        return_temperature_setpoint: {present_value: 28.02},
        return_air_temperature_sensor: {present_value: 21.00},
        filter_alarm_status_dirty: {present_value: "clean"},
        supply_air_temperature_sensor: {present_value: 40.02},
        heating_valve_percentage_command: {present_value: 40.02},
        cooling_valve_percentage_command: {present_value: 0.00},
        supply_fan_run_command: {present_value: "enabled"},
        supply_fan_run_status: {present_value: "running"},
        supply_fan_speed_percentage_command: {present_value: 50},
        space_air_temperature_sensor: {present_value: 40.02}
      },
      {
        name: {present_value: "FCU-002"},
        timestamp: {present_value: "2022-08-110T09:30:00Z"},
        alarm: {present_value: "Healthy"},
        version:1,
        return_temperature_setpoint: {present_value: 28.02},
        return_air_temperature_sensor: {present_value: 21.00},
        filter_alarm_status_dirty: {present_value: "clean"},
        supply_air_temperature_sensor: {present_value: 40.02},
        heating_valve_percentage_command: {present_value: 40.02},
        cooling_valve_percentage_command: {present_value: 0.00},
        supply_fan_run_command: {present_value: "enabled"},
        supply_fan_run_status: {present_value: "running"},
        supply_fan_speed_percentage_command: {present_value: 50},
        space_air_temperature_sensor: {present_value: 40.02}
      }
    ]
  }

  // const chartData = [
  //   { timestamp: "2021-02-12T06:52:00Z", heating_valve: 20.0, cooling_valve: 70.0, supply_temperature: 23.0, return_temperature: 21.0 },
  //   { timestamp: "2021-02-12T06:52:30Z", heating_valve: 30.0, cooling_valve: 60.0, supply_temperature: 23.5, return_temperature: 21.3 },
  //   { timestamp: "2021-02-12T06:53:00Z", heating_valve: 25.0, cooling_valve: 55.0, supply_temperature: 23.5, return_temperature: 21.5 },
  //   { timestamp: "2021-02-12T06:53:30Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.6 },
  //   { timestamp: "2021-02-12T06:54:00Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.4 },
  //   { timestamp: "2021-02-12T06:54:30Z", heating_valve: 27.0, cooling_valve: 40.0, supply_temperature: 22.5, return_temperature: 21.2 },
  //   { timestamp: "2021-02-12T06:55:00Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 21.0 },
  //   { timestamp: "2021-02-12T06:55:30Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 20.9 },
  //   { timestamp: "2021-02-12T06:56:00Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 22.5, return_temperature: 20.7 },
  //   { timestamp: "2021-02-12T06:56:30Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 23.0, return_temperature: 21.0 },
  //   { timestamp: "2021-02-12T06:57:00Z", heating_valve: 20.0, cooling_valve: 70.0, supply_temperature: 23.0, return_temperature: 21.0 },
  //   { timestamp: "2021-02-12T06:57:30Z", heating_valve: 30.0, cooling_valve: 60.0, supply_temperature: 23.5, return_temperature: 21.3 },
  //   { timestamp: "2021-02-12T06:58:00Z", heating_valve: 25.0, cooling_valve: 55.0, supply_temperature: 23.5, return_temperature: 21.5 },
  //   { timestamp: "2021-02-12T06:58:30Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.6 },
  //   { timestamp: "2021-02-12T06:59:00Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.4 },
  //   { timestamp: "2021-02-12T06:59:30Z", heating_valve: 27.0, cooling_valve: 40.0, supply_temperature: 22.5, return_temperature: 21.2 },
  //   { timestamp: "2021-02-12T07:00:00Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 21.0 },
  //   { timestamp: "2021-02-12T07:00:30Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 20.9 },
  //   { timestamp: "2021-02-12T07:01:00Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 22.5, return_temperature: 20.7 },
  //   { timestamp: "2021-02-12T07:01:30Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 23.0, return_temperature: 21.0 }
  // ];

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  interface ReactTableProps<T extends object> {
    data: T[];
    columns: Column<T>[];
  }



  function ReactTable<T extends object>({ data, columns }: ReactTableProps<ExtendedIFcuData>) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid grey' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '2px solid grey' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '5px',
                    backgroundColor: '#545cd8',
                    border: '1px solid grey',
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: rowIndex !== rows.length - 1 ? '1px solid grey' : undefined }}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '5px',
                        backgroundColor: cellIndex % 2 === 0 ? 'white' : 'lightgrey',
                        textAlign: 'center'
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
    
    
  }
  
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);
  const makeNewFcuData = makeNewData();

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
  `
  
  type Props = {
    buildings: BuildingState
  };

  type State = {
      flattenedPointset: Array<ExtendedIFcuData>,
      metadata: Array<IFcuMetaData>,
      brickschema: {},
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: [],
      chartData: FcuChartData;
      chartDisplay: FcuChartDisplay;
  };

  const callbackQueue = new CallbackQueue()
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);
      

      this.state = {
        flattenedPointset: [],
        metadata: [],
        brickschema: {},
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        chartData:[
          { timestamp: "2021-02-12T06:52:00Z", supply_fan_speed_percentage_command: 50.0, space_air_temperature_sensor: 23.0, heating_valve_percentage_command: 55.0, cooling_valve_percentage_command: 45.0, supply_air_temperature_sensor: 20.0, return_air_temperature_sensor: 10.0, return_temperature_setpoint:22.0},
          { timestamp: "2021-02-13T06:52:00Z", supply_fan_speed_percentage_command: 47.0, space_air_temperature_sensor: 23.0, heating_valve_percentage_command: 45.0, cooling_valve_percentage_command: 55.0, supply_air_temperature_sensor: 10.0, return_air_temperature_sensor: 20.0, return_temperature_setpoint:22.0 },
          { timestamp: "2021-02-14T06:52:00Z", supply_fan_speed_percentage_command: 50.0, space_air_temperature_sensor: 23.0, heating_valve_percentage_command: 55.0, cooling_valve_percentage_command: 45.0, supply_air_temperature_sensor: 20.0, return_air_temperature_sensor: 10.0, return_temperature_setpoint:22.0 }
        ],
        chartDisplay:{
          supply_fan_speed_percentage_command:false,
          space_air_temperature_sensor:false,
          return_air_temperature_sensor:true,
          heating_valve_percentage_command:true,
          cooling_valve_percentage_command:true,
          return_temperature_setpoint:false,
          supply_air_temperature_sensor:true
        }
      };

      this.fetchTableData = this.fetchTableData.bind(this);
      this.handleFilterData = this.handleFilterData.bind(this);
      this.onDataChange = this.onDataChange.bind(this);

      this.unsubscribe = () => { };
    }
  
    componentDidUpdate(prevProps: Props) {
      if (this.props.buildings !== prevProps.buildings) {
          if (this.unsubscribe) {
              this.unsubscribe();
          }
          this.fetchTableData();
      }
    }

    componentDidMount() {
      this.fetchTableData();
    }
  
    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
    }
    }

    fetchTableData(){
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      let subscriptions: any = []
      for (const [BuildingName, BuildingObject] of Object.entries(this.props.buildings)) {
        for (const [LevelName, LevelObject] of Object.entries(BuildingObject)) {
          for (const deviceName of LevelObject.registries.device) {
            const unsubscribe = FcuDataService.getDevice(deviceName).onSnapshot((snapshot) => {
              callbackQueue.enqueue(() => {
                this.onDataChange(snapshot, deviceName);
                return Promise.resolve();
              });
            });
            //Store subscriptions ready to unsubscribe when we unmount
            subscriptions.push(unsubscribe);
          }
        }
      }
      this.unsubscribe = () => {
        //Assign function this.unsubscribe with a function that iterates over the subscriptions and removes them all.
        for (const unsubscribe of subscriptions) {
            unsubscribe();
        }
      };
    }

    handleFilterData(chartData:FcuChartData, chartDisplay:FcuChartDisplay) {
      this.setState({
        chartData: chartData,
        chartDisplay: chartDisplay
      });
    }

    onDataChange(items: any, deviceName: string) {
      console.log("onDataChange");
  
      items.forEach((item: any) => {
          let id:string = item.id;
          let data = item.data();
          console.log(deviceName)
  
          if(id === 'pointset'){
              // Ensure that 'data.timestamp' and 'data.timestamp.seconds' exist
              if (data.timestamp && typeof data.timestamp.seconds === 'number') {
                  const dateMillis = data.timestamp.seconds * 1000
                  const deviceDate = new Date(dateMillis)
                  
                  const isoTimestamp = deviceDate.toISOString();
                  const formattedTimestamp = createHumanTimestamp(isoTimestamp);

                  const deviceOnline = isDeviceOnline(deviceDate);
                  const lastSeen = calculateLastSeen(dateMillis);
                  const lastSeenDays = lastSeen[0];
                  const lastSeenHours = lastSeen[1];
                  const lastSeenMins = lastSeen[2];
  
                  const flattenedPoint: ExtendedIFcuData = {
                      ...data.points as any,  
                      version: data.version,
                      name: { present_value: deviceName },  
                      timestamp: { present_value: formattedTimestamp },
                      online: {present_value: deviceOnline},
                      last_seen_days: {present_value: lastSeenDays},
                      last_seen_hours: {present_value: lastSeenHours},
                      last_seen_mins: {present_value: lastSeenMins}
                  };
  
                  this.setState(prevState => {
                    const index = prevState.flattenedPointset.findIndex(point => point.name.present_value === deviceName);
                    
                    if (index !== -1) {
                        // If the device exists in the array, create a new array with updated data for that device
                        const updatedPoints = [...prevState.flattenedPointset];
                        updatedPoints[index] = flattenedPoint;
                        return {
                            ...prevState,
                            flattenedPointset: updatedPoints
                        };
                    } else {
                        // If the device doesn't exist in the array, append it
                        return {
                            ...prevState,
                            flattenedPointset: [...prevState.flattenedPointset, flattenedPoint]
                        };
                    }
                });
              } else {
                  console.error('Timestamp data is missing or in incorrect format', data);
              }
          } else if(id === 'metadata'){
              // Handle metadata logic here
          }
      });
    }

    calculateTicks(data:FcuChartData, numTicks:number) {
      const step = Math.floor(data.length / numTicks);
      return data
        .filter((_, index) => index % step === 0)
        .map(item => item.timestamp);
    }

      render() {
        const { flattenedPointset, chartData, chartDisplay } = this.state;

        return (
            <>
        {/* <Fcu_Type_1_DeviceHolder /> */}
        <Container fluid>

        </Container> 
            </>
    );
  }
}