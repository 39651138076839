import React, { useCallback, useEffect, Fragment } from "react";

import { connect, ConnectedProps, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { BuildingState } from "../../redux/types/building.type";
import { fetchBuildings } from "../../redux/actions/building.action";
import { StoreState } from "../../redux/types/store.type";

import { ThemeOptionsState } from "../../redux/types/themeOptions.type";
import { AssetState } from "../../redux/types/asset.type";
import { fetchAssets } from "../../redux/actions/assets.action";

interface OwnProps {
  placeholder?: string;
}

type MapStateToPropsType = {
  ThemeOptions: ThemeOptionsState;
  Buildings: BuildingState;
  Assets: AssetState;
};

type MapDispatchToPropsType = {
  fetchBuildings: () => void;
  fetchAssets: () => void;
};

type Props = ConnectedProps<typeof connector> &
  OwnProps &
  MapStateToPropsType &
  MapDispatchToPropsType;

const useBuildingActions = () => {
  const dispatch =
    useDispatch<ThunkDispatch<BuildingState, unknown, AnyAction>>();

  const memoizedFetchBuildings = useCallback(
    () => dispatch(fetchBuildings()),
    [dispatch]
  );

  return {
    fetchBuildings: memoizedFetchBuildings,
  };
};

const useAssetActions = () => {
  const dispatch = useDispatch<ThunkDispatch<AssetState, unknown, AnyAction>>();
  const memoizedFetchAssets = useCallback(
    () => dispatch(fetchAssets()),
    [dispatch]
  );
  return {
    fetchAssets: memoizedFetchAssets,
  };
};

const BuildingDataService: React.FC<Props> = ({
  ThemeOptions,
  Buildings,
  fetchBuildings,
  fetchAssets,
}) => {
  useEffect(() => {
    fetchBuildings();
    fetchAssets();
  }, [fetchBuildings, fetchAssets]);

  return <></>;
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
  console.log("*** HERE ***");
  console.log(state);
  return {
    ThemeOptions: state.ThemeOptions,
    Buildings: state.Buildings,
    Assets: state.Assets,
  };
};

const connector = connect(mapStateToProps);

export default connector((props: Props) => {
  const { fetchBuildings } = useBuildingActions();
  const { fetchAssets } = useAssetActions();
  return (
    <BuildingDataService
      {...props}
      fetchBuildings={fetchBuildings}
      fetchAssets={fetchAssets}
    />
  );
});
