import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";


import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

  
  type Props = {
    placeholder?: string | null
  };
  
  type State = {
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: []
  };
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {

    constructor(props: Props) {
      super(props);

      this.state = {
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: []
      };

    }
  
    componentDidMount() {

    }
  
    componentWillUnmount() {

    }

      render() {
        return (
          <>
                <iframe 
                    src="https://myhub.autodesk360.com/ue2a9eea3/g/shares/SH35dfcQT936092f0e4395b16366a014a5a2"
                    title="Example Iframe"
                    width="100%" 
                    height="1500px"
                    frameBorder="0"  // This removes the default border around the iframe. Most websites use this to make the iframe look cleaner.
                    allowFullScreen  // This allows the iframe content to be viewed in full screen
                ></iframe>
          </>
        );
    }
}