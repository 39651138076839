import * as actionTypes from "../types/article.action.type"
import {IArticle, ArticleAction, ArticleState} from "../types/article.type"

const initialState: ArticleState = {
  articles: [
    {
      id: 1,
      title: "post 1",
      body:
        "Quisque cursus, metus vitae pharetra Nam libero tempore, cum soluta nobis est eligendi",
    },
    {
      id: 2,
      title: "post 2",
      body:
        "Harum quidem rerum facilis est et expedita distinctio quas molestias excepturi sint",
    },
  ],
}

function checkIdExists(state: ArticleState, actionId: Number){
  return !state.articles.find((a) => a.id === actionId)
}

const reducer = (
    state: ArticleState = initialState,
    action: ArticleAction
  ): ArticleState => {
    switch (action.type) {
      case actionTypes.ADD_ARTICLE:{
        const newArticle: IArticle = {
          id: Math.max(...state.articles.map((article) => article.id)) + 1,
          title: action.article.title,
          body: action.article.body,
        }
        return {
          ...state,
          articles: state.articles.concat(newArticle),
        }
      }
      case actionTypes.REMOVE_ARTICLE:{
        const updatedArticles: IArticle[] = state.articles.filter(
          article => article.id !== action.article.id
        )
        return {
          ...state,
          articles: updatedArticles,
        }
      }
      case actionTypes.FETCH_ARTICLES_SUCCESS:{
        var newArticles: IArticle[] = [];
        for(var i = 0; i < action.payload.length; i++){
          const actionId = action.payload[i].id;
          if(checkIdExists(state, actionId)){
            const newArticle: IArticle = {
              id: actionId,
              title: action.payload[i].title,
              body: action.payload[i].body
            }
            newArticles.push(newArticle);
          }
        }
        return {
          ...state,
          articles: state.articles.concat(newArticles),
        }
      }
    }
    return state
  }
  
  export default reducer