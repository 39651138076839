import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  BarChart, 
  Bar,
  PieChart,
  Pie,
  Sector,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";


import CountUp from "react-countup";

import styled from 'styled-components'
import { useTable, Column } from "react-table";

import AHU_TYPE_1 from '../../BMSComponents/components/ahu_type_1';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import AhuDataService from "../../../../firebase/services/ahu.service";
import {IAhuData, ExtendedIAhuData} from '../../../../firebase/types/ahu.type';

import TimeLine from '../../../components/timeLine';

interface CustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

interface PieChartData {
  name: string;
  value: number;
  color:string;
}

function Ahu_Type_1_DeviceHolder () {
    let data = {
      supply_air_static_pressure_sensor: {present_value: 150.30},
      supply_air_static_pressure_sp: {present_value: 120.00},
      supply_air_temperature_sensor: {present_value: 22.6},
      supply_air_temperature_sp: {present_value:23.00},
      filter_differential_pressure_sensor: {present_value: 132.43},
      filter_alarm_status_dirty: {present_value: "clean"},
      filter_alarm_status_sp: {present_value: 10},
      heating_valve_percentage_command: {present_value: 23.33},
      cooling_valve_percentage_command: {present_value: 0.00},
      supply_fan_run_command: {present_value: "Enabled"},
      supply_fan_run_status: {present_value: "Running"},
      supply_fan_speed_percentage_command: {present_value: 67.19},
      phx_off_temp_sensor: {present_value: 18.00},
      phx_pos: {present_value: 43.00},
      exhaust_damper_command: {present_value: "Open"},
      exhaust_damper_end_switch: {present_value: "Open"},
      exhaust_air_temperature_sensor: {present_value: 23.56},       
      return_air_static_pressure_sensor: {present_value: 150.31},
      return_air_static_pressure_sp: {present_value: 120.02},
      return_air_temperature_sensor: {present_value: 23.47},
      extract_fan_run_command: {present_value: "Enabled"},
      extract_fan_run_status: {present_value: "Running"},
      extract_fan_speed_percentage_command: {present_value: 50.02},
      panel_filter_differential_pressure_sensor: {present_value: 125.02},
      panel_filter_alarm_status_dirty: {present_value: "clean"},
      panel_filter_alarm_status_sp: {present_value: 10},
      in_panel_filter_differential_pressure_sensor: {present_value: 145.71},
      in_panel_filter_alarm_status_dirty: {present_value: "clean"},
      in_panel_filter_alarm_status_sp: {present_value: 10},
      phx_on_temp_sensor: {present_value: 18.00},
      frost_coil_command: {present_value: 11.00},
      off_frost_temp_sensor: {present_value: 24.00},
      frost_state: {present_value: "OK"},
      off_frost_temp_sp: {present_value: 8.00},
      intake_damper_command: {present_value: "Open"},
      intake_damper_end_switch: {present_value: "Open"},
      intake_air_temperature_sensor: {present_value: 19.34}
      };
    return (
      <div className='ahu-holder'>
        <AHU_TYPE_1 data={data}/>
      </div>
    );
  }

  function makeNewData(){
    return [
      {
        name: {present_value: "AHU-001"},
        timestamp: {present_value: "2022-08-10T09:30:00Z"},
        alarm: {present_value: "Healthy"},
        supply_air_static_pressure_sensor: {present_value: 150.30},
        supply_air_static_pressure_sp: {present_value: 120.00},
        supply_air_temperature_sensor: {present_value: 23.22},
        supply_air_temperature_sp: {present_value:23.00},
        filter_differential_pressure_sensor: {present_value: 132.43},
        filter_alarm_status_dirty: {present_value: "clean"},
        filter_alarm_status_sp: {present_value: 10},
        heating_valve_percentage_command: {present_value: 0.00},
        cooling_valve_percentage_command: {present_value: 55.00},
        supply_fan_run_command: {present_value: "Enabled"},
        supply_fan_run_status: {present_value: "Running"},
        supply_fan_speed_percentage_command: {present_value: 60.00},
        phx_off_temp_sensor: {present_value: 18.00},
        phx_pos: {present_value: 43.00},
        exhaust_damper_command: {present_value: "Open"},
        exhaust_damper_end_switch: {present_value: "Open"},
        exhaust_air_temperature_sensor: {present_value: 10.02},       
        return_air_static_pressure_sensor: {present_value: 150.31},
        return_air_static_pressure_sp: {present_value: 120.02},
        return_air_temperature_sensor: {present_value: 26.02},
        extract_fan_run_command: {present_value: "Enabled"},
        extract_fan_run_status: {present_value: "Running"},
        extract_fan_speed_percentage_command: {present_value: 50.02},
        panel_filter_differential_pressure_sensor: {present_value: 125.02},
        panel_filter_alarm_status_dirty: {present_value: "clean"},
        panel_filter_alarm_status_sp: {present_value: 10},
        in_panel_filter_differential_pressure_sensor: {present_value: 145.71},
        in_panel_filter_alarm_status_dirty: {present_value: "clean"},
        in_panel_filter_alarm_status_sp: {present_value: 10},
        phx_on_temp_sensor: {present_value: 18.00},
        frost_coil_command: {present_value: 11.00},
        off_frost_temp_sensor: {present_value: 24.00},
        frost_state: {present_value: "OK"},
        off_frost_temp_sp: {present_value: 8.00},
        intake_damper_command: {present_value: "Open"},
        intake_damper_end_switch: {present_value: "Open"},
        intake_air_temperature_sensor: {present_value: 24.74}
      },
      {
        name: {present_value: "AHU-002"},
        timestamp: {present_value: "2022-08-110T09:30:00Z"},
        alarm: {present_value: "Healthy"},
        supply_air_static_pressure_sensor: {present_value: 150.30},
        supply_air_static_pressure_sp: {present_value: 120.00},
        supply_air_temperature_sensor: {present_value: 23.22},
        supply_air_temperature_sp: {present_value:23.00},
        filter_differential_pressure_sensor: {present_value: 132.43},
        filter_alarm_status_dirty: {present_value: "clean"},
        filter_alarm_status_sp: {present_value: 10},
        heating_valve_percentage_command: {present_value: 0.00},
        cooling_valve_percentage_command: {present_value: 55.00},
        supply_fan_run_command: {present_value: "Enabled"},
        supply_fan_run_status: {present_value: "Running"},
        supply_fan_speed_percentage_command: {present_value: 60.00},
        phx_off_temp_sensor: {present_value: 18.00},
        phx_pos: {present_value: 43.00},
        exhaust_damper_command: {present_value: "Open"},
        exhaust_damper_end_switch: {present_value: "Open"},
        exhaust_air_temperature_sensor: {present_value: 23.56},       
        return_air_static_pressure_sensor: {present_value: 150.31},
        return_air_static_pressure_sp: {present_value: 120.02},
        return_air_temperature_sensor: {present_value: 26.02},
        extract_fan_run_command: {present_value: "Enabled"},
        extract_fan_run_status: {present_value: "Running"},
        extract_fan_speed_percentage_command: {present_value: 50.02},
        panel_filter_differential_pressure_sensor: {present_value: 125.02},
        panel_filter_alarm_status_dirty: {present_value: "clean"},
        panel_filter_alarm_status_sp: {present_value: 10},
        in_panel_filter_differential_pressure_sensor: {present_value: 145.71},
        in_panel_filter_alarm_status_dirty: {present_value: "clean"},
        in_panel_filter_alarm_status_sp: {present_value: 10},
        phx_on_temp_sensor: {present_value: 18.00},
        frost_coil_command: {present_value: 11.00},
        off_frost_temp_sensor: {present_value: 24.00},
        frost_state: {present_value: "OK"},
        off_frost_temp_sp: {present_value: 8.00},
        intake_damper_command: {present_value: "Open"},
        intake_damper_end_switch: {present_value: "Open"},
        intake_air_temperature_sensor: {present_value: 24.74}
      }
    ]
  }

  const chartData = [
    { timestamp: "2021-02-12T06:52:00Z", heating_valve: 20.0, cooling_valve: 70.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:52:30Z", heating_valve: 30.0, cooling_valve: 60.0, supply_temperature: 23.5, return_temperature: 21.3 },
    { timestamp: "2021-02-12T06:53:00Z", heating_valve: 25.0, cooling_valve: 55.0, supply_temperature: 23.5, return_temperature: 21.5 },
    { timestamp: "2021-02-12T06:53:30Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.6 },
    { timestamp: "2021-02-12T06:54:00Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.4 },
    { timestamp: "2021-02-12T06:54:30Z", heating_valve: 27.0, cooling_valve: 40.0, supply_temperature: 22.5, return_temperature: 21.2 },
    { timestamp: "2021-02-12T06:55:00Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:55:30Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 20.9 },
    { timestamp: "2021-02-12T06:56:00Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 22.5, return_temperature: 20.7 },
    { timestamp: "2021-02-12T06:56:30Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:57:00Z", heating_valve: 20.0, cooling_valve: 70.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:57:30Z", heating_valve: 30.0, cooling_valve: 60.0, supply_temperature: 23.5, return_temperature: 21.3 },
    { timestamp: "2021-02-12T06:58:00Z", heating_valve: 25.0, cooling_valve: 55.0, supply_temperature: 23.5, return_temperature: 21.5 },
    { timestamp: "2021-02-12T06:58:30Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.6 },
    { timestamp: "2021-02-12T06:59:00Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.4 },
    { timestamp: "2021-02-12T06:59:30Z", heating_valve: 27.0, cooling_valve: 40.0, supply_temperature: 22.5, return_temperature: 21.2 },
    { timestamp: "2021-02-12T07:00:00Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T07:00:30Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 20.9 },
    { timestamp: "2021-02-12T07:01:00Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 22.5, return_temperature: 20.7 },
    { timestamp: "2021-02-12T07:01:30Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 23.0, return_temperature: 21.0 }
  ];

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  interface ReactTableProps<T extends object> {
    data: T[];
    columns: Column<T>[];
  }



  function ReactTable<T extends object>({ data, columns }: ReactTableProps<ExtendedIAhuData>) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })

    // console.log("pre render");
    // const some = headerGroups.map(headerGroup => (
    //   console.log(headerGroup)
    //   // <tr {...headerGroup.getHeaderGroupProps()}>
    //   //   {headerGroup.headers.map(column => (
    //   //     <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    //   //   ))}
    //   // </tr>
    // ));
  
    // Render the UI for your table
    return (
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
  
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);
  const sampleData5 = randomData(11);
  const sampleData6 = randomData(9);
  const sampleData7 = randomData(8);
  const makeNewAhuData = makeNewData();

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
  `

  type RunTimeDataItem = {
    day:string;
    normal:number;
    overide:number;
  }

  type RuntimeData = RunTimeDataItem[];
  
  type Props = {
    placeholder?: string | null
  };
  
  type State = {
      data: Array<ExtendedIAhuData>,
      brickschema: {},
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: [],
      weeklyRuntimeData:RuntimeData;
  };
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);

      this.state = {
        data: makeNewAhuData,
        brickschema: {},
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        weeklyRuntimeData: [
          {
            day: 'S',
            normal: 3.5,
            overide: 0,
          },
          {
            day: 'M',
            normal: 4.5,
            overide: 0,
          },
          {
            day: 'T',
            normal: 7,
            overide: 0,
          },
          {
            day: 'W',
            normal: 15,
            overide: 0,
          },
          {
            day: 'T',
            normal: 4.5,
            overide: 10.5,
          },
          {
            day: 'F',
            normal: 10,
            overide: 0,
          },
          {
            day: 'S',
            normal: 3.5,
            overide: 0,
          }
        ]
      };

      this.onDataChange = this.onDataChange.bind(this)
      this.unsubscribe = () => { };
    }
  
    componentDidMount() {
      this.unsubscribe = AhuDataService.getAll().onSnapshot(this.onDataChange);
    }
  
    componentWillUnmount() {
      this.unsubscribe();
    }

    onPieEnter(){

    }

    onDataChange(items: any) {
        let ahus = new Array<ExtendedIAhuData>();
    
        items.forEach((item: any) => {
          let data = item.data();
          ahus.push({
            name: data.name,
            timestamp: data.timestamp,
            alarm: data.alarm,
            supply_air_static_pressure_sensor: data.supply_air_static_pressure_sensor,
            supply_air_static_pressure_sp: data.supply_air_static_pressure_sp,
            supply_air_temperature_sensor: data.supply_air_temperature_sensor,
            supply_air_temperature_sp: data.supply_air_temperature_sp,
            filter_differential_pressure_sensor: data.filter_differential_pressure_sensor,
            filter_alarm_status_dirty: data.filter_alarm_status_dirty,
            filter_alarm_status_sp: data.filter_alarm_status_sp,
            heating_valve_percentage_command: data.heating_valve_percentage_command,
            cooling_valve_percentage_command: data.cooling_valve_percentage_command,
            supply_fan_run_command: data.supply_fan_run_command,
            supply_fan_run_status: data.supply_fan_run_status,
            supply_fan_speed_percentage_command: data.supply_fan_speed_percentage_command,
            phx_off_temp_sensor: data.phx_off_temp_sensor,
            phx_pos: data.phx_pos,
            exhaust_damper_command: data.exhaust_damper_command,
            exhaust_damper_end_switch: data.exhaust_damper_end_switch,
            exhaust_air_temperature_sensor: data.exhaust_air_temperature_sensor,
            return_air_static_pressure_sensor: data.return_air_static_pressure_sensor,
            return_air_static_pressure_sp: data.return_air_static_pressure_sp,
            return_air_temperature_sensor: data.return_air_temperature_sensor,
            extract_fan_run_command: data.extract_fan_run_command,
            extract_fan_run_status: data.extract_fan_run_status,
            extract_fan_speed_percentage_command: data.extract_fan_speed_percentage_command,
            panel_filter_differential_pressure_sensor: data.panel_filter_differential_pressure_sensor,
            panel_filter_alarm_status_dirty: data.panel_filter_alarm_status_dirty,
            panel_filter_alarm_status_sp: data.panel_filter_alarm_status_sp,
            in_panel_filter_differential_pressure_sensor: data.in_panel_filter_differential_pressure_sensor,
            in_panel_filter_alarm_status_dirty: data.in_panel_filter_alarm_status_dirty,
            in_panel_filter_alarm_status_sp: data.in_panel_filter_alarm_status_sp,
            phx_on_temp_sensor: data.phx_on_temp_sensor,
            frost_coil_command: data.frost_coil_command,
            off_frost_temp_sensor: data.off_frost_temp_sensor,
            frost_state: data.frost_state,
            off_frost_temp_sp: data.off_frost_temp_sp,
            intake_damper_command: data.intake_damper_command,
            intake_damper_end_switch: data.intake_damper_end_switch,
            intake_air_temperature_sensor: data.intake_air_temperature_sensor,
          });
        });
    
        // this.setState({
        //   data: ahus,
        // });
      }

      render() {
        const { data, weeklyRuntimeData } = this.state;

        const timelineData = [
          {
            title: 'AHU Enabled',
            description: 'Start/Stop time schedule',
            date: '2023-07-24',
            startHour: 6, // 2 PM
            endHour: 18    // 11 PM
          },
          {
            title: 'AHU AI Sequence',
            description: 'Start/Stop time schedule',
            date: '2023-07-24',
            startHour: 21, // 2 PM
            endHour: 22    // 11 PM
          },
          // Add more events as needed
      ];

      const COLORS = ['#FF8042','#00C49F'];

      const pieData: PieChartData[] = [
        { name: 'Alarm', value: 3, color: '#FF8042'},
        { name: 'Healthy', value: 17, color: '#00C49F'}
      ];

      const renderCustomizedLabel = (props: CustomLabelProps) => {
        const { cx, cy, midAngle, innerRadius, outerRadius, index } = props;
        const radius = innerRadius + (outerRadius - innerRadius) * 2;
        const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
        const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
      
        return (
            <text 
            x={x} 
            y={y} 
            fill={pieData[index].color} // Set text color
            textAnchor={x > cx ? 'start' : 'end'} 
            dominantBaseline="central"
            style={
              { 
                fontSize: '18px', 
                // fontWeight: 'bold' 
              }} // Increase font size
          >
            {`${pieData[index].name}: ${pieData[index].value}`}
          </text>
        );
      };

        return (
            <>
        <Ahu_Type_1_DeviceHolder />
        <Container fluid>
          <Row> 
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-success border-success">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={19.34} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">°C</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Intake Temperature
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--success)",
                            fill: "none",
                          }}/>
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-dark border-dark">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={23.56} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">°C</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Exhaust Temperature
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData2}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--dark)",
                            fill: "none",
                          }}/>
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-primary border-primary">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={43.00} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">% </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      PHX Position
                      {/* plate heat exchanger position */}
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData2}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--primary)",
                            fill: "none",
                          }}/>
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-warning border-warning">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={67.19} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">% </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Supply Fan Speed
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--warning)",
                            fill: "none",
                          }}/>
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-primary border-primary">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={0.00} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">% </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Cooling Valve
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData4}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--primary)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={23.33} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">% </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Heating Valve
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData5}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--danger)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-info border-info">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={23.47} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">°C </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Return Temperature
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData6}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--info)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-secondary border-secondary">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={22.6} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">°C </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Supply Temperature
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData7}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--secondary)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Weekly Runtime
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                  <ResponsiveContainer width="100%" aspect={8.0 / 4.0}>
                    <BarChart
                      width={500}
                      height={300}
                      data={weeklyRuntimeData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="day" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="normal" stackId="a" fill="#8884d8" />
                      <Bar dataKey="overide" stackId="a" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Active Alarm
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                {/* <ResponsiveContainer width="100%" aspect={8.0 / 4.0}> */}
                  <PieChart width={400} height={240} onMouseEnter={this.onPieEnter}>
                  <Pie
                    data={pieData}
                    cx={'50%'}  // Center horizontally
                    cy={'50%'}  // Center vertically
                    innerRadius={55}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={renderCustomizedLabel}
                  >
                    {
                      pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))
                    }
                  </Pie>
                  {/* <Pie
                    data={pieData}
                    cx={'50%'}
                    cy={'50%'}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                  >
                    {
                      pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))
                    }
                  </Pie> */}
                  </PieChart>
                {/* </ResponsiveContainer> */}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Schedule
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                <TimeLine items={timelineData} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Air Handling Unit Pointset
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Settings</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                    <div className="-striped -highlight -fixed">
                  <ReactTable data={data}
                     columns={[
                      {
                        Header: "AHU",
                        columns: [
                          {
                            Header: "Name",
                            id: "ahuName",
                            accessor: (row: ExtendedIAhuData) => row.name.present_value,
                            width: 100,
                          },
                          {
                            Header: "Timestamp",
                            id: "ahuTimestamp",
                            accessor: (row: ExtendedIAhuData) => row.timestamp.present_value,
                            width: 200,
                          },
                          {
                            Header: "Alarm",
                            id: "ahuAlarm",
                            accessor: (row: ExtendedIAhuData) => row.alarm.present_value,
                            width: 100,
                          }
                        ]
                      },
                      {
                        Header: "Supply Air",
                        columns: [
                          {
                            Header: "Static Pressure Sensor",
                            id: "supplyAirStaticPressureSensor",
                            accessor: (row: ExtendedIAhuData) =>
                              row.supply_air_static_pressure_sensor.present_value,
                          },
                          {
                            Header: "Static Pressure Setpoint",
                            id: "supplyAirStaticPressureSetpoint",
                            accessor: (row: ExtendedIAhuData) =>
                              row.supply_air_static_pressure_sp.present_value,
                          },
                          {
                            Header: "Temperature Sensor",
                            id: "supplyAirTemperatureSensor",
                            accessor: (row: ExtendedIAhuData) =>
                              row.supply_air_temperature_sensor.present_value,
                            width: 120,
                          },
                          {
                            Header: "Temperature Setpoint",
                            id: "supplyAirTemperatureSetpoint",
                            accessor: (row: ExtendedIAhuData) =>
                              row.supply_air_temperature_sp.present_value,
                            width: 120,
                          },
                        ],
                      },
                      {
                        Header: "Filter",
                        columns: [
                          {
                            Header: "Differential Pressure Sensor Feedback",
                            id: "filterDifferentialPressureSensor",
                            accessor: (row: ExtendedIAhuData) =>
                              row.filter_differential_pressure_sensor.present_value,
                          },
                          {
                            Header: "Alarm Status Dirty",
                            id: "filterAlarmStatusDirty",
                            accessor: (row: ExtendedIAhuData) =>
                              row.filter_alarm_status_dirty.present_value,
                          },
                          {
                            Header: "Alarm Status Setpoint",
                            id: "filterAlarmStatusSetpoint",
                            accessor: (row: ExtendedIAhuData) =>
                              row.filter_alarm_status_sp.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Heating Valve",
                        columns: [
                          {
                            Header: "Sensor",
                            id: "heatingValveSensor",
                            accessor: (row: ExtendedIAhuData) =>
                              row.heating_valve_percentage_command.present_value,
                          },
                          {
                            Header: "Setpoint",
                            id: "heatingValveSetpoint",
                            accessor: (row: ExtendedIAhuData) =>
                              row.heating_valve_percentage_command.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Cooling Valve",
                        columns: [
                          {
                            Header: "Sensor",
                            id: "coolingValveSensor",
                            accessor: (row: ExtendedIAhuData) =>
                              row.cooling_valve_percentage_command.present_value,
                          },
                          {
                            Header: "Setpoint",
                            id: "coolingValveSetpoint",
                            accessor: (row: ExtendedIAhuData) =>
                              row.cooling_valve_percentage_command.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Supply Fan",
                        columns: [
                          {
                            Header: "Run Command",
                            id: "supplyFanRunCommand",
                            accessor: (row: ExtendedIAhuData) => row.supply_fan_run_command.present_value,
                          },
                          {
                            Header: "Run Status",
                            id: "supplyFanRunStatus",
                            accessor: (row: ExtendedIAhuData) => row.supply_fan_run_status.present_value,
                          },
                          {
                            Header: "Speed Command",
                            id: "supplyFanSpeedCommand",
                            accessor: (row: ExtendedIAhuData) => row.supply_fan_speed_percentage_command.present_value,
                          },
                        ],
                      },
                      {
                        Header: "PHX",
                        columns: [
                          {
                            Header: "Off Temperature Sensor",
                            id: "phxOffTemperatureSensor",
                            accessor: (row: ExtendedIAhuData) => row.phx_off_temp_sensor.present_value,
                            width: 120,
                          },
                          {
                            Header: "pos",
                            id: "phxPos",
                            accessor: (row: ExtendedIAhuData) => row.phx_pos.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Exhaust",
                        columns: [
                            {
                              Header: "Damper Command",
                              id: "exhaustDamperCommand",
                              accessor: (row: ExtendedIAhuData) => row.exhaust_damper_command.present_value,
                            },
                            {
                              Header: "Damper End Switch",
                              id: "exhaustDamperEndSwitch",
                              accessor: (row: ExtendedIAhuData) => row.exhaust_damper_end_switch.present_value,
                            },
                            {
                              Header: "Air Temperature Sensor",
                              id: "exhaustAirTemperatureSensor",
                              accessor: (row: ExtendedIAhuData) => row.exhaust_air_temperature_sensor.present_value,
                              width: 120,
                            },
                          ],
                      },
                    ]}
                    />
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>Air Handling Unit Timeseries</Card.Title>
                  <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
                    <LineChart data={chartData} width={500} height={300}>
                      <XAxis dataKey="timestamp" />
                      <YAxis label={{ value: "%", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 100]} interval={0} ticks={[0, 20, 40, 60, 80, 100]}/> */}
                      {/* <YAxis label={<AxisLabel align='left' axisType='yAxis' x={20} y={150} width={0} height={0}>Percent</AxisLabel>} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 100]} interval={0} ticks={[0, 20, 40, 60, 80, 100]}/> */}
                      {/* <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 40]} interval={0}/>
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line yAxisId="left" type="monotone" dataKey="heating_valve" stroke="#d0312d" activeDot={{ r: 8 }}/>
                      <Line yAxisId="left" type="monotone" dataKey="cooling_valve" stroke="#1338BE" />
                      <Line yAxisId="right" type="monotone" dataKey="supply_temperature" stroke="#fece78" activeDot={{ r: 8 }}/>
                      <Line yAxisId="right" type="monotone" dataKey="return_temperature" stroke="#82ca9d" />
                    </LineChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
          {/* <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>Firebase Timeseries</Card.Title>
                  <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
                    <LineChart data={graphObject} width={500} height={300}>
                      <XAxis dataKey="timestamp" />
                      <YAxis label={{ value: "%", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 100]} interval={0} ticks={[0, 20, 40, 60, 80, 100]}/>
                      <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 40]} interval={0}/>
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      <Line yAxisId="left" type="monotone" dataKey="heating_valve_percentage_command" stroke="#d0312d" activeDot={{ r: 8 }}/>
                      <Line yAxisId="left" type="monotone" dataKey="cooling_valve_percentage_command" stroke="#1338BE" />
                      <Line yAxisId="right" type="monotone" dataKey="supply_air_temperature_sensor" stroke="#fece78" activeDot={{ r: 8 }}/>
                      <Line yAxisId="right" type="monotone" dataKey="return_air_temperature_sensor" stroke="#82ca9d" />
                    </LineChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Container> 
            </>
    );
  }
}