import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState
  };

type Props = ConnectedProps<typeof connector> & OwnProps;

const METRICSDashboard: React.FC<Props> = () => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Utilisation Metrics"
                        subheading="Measuring and scoring information related to air quality, energy usage, water usage, and carbon intensity is crucial for environmental awareness and sustainable living. By quantifying air quality, individuals can understand the level of pollutants they are exposed to, prompting them to advocate for cleaner air practices or take personal protective measures. Monitoring energy usage helps in identifying areas where energy consumption can be reduced, leading to lower utility bills and reduced environmental impact. Water usage metrics are essential for water conservation efforts, as they highlight excessive consumption and encourage more efficient use of this vital resource. Understanding carbon intensity – the amount of carbon emitted per unit of energy consumed – aids in recognizing the carbon footprint of different activities and products, guiding people towards more eco-friendly choices. Overall, such measurements and scores empower individuals and communities to make informed decisions that contribute positively to environmental sustainability and personal health."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 />
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <METRICSDashboard {...props} />;
});