import { useEffect, useRef } from "react";
import update from "immutability-helper";
import { useSettingsContext } from "../providers/settings-provider";
import { StoreState } from "../../../../../../redux/types/store.type";
import { AssetState } from "../../../../../../redux/types/asset.type";
import { ConnectedProps, connect } from "react-redux";
import { useGraphicDeviceTypeMapping } from "../providers/graphic-device-type-mapping-provider";

interface OwnProps {
  displayDropdown: boolean;
  closeDropdown: Function;
}

type MapStateToPropsType = {
  Assets: AssetState | null;
};

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

function useOutsideHTMLElementClick(
  ref: React.RefObject<HTMLDivElement>,
  callback: Function
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // alert("You clicked outside of me!");
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const DeviceTypeDropdown: React.FC<Props> = ({
  displayDropdown,
  closeDropdown,
  Assets,
}: Props) => {
  const { graphicDeviceTypeMapping, setGraphicDeviceTypeMapping } =
    useGraphicDeviceTypeMapping();

  const ref = useRef<HTMLDivElement>(null);
  useOutsideHTMLElementClick(ref, closeDropdown);

  async function setMapping(deviceName: string, deviceType: string) {
    const graphicDeviceTypeMappingCopy = { ...graphicDeviceTypeMapping };
    if (
      graphicDeviceTypeMappingCopy &&
      graphicDeviceTypeMappingCopy[deviceName] &&
      graphicDeviceTypeMappingCopy[deviceName].includes(deviceType)
    ) {
      const filtered = graphicDeviceTypeMappingCopy[deviceName].filter(
        (type) => type !== deviceType
      );
      setGraphicDeviceTypeMapping({
        ...graphicDeviceTypeMappingCopy,
        [deviceName]: filtered,
      });
    } else {
      if (graphicDeviceTypeMappingCopy[deviceName]) {
        graphicDeviceTypeMappingCopy[deviceName].push(deviceType);
        setGraphicDeviceTypeMapping(graphicDeviceTypeMappingCopy);
      } else {
        setGraphicDeviceTypeMapping({
          ...graphicDeviceTypeMappingCopy,
          [deviceName]: [deviceType],
        });
      }
    }
  }

  const isRowCheked = (header: string, deviceType: string) => {
    return Boolean(
      graphicDeviceTypeMapping &&
        graphicDeviceTypeMapping[header] &&
        graphicDeviceTypeMapping[header].includes(deviceType)
    );
  };

  return displayDropdown ? (
    <div
      ref={ref}
      className=" z-30 w-60 bg-white rounded shadow absolute bottom-20 left-0"
    >
      <ul
        className="p-3 space-y-1 text-sm text-gray-700"
        aria-labelledby="dropdownHelperButton"
      >
        {Assets &&
          Assets.Types &&
          Object.keys(Assets.Types).map((header) => (
            <div key={header}>
              <HeaderRow headerName={header} />
              {Object.keys((Assets.Types as any)[header]).map((deviceType) => (
                <Row
                  key={deviceType}
                  deviceTypeName={deviceType}
                  checked={isRowCheked(header, deviceType)}
                  onClick={() => setMapping(header, deviceType)}
                />
              ))}
            </div>
          ))}
      </ul>
    </div>
  ) : null;
};

interface HeaderProps {
  headerName: string;
}

interface RowProps {
  deviceTypeName: string;
  checked: boolean;
  onClick: () => void;
}

const HeaderRow = ({ headerName }: HeaderProps) => {
  return (
    <li>
      <div className="flex items-center">
        <div className="ml-2 text-sm">
          <label
            htmlFor="helper-checkbox-1"
            className="flex items-center font-semibold text-gray-900 m-0"
          >
            <div>{headerName}</div>
          </label>
        </div>
      </div>
    </li>
  );
};

const Row = ({ deviceTypeName, checked, onClick }: RowProps) => {
  return (
    <li>
      <div className="flex items-center p-1 rounded hover:bg-gray-100">
        <div className="flex items-center h-5">
          <input
            id="helper-checkbox-1"
            aria-describedby="helper-checkbox-text-1"
            type="checkbox"
            value="snapToGrid"
            checked={checked} // update here
            onChange={onClick} // update here
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
          />
        </div>
        <div className="ml-2 text-xs">
          <label
            htmlFor="helper-checkbox-1"
            className="flex items-center font-medium text-gray-900 m-0"
          >
            <div>{deviceTypeName}</div>
          </label>
        </div>
      </div>
    </li>
  );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
  return {
    Assets: state.Assets,
  };
};

const connector = connect(mapStateToProps);

export default connector((props: Props) => {
  return <DeviceTypeDropdown {...props} />;
});
