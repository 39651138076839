import * as actionTypes from "../types/building.action.type";
import { 
    BuildingState, 
    BuildingAction, 
    AddBuildingAction,
    AddBuildingsAction,
    RemoveBuildingAction
   } from '../types/building.type';
import { ThunkAction } from 'redux-thunk';

import firebase, { functions}  from "../../firebase";
// import firebase from "../../firebase";

export function fetchBuildings(): ThunkAction<void, BuildingState, unknown, BuildingAction> {
    return (dispatch) => {
        //Get the buildings collection
        let buildingsCollection = "Buildings"
        firebase.collection(buildingsCollection).get()
        //Snapshot is the data returned from the collection get call.
        .then((snapshot) => {
            const buildings: string[] = [];
            //Iterate over the snapshot and add the document id (building_name) to the buildings name array called buildings
            snapshot.forEach(doc => {
                buildings.push(doc.id);
                // documents.push({ id: doc.id, ...doc.data() })
            });
            //Instantiate the CLOUD FUNCTION listSubCollections so that we can use it.
            const listSubCollections = functions.httpsCallable("listSubCollections");

            //Iterate over the buildings name array called buildings.
            buildings.forEach(building => {
                let tempLevels: string[] = [];
                //Use the CLOUD FUNCTION listSubCollections by passing the subcollection address, in this instance it would be "Buildings/UK-LONDON-WENLOCK-22" and "Buildings/UK-LONDON-MARSHGATE-STADIUM"
                listSubCollections({ docPath: buildingsCollection + "/" + building })
                .then(result => {
                    //listSubCollections has now returned the levels for the building requested
                    (result.data.subCollections as string[]).forEach(level => tempLevels.push(level));  // add each level to the tempLevels array
                    tempLevels.forEach(level => {
                        //Now we can collect the devices from the buildings level registries device.
                        firebase.collection(buildingsCollection + "/" + building  + "/" + level + "/registries/device").get()
                        .then((snapshot) => {
                            //Snapshot is the date returned from the collection get call.
                            const devices: string[] = [];
                            snapshot.forEach(doc => {
                                devices.push(doc.id); // add each device to the devices array
                            })
                            console.log(building, level, devices);

                            dispatch(fetchBuildingsSuccess(building, level, devices));
                        })
                        .catch((error) => {
                            console.error(error.message);
                        })
                    })
                })
                .catch(error => {
                    console.error("Error listing sub-collections:", error);
                });
            })
            
            //method to document fields
            // snapshot.docs.map(field => Buildings.push(field.data()))
        })
        .catch((error) => {
            console.error(error.message);
        })
    };
}

export function fetchBuildingsSuccess(building: string, level: string, devices: string[]): AddBuildingAction {
    return {
        type: actionTypes.ADD_BUILDING,
        building,
        level,
        devices
    };
}
