import React from 'react';
import TimelineItem from './timeLineItem';
import './timeline.css';

interface TimelineProps {
  items: Array<{
    title: string;
    description: string;
    date: string;
    startHour: number;
    endHour: number;
  }>;
}

const Timeline: React.FC<TimelineProps> = ({ items }) => {
    // Define the times to display on the timeline
  const times = ['3 AM', '9 AM', '3 PM', '9 PM', '3 AM'];
  return (
    <div className="timeline-container">
      <div className="chart-background">
        {times.map((time, index) => (
          <div key={index} className="time-slot">
            <span>{time}</span>
          </div>
        ))}
      </div>
      {items.map((item, index) => (
        <TimelineItem key={index} data={item} />
      ))}
    </div>
  );
};

export default Timeline;
