import { ChangeEvent } from "react";

interface Props {
  title: string;
  updateFontSize: (num: number) => void;
  onFontSizeChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fontSize: number;
}

const SizeInput = ({
  updateFontSize,
  onFontSizeChange,
  fontSize,
  title,
}: Props) => {
  return (
    <div className="flex flex-col">
      <label
        htmlFor="custom-input-number"
        className="w-full mb-2 text-xs font-medium text-gray-500"
      >
        {title}
      </label>
      <div className="flex flex-row h-8 w-full rounded-lg relative bg-transparent">
        <button
          className=" bg-slate-200 text-gray-600 hover:text-gray-700 hover:bg-slate-300 h-full w-10 rounded-l cursor-pointer outline-none"
          onClick={() => updateFontSize(-1)}
        >
          <span className="m-auto text-lg font-thin">−</span>
        </button>
        <input
          type="number"
          min="1"
          max="72"
          className="focus:outline-none text-center w-10 bg-slate-200 font-normal text-sm text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none appearance-none"
          name="custom-input-number"
          value={fontSize}
          onChange={onFontSizeChange}
        ></input>
        <button
          className="bg-slate-200 text-gray-600 hover:text-gray-700 hover:bg-slate-300 h-full w-10 rounded-r cursor-pointer"
          onClick={() => updateFontSize(1)}
        >
          <span className="m-auto text-lg font-thin">+</span>
        </button>
      </div>
    </div>
  );
};

export default SizeInput;
