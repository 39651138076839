import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';
import { BuildingState } from '../../../redux/types/building.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../../components/redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
    Buildings: BuildingState;
};

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

const LIGHTAREACTRLDashboard: React.FC<Props> = ({Buildings}) => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Lighting Area Controller"
                        subheading="A lighting area controller is a crucial component in advanced lighting systems, particularly in large-scale or commercial environments. It is designed to manage the lighting of specific zones or areas within a building, providing centralized control over multiple lighting fixtures and their operations."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 buildings={Buildings}/>
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

// // const mapStateToProps = (state: StoreState.All): ArticleState => {
// //     return {
// //       state: state
// //     };
// //   };

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
        Buildings: state.Buildings
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <LIGHTAREACTRLDashboard {...props} />;
});