// App.tsx
import React, { Component } from "react";
import axios from "axios";
import { RegionalData, RegionalDataResponse, DataPoint } from "../../../../../components/firebase/types/carbon.type";

type Props = {
    handleCarbonData: Function;
    postcode:string;
}

type State = {
  fetchedOnce: boolean;
  regionalData:RegionalData;
}

export default class CarbonIntensity extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleCarbonData = this.handleCarbonData.bind(this);

    this.state = {
      fetchedOnce:false,
      regionalData:{
        "regionid": 13,
        "dnoregion": "UKPN London",
        "shortname": "London",
        "postcode": "DA16",
        "data": [
            {
                "from": "2024-12-01T20:00Z",
                "to": "2024-12-01T20:30Z",
                "intensity": {
                    "forecast": 125,
                    "index": "moderate"
                },
                "generationmix": [
                    {
                        "fuel": "biomass",
                        "perc": 0
                    },
                    {
                        "fuel": "coal",
                        "perc": 0
                    },
                    {
                        "fuel": "imports",
                        "perc": 42.5
                    },
                    {
                        "fuel": "gas",
                        "perc": 16.8
                    },
                    {
                        "fuel": "nuclear",
                        "perc": 0
                    },
                    {
                        "fuel": "other",
                        "perc": 0
                    },
                    {
                        "fuel": "hydro",
                        "perc": 1.6
                    },
                    {
                        "fuel": "solar",
                        "perc": 0
                    },
                    {
                        "fuel": "wind",
                        "perc": 39.1
                    }
                ]
            }
        ]
      }
    };
  }

  componentDidMount = async () => {
    this.fetchDataApi(); 
  };

  getCurrentTimeInISOFormat() {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(now.getUTCDate()).padStart(2, "0");
    const hours = String(now.getUTCHours()).padStart(2, "0");
    const minutes = String(now.getUTCMinutes()).padStart(2, "0");
  
    return `${year}-${month}-${day}T${hours}:${minutes}Z`;
  }

  handleCarbonData() {
    const { fetchedOnce, regionalData } = this.state;
    console.log("carbonIntensity handleCarbonData:" + JSON.stringify(regionalData));
    this.props.handleCarbonData(regionalData)
    .then(() => {
        if(fetchedOnce) {
            //do next action() or not
        }
    });
  };

  fetchDataApi = async () => {
    console.log("carbonIntensity fetchDataApi");
    try {
      const response = await axios.get<RegionalDataResponse>(
        "https://api.carbonintensity.org.uk/regional/intensity/" +
          this.getCurrentTimeInISOFormat() +
          "/pt24h/postcode/" +
          this.props.postcode
      );
  
      // Update state and call handleCarbonData after state update
      this.setState({ regionalData: response.data.data }, () => {
        this.handleCarbonData();
      });
    } catch (error: any) {
      console.log("Error getting carbonIntensity documents: ", error);
    }
  };

  render() {
    return(
        <div></div>
    );
  }
};
