import { SvgProps } from "../types/svg";
import Svg from "./svg";

type SvgPropsWithoutD = Omit<SvgProps, "d">;
interface IconProps extends SvgPropsWithoutD {
  icon: "zoomIn" | "zoomOut" | "setting" | "deviceType";
}

const icons = {
  zoomIn:
    "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7",
  zoomOut: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7",
  setting:
    "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4",
  deviceType:
    "M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M11,17H7v-4h4V17z M11,11H7V7h4V11 z M17,17h-4v-4h4V17z M17,11h-4V7h4V11z",
};

const Icon = (props: IconProps) => {
  return <Svg {...props} d={icons[props.icon]} />;
};

export default Icon;
