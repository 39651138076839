import firebase from "..";
import {IuserDetails} from "../types/user.type"

const db = firebase.collection("users/");

class UserDataService {
    async getBrandingByUserId(userId: string): Promise<any> {
        try {
            const userDoc = await db.doc(userId).get();
            if (userDoc.exists) {
                // Assuming 'branding' is a field within the user document
                const branding = userDoc.data()?.branding;
                return branding;
            } else {
                console.log("No such user branding document!");
                return "thanium";
            }
        } catch (error) {
            console.error("Error getting document:", error);
            throw error; // Or handle the error as needed
        }
    }
    async getDetailsByUserId(userId: string): Promise<IuserDetails> {
        try {
            const userDoc = await db.doc(userId).get();
            if (userDoc.exists) {
                // Extracting 'username' and 'job_role' from the user document
                const userData = userDoc.data();
                const userDetails = {
                    username: userData?.username,
                    job_role: userData?.job_role
                };
                return userDetails;
            } else {
                console.log("No such user username & job_role document!");
                return {
                    username: "",
                    job_role: ""
                };
            }
        } catch (error) {
            console.error("Error getting document:", error);
            throw error; // Or handle the error as needed
        }
    }
}

export default new UserDataService();