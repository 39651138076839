import {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import update from "immutability-helper";
import { useDrop, XYCoord } from "react-dnd";
import { snapToGrid as doSnapToGrid } from "../utilities/snap-to-grid";
import { v4 as uuidv4 } from "uuid";
import { calculateNewComponentPositionOnDrop } from "./utils";
import {
  Label,
  LabelTextfield,
  TextField,
} from "../../components/draggable-components";
import {
  ComponentTypes,
  DraggableComponentProps,
} from "../../types/draggable-component";
import { useSelectorContext } from "../providers/selector-provider";
import { useGraphicComponentsContext } from "../providers/graphic-components-provider";
import { useSettingsContext } from "../providers/settings-provider";
import { useGraphicHook } from "./custom-hooks/image-hook";
import BottomBar from "./bottom-bar";

interface Props {
  snapToGrid?: boolean;
  graphic: File;
}

const GraphicContainer = ({ graphic }: Props) => {
  const outterContainerRef = useRef<HTMLDivElement>(null);
  const dropContainerRef = useRef<HTMLDivElement>(null);
  const [centerGraphic, setCenterGraphic] = useState<boolean>(false);
  const { setSelectedComponent } = useSelectorContext();
  const { components, setComponents } = useGraphicComponentsContext();
  const { settings, setSettings } = useSettingsContext();

  const { graphicRef, onGraphicLoad, graphicWidth, graphicHeight } =
    useGraphicHook(graphic);

  useEffect(() => {
    if (graphicWidth && outterContainerRef && outterContainerRef.current) {
      if (graphicWidth < outterContainerRef.current.clientWidth) {
        setCenterGraphic(true);
      } else {
        setCenterGraphic(false);
      }
    }
  }, [graphicWidth, outterContainerRef]);

  const moveComponent = useCallback(
    (id: string, left: number, top: number) => {
      setComponents(
        update(components, {
          [id]: {
            $merge: { left, top },
          },
        })
      );
    },
    [components]
  );

  const createComponentOnDrop = useCallback(
    (component: DraggableComponentProps) => {
      setComponents(
        update(components, {
          [component.id]: {
            $set: component,
          },
        })
      );
    },
    [components]
  );

  const [, drop] = useDrop(
    () => ({
      accept: [
        ComponentTypes.LABEL,
        ComponentTypes.TEXTFIELD,
        ComponentTypes.VERTICAL_LABEL_TEXTFIELD,
        ComponentTypes.HORIZONTAL_LABEL_TEXTFIELD,
      ],
      drop(item: DraggableComponentProps, monitor) {
        if (item.id === "abc") {
          let newItemPosition: XYCoord = calculateNewComponentPositionOnDrop(
            monitor,
            dropContainerRef
          );
          if (settings.snapToGrid) {
            const { x, y } = doSnapToGrid(newItemPosition.x, newItemPosition.y);
            newItemPosition.x = x;
            newItemPosition.y = y;
          }

          const component: DraggableComponentProps = {
            ...item,
            id: uuidv4(),
            left: newItemPosition.x,
            top: newItemPosition.y,
          };
          createComponentOnDrop(component);
          return undefined;
        }
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (settings.snapToGrid) {
          const { x, y } = doSnapToGrid(left, top);
          left = x;
          top = y;
        }
        moveComponent(item.id, left, top);
        return undefined;
      },
    }),
    [moveComponent, createComponentOnDrop]
  );

  function renderComponents(): ReactNode {
    if (Object.keys(components).length === 0) return;
    return Object.keys(components).map((key) => {
      let component: DraggableComponentProps = components[key];
      switch (component.type) {
        case ComponentTypes.VERTICAL_LABEL_TEXTFIELD:
        case ComponentTypes.HORIZONTAL_LABEL_TEXTFIELD:
          return (
            <LabelTextfield
              key={key}
              isSelectable={true}
              {...(components[key] as DraggableComponentProps)}
            />
          );
        case ComponentTypes.LABEL:
          return (
            <Label
              key={key}
              isSelectable={true}
              {...(components[key] as DraggableComponentProps)}
            />
          );
        case ComponentTypes.TEXTFIELD:
          return (
            <TextField
              key={key}
              isSelectable={true}
              {...(components[key] as DraggableComponentProps)}
            />
          );

        default:
          return null;
      }
    });
  }

  const handleContainerClick = (event: MouseEvent) => {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setSelectedComponent(null);
    }
  };

  return (
    <div className="relative w-[calc(100%-20rem)] h-full grid place-items-center">
      <div
        ref={outterContainerRef}
        className={`relative w-full h-full bg-white float-left ${
          centerGraphic && "grid place-items-center"
        } overflow-scroll`}
      >
        <img
          ref={graphicRef}
          alt="graphic"
          src={URL.createObjectURL(graphic)}
          onLoad={onGraphicLoad}
          className="hidden object-center"
          style={{ width: graphicWidth, height: graphicHeight }}
        />
        <img
          width={graphicWidth}
          height={graphicHeight}
          src={URL.createObjectURL(graphic)}
          className="absolute bg-no-repeat bg-cover"
        />
        <div ref={dropContainerRef} className="absolute">
          <div
            ref={drop}
            style={{
              width: graphicWidth,
              height: graphicHeight,
            }}
            onClick={(e) => handleContainerClick(e)}
          >
            {renderComponents()}
          </div>
        </div>
      </div>
      <BottomBar onZoom={() => null} />
    </div>
  );
};

export default GraphicContainer;
