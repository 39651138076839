import LabelComponent from "./label";
import Textfield from "./textfield";
import {
  DraggableComponentProps,
  LabelComponentProps,
  TextFieldComponentProps,
} from "../../types/draggable-component";
import { DraggableContainer } from "./draggable-container";

interface ExtendedDraggableComponentProps extends DraggableComponentProps {
  defaultDragPreview?: boolean;
  isSelectable?: boolean;
}

export const LabelTextfield = (props: ExtendedDraggableComponentProps) => {
  return (
    <DraggableContainer {...props}>
      {label(props.label)}
      {textfield(props.textfield)}
    </DraggableContainer>
  );
};

export const Label = (props: ExtendedDraggableComponentProps) => {
  return (
    <DraggableContainer {...props}>{label(props.label)}</DraggableContainer>
  );
};

export const TextField = (props: ExtendedDraggableComponentProps) => {
  return (
    <DraggableContainer {...props}>
      {textfield(props.textfield)}
    </DraggableContainer>
  );
};

function label(props?: LabelComponentProps) {
  return <LabelComponent {...props} />;
}

function textfield(props?: TextFieldComponentProps) {
  return <Textfield {...props} />;
}
