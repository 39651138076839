import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../../components/redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState
  };

type Props = ConnectedProps<typeof connector> & OwnProps;

const AHUDashboard: React.FC<Props> = () => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Air Handling Unit"
                        subheading="Air Handling Unit (AHU) is an integral component designed to regulate and circulate air within a facility. The AHU is responsible for taking in external air, conditioning it (by either cooling or heating), and then distributing it throughout the building. Equipped with various sensors and actuators, it constantly communicates with the BMS to ensure optimal indoor air quality, temperature, and humidity levels. Its primary function is to maintain a comfortable and healthy environment for occupants, while also ensuring energy efficiency in alignment with the building's HVAC strategy."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 />
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

// // const mapStateToProps = (state: StoreState.All): ArticleState => {
// //     return {
// //       state: state
// //     };
// //   };

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <AHUDashboard {...props} />;
});