import {
  Dispatch,
  ReactEventHandler,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";

interface CustomImageHook {
  graphicRef: RefObject<HTMLImageElement>;
  graphicWidth: number | undefined;
  graphicHeight: number | undefined;
  originalGraphicWidth: number | undefined;
  originalGraphicHeight: number | undefined;
  graphicLoaded: boolean;
  onGraphicLoad: ReactEventHandler<HTMLImageElement>;
  setGraphicWidth: Dispatch<React.SetStateAction<number | undefined>>;
  setGraphicHeight: Dispatch<React.SetStateAction<number | undefined>>;
}

interface GraphicMetadata {
  name: string;
  size: number;
  width: number | undefined;
  height: number | undefined;
}

export const useGraphicHook = (graphic: File): CustomImageHook => {
  const graphicRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [originalGraphicWidth, setOriginalGraphicWidth] = useState<number>();
  const [originalGraphicHeight, setOriginalGraphicHeight] = useState<number>();
  const [graphicWidth, setGraphicWidth] = useState<number>();
  const [graphicHeight, setGraphicHeight] = useState<number>();
  const [graphicMetadata, setGraphicMetadata] =
    useState<GraphicMetadata | null>(null);

  const onLoad = () => {
    if (!loaded) {
      setLoaded(true);
      setGraphicWidth(graphicRef.current?.width);
      setGraphicHeight(graphicRef.current?.height);
      setOriginalGraphicWidth(graphicRef.current?.width);
      setOriginalGraphicHeight(graphicRef.current?.height);
      setGraphicMetadata({
        name: graphic.name,
        size: graphic.size,
        width: graphicWidth,
        height: graphicHeight,
      });
    }
  };

  useEffect(() => {
    if (graphicRef.current && graphicRef.current.complete) {
      onLoad();
    }
  }, [graphicRef, graphicWidth]);

  return {
    graphicRef,
    graphicLoaded: loaded,
    onGraphicLoad: onLoad,
    graphicWidth,
    graphicHeight,
    originalGraphicWidth,
    originalGraphicHeight,
    setGraphicWidth,
    setGraphicHeight,
  };
};
