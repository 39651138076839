import { combineReducers } from "@reduxjs/toolkit";

import ThemeOptions from "./ThemeOptions";
import Article from "./Article";
import Building from "./Building";
import Asset from "./Asset";

import { StoreState } from "../types/store.type";

const allReducers = combineReducers<StoreState.All>({
  ThemeOptions: ThemeOptions,
  Articles: Article,
  Buildings: Building,
  Assets: Asset,
});

export default allReducers;
