import React, { FC } from 'react';

interface ImageBoxProps {
  top?: number;
  left?: number;
  imageOn?: string;
  imageOff?: string;
  color?: string;
  backgroundColor?: string;
  value?: string;
}

export const ImageBox: FC<ImageBoxProps> = ({
  top,
  left,
  imageOn,
  imageOff,
  color = 'red',
  backgroundColor = 'blue',
  value = 'false',
}) => {
  const parsedValue = value === 'true';

  return parsedValue ? (
    <div
      className="image-box-wrapper"
      style={{
        top: top,
        left: left,
        backgroundImage: `url(${imageOn})`,
        // color: color,
        // backgroundColor: backgroundColor,
      }}
    ></div>
  ) : (
    <div
      className="image-box-wrapper"
      style={{
        top: top,
        left: left,
        backgroundImage: `url(${imageOff})`,
        // color: color,
        // backgroundColor: backgroundColor,
      }}
    ></div>
  );
};
