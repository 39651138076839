import React from 'react';
import './chl_type_1.css';
import meter_graphic from '../images/fcu_cooling.png';
import supply_on from '../images/supply_on.gif';
import { GraphicComponents } from '../GraphicComponents';

import {ExIChlData, ExtendedIChlData} from '../../../../firebase/types/chl.type';

type Props = {
  data?: ExIChlData;
};

const METER_TYPE_1: React.FC<Props> = ({data}) => {
  try {
    const isEmpty = (obj: ExIChlData): boolean => {
      return Object.keys(obj).length === 0;
    }

    if (data === undefined || data === null || isEmpty(data)
    ) {
      return <h5>Loading...</h5>;
    }
    //return
    const leaving_water_temp = RoundNumber(data.leaving_water_temp.present_value) + ' °C';
    
    //supply
    const entering_water_temp = RoundNumber(data.entering_water_temp.present_value) + ' °C';
    
    const percentage_total_capacity = RoundNumber(data.percentage_total_capacity.present_value) +' %';
    const chiller_enbl = data.chiller_enabled.present_value;
    const chiller_sts = data.status.present_value;

    return (
        <>
        <div
          className='titleName'
        >
          
        <h1>METER TYPE 1</h1>
        </div>
        {/* <TransformWrapper>
        <TransformComponent> */}
        <section
          style={{
            backgroundImage: `url(${meter_graphic})`
            
          }}
          className='chl_type_1_wrapper'
        >
          <GraphicComponents.ImageBox
          top={115}
          left={420}
          value={chiller_enbl}
          imageOn={supply_on}
          />

          <GraphicComponents.VLabelBox
            top={220}
            left={310}
            label='Return Temperature'
            value={leaving_water_temp}
          />

     
          <GraphicComponents.VLabelBox
            top={10}
            left={370}
            label='Status'
            value={chiller_sts}
          />
        </section>
        {/* </TransformComponent>
        </TransformWrapper> */}
        </>
    );
  } catch (exception) {
    console.log(exception);
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p>Device Graphic not available.</p>
      </div>
    );
  }
};

function RoundNumber(number: number) {
  return Math.round(number * 100) / 100;
}

export default METER_TYPE_1;
