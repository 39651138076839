import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  PieChart, 
  Pie,
  Cell,
  Sector,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import './local.css';
import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import { IExtendedResourceData, IResourceData } from "../../../../firebase/types/resources.type";

import { IntensitySection } from "../../../components/intensitySection";
import { ScoreSection } from "../../../components/scoreSection";
import { readlink } from "fs";

  const makeNewData: IExtendedResourceData =
      {
        meta: {
          name: { present_value: "Metrics" },
          timestamp: { present_value: "2023-03-28T09:30:00Z" },
          alarm: { present_value: "Healthy" },
        },
        metrics:[
          {
            "name": "Simmtronic",
            "address": {
                "street": "3 Charlton Mead Lane",
                "city": "Hoddesdon",
                "postcode": "SW1A 1AA",
                "country": "UK"
            },
            "carbon_intensity": {
                "score_percent": {"present_value": 20},
                "grams_carbon_dioxide_per_kWh": {"present_value": 120}
            },
            "energy_intensity": {
                "score_percent": {"present_value": 60},
                "kWh": {"present_value": 14}
            },
            "water_intensity": {
                "score_percent": {"present_value": 0},
                "litres": {"present_value": 0}
            },
            "air_quality": {
                "score_percent": {"present_value": 40},
                "relative_humidity": {"present_value": 60},
                "carbon_dioxide": {"present_value": 822}
            },
            "size_sq_meter": {"present_value": 120}
        },
        
    //     {
    //       "name": "Eco Nest",
    //       "address": {
    //           "street": "47 Birch Lane",
    //           "city": "Edinburgh",
    //           "postcode": "EH1 3DG",
    //           "country": "UK"
    //       },
    //       "carbon_intensity": {
    //           "score_percent": {"present_value": 60},
    //           "grams_carbon_dioxide_per_kWh": {"present_value": 100}
    //       },
    //       "energy_intensity": {
    //           "score_percent": {"present_value": 50},
    //           "kWh": {"present_value": 9}
    //       },
    //       "water_intensity": {
    //           "score_percent": {"present_value": 50},
    //           "litres": {"present_value": 337}
    //       },
    //       "air_quality": {
    //           "score_percent": {"present_value": 55},
    //           "relative_humidity": {"present_value": 55},
    //           "carbon_dioxide": {"present_value": 400}
    //       },
    //       "size_sq_meter": {"present_value": 100}
    //   },
      
    //   {
    //     "name": "Urban Oasis",
    //     "address": {
    //         "street": "88 Maple Road",
    //         "city": "Manchester",
    //         "postcode": "M1 2AN",
    //         "country": "UK"
    //     },
    //     "carbon_intensity": {
    //         "score_percent": {"present_value": 55},
    //         "grams_carbon_dioxide_per_kWh": {"present_value": 260}
    //     },
    //     "energy_intensity": {
    //         "score_percent": {"present_value": 40},
    //         "kWh": {"present_value": 17}
    //     },
    //     "water_intensity": {
    //         "score_percent": {"present_value": 48},
    //         "litres": {"present_value": 677}
    //     },
    //     "air_quality": {
    //         "score_percent": {"present_value": 43},
    //         "relative_humidity": {"present_value": 58},
    //         "carbon_dioxide": {"present_value": 942}
    //     },
    //     "size_sq_meter": {"present_value": 110}
    // },
    
    // {
    //   "name": "The Haven",
    //   "address": {
    //       "street": "33 King Drive",
    //       "city": "London",
    //       "postcode": "N1 9GU",
    //       "country": "UK"
    //   },
    //   "carbon_intensity": {
    //     "score_percent": {"present_value": 65},
    //     "grams_carbon_dioxide_per_kWh": {"present_value": 190}
    //     },
    //     "energy_intensity": {
    //     "score_percent": {"present_value": 55},
    //     "kWh": {"present_value": 9}
    //     },
    //     "water_intensity": {
    //     "score_percent": {"present_value": 60},
    //     "litres": {"present_value": 240}
    //     },
    //     "air_quality": {
    //     "score_percent": {"present_value": 58},
    //     "relative_humidity": {"present_value": 53},
    //     "carbon_dioxide": {"present_value": 295}
    //     },
    //     "size_sq_meter": {"present_value": 130}
    //     }
      ]
    }
  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    container: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: 'red',
      justifyContent: 'center',
      alignItems: 'center',
    },

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
    
  `
  interface ActiveShapeProps {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    fill: string;
    payload: {
      name: string;
      // add other payload properties here if needed
    };
    percent: number;
    value: number;
  }



  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);
  
  type Props = {
    placeholder?: string | null
  };
  
  type State = {
      data: IExtendedResourceData;
      dropdownOpen: false;
      selectedOption: null;
      loading: false;
      device: [];
      activeIndex:number;
  };

  interface NeedleDataItem {
    name: string;
    value: number;
    color: string;
  }
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {

    constructor(props: Props) {
      super(props);

      this.state = {
        data: makeNewData,
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        activeIndex:0
      };


    }
  
    componentDidMount() {

    }
  
    componentWillUnmount() {

    }

    onPieEnter = (_: any, index: number) => {
      this.setState({
        activeIndex: index,
      });
    };

    calculateScorePercent(input:number, inputMin:number, inputMax:number){
      const scoreMax = 100;
      return (input - inputMin) * scoreMax / (inputMax - inputMin);
    }

    calculateInverseScorePercent(input:number, inputMin:number, inputMax:number){
      //scaling normal per 100 sq meter, current sq meter
      let score;
      const scoreMax = 100;
      const scoreMin = 0;
      if (input <= inputMin) {
        score = scoreMax;
      } else if (input >= inputMax) {
        score = scoreMin;
      } else {
        score = scoreMax - ((input - inputMin) / (inputMax - inputMin)) * (scoreMax - scoreMin);
      }
      return score;
    }

    calculateAirQualityScore(relative_humidity:number, carbon_dioxide:number){
      let carbon_dioxide_score = 50;
      if(carbon_dioxide < 600){
        carbon_dioxide_score = 50;
      } else if(carbon_dioxide >= 600 && carbon_dioxide < 1100){
        carbon_dioxide_score -= ((carbon_dioxide - 500) * 0.05);
      } else if(carbon_dioxide >= 1000 && carbon_dioxide < 2000){
        carbon_dioxide_score -= ((carbon_dioxide - 1000) * 0.025);
      } else {
        carbon_dioxide_score = 0
      }
      let relative_humidity_score = 50;
      if(relative_humidity < 20){
        relative_humidity_score = 0;
      } else if(relative_humidity >= 20 && relative_humidity < 45){
        relative_humidity_score -= (this.calculateInverseScorePercent(relative_humidity, 20, 45)/2);
      } else if(relative_humidity >=45 && relative_humidity < 60){
        relative_humidity_score = 50;
      } else if(relative_humidity >= 60 && relative_humidity < 80){
        relative_humidity_score -= (this.calculateScorePercent(relative_humidity, 60, 80)/2);
      } else if(relative_humidity >= 80){
        relative_humidity_score = 0;
      }
      return Math.round(carbon_dioxide_score + relative_humidity_score);
    }


    calculateCarbonScore(value:number){
      return this.calculateScore(value, 200, 150);
    }

    calculateScore(value:number, normal_per_100_sq_meter:number, size_sq_meter:number){
    	let normalized = (normal_per_100_sq_meter / 100) * size_sq_meter;
      let score = 50;
        if(value <= normalized){
        	score += (this.calculateInverseScorePercent(value, 0, normalized)/2);
        } else {
        	score -= (this.calculateScorePercent(value - normalized, 0, normalized)/2);
        }
        if(score < 0){
          score = 0;
        }
        return Math.round(score);
    }

      render() {
        const { data } = this.state;

        const RADIAN = Math.PI / 180;
        const needle = (value:number, data:NeedleDataItem[], cx:number, cy:number, iR:number, oR:number, color:string) => {
          let total = 0;
          data.forEach((v) => {
            total += v.value;
          });
          const ang = 180.0 * (1 - value / total);
          const length = (iR + 2 * oR) / 3;
          const sin = Math.sin(-RADIAN * ang);
          const cos = Math.cos(-RADIAN * ang);
          const r = 5;
          const x0 = cx + 5;
          const y0 = cy + 5;
          const xba = x0 + r * sin;
          const yba = y0 - r * cos;
          const xbb = x0 - r * sin;
          const ybb = y0 + r * cos;
          const xp = x0 + length * cos;
          const yp = y0 + length * sin;
        
          return [
            <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
            <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color} />,
          ];
        };

        const renderActiveShape = (props: ActiveShapeProps) => {
          const RADIAN = Math.PI / 180;
          const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
          const sin = Math.sin(-RADIAN * midAngle);
          const cos = Math.cos(-RADIAN * midAngle);
          const sx = cx + (outerRadius + 10) * cos;
          const sy = cy + (outerRadius + 10) * sin;
          const mx = cx + (outerRadius + 30) * cos;
          const my = cy + (outerRadius + 30) * sin;
          const ex = mx + (cos >= 0 ? 1 : -1) * 22;
          const ey = my;
          const textAnchor = cos >= 0 ? 'start' : 'end';
        
          return (
            <g>
              <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
              </text>
              <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
              />
              <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
              />
              <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
              <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value} %`}</text>
              <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {/* {`(${(percent * 100).toFixed(2)}%)`} */}
              </text>
            </g>
          );
        };


        const renderMenuItems = (metrics: IResourceData[]) => {
          return metrics.map((item: IResourceData) => {
            
            

            const COLORS = ['#00C49F', '#808080', '#0088FE', '#808080', '#FFBB28', '#808080', '#FF8042', '#808080'];

            let air_quality_score = this.calculateAirQualityScore(item.air_quality.relative_humidity.present_value, item.air_quality.carbon_dioxide.present_value);
            let water_intensity_score = this.calculateScore(item.water_intensity.litres.present_value, 450, item.size_sq_meter.present_value);
            let energy_intensity_score = this.calculateScore(item.energy_intensity.kWh.present_value, 25, item.size_sq_meter.present_value);
            let carbon_intensity_score = this.calculateCarbonScore(item.carbon_intensity.grams_carbon_dioxide_per_kWh.present_value);

            const needleCx = 200;
            const needleCy = 125;
            const needleIr = 80;
            const needleOr = 110;
            
            const needleData = [
              { name: 'Poor', value: 25, color: '#dc3545' },
              { name: 'Warning', value: 25, color: '#ffc107' },
              { name: 'Good', value: 25, color: '#6c757d' },
              { name: 'Excellent', value: 25, color: '#28a745' },
            ];

            const pieChartData = [
              { name: 'Air Score', value: air_quality_score },
              { name: 'Air Opportunity', value: (100 - air_quality_score) },
              { name: 'Water Score', value: water_intensity_score },
              { name: 'Water Opportunity', value: (100 - water_intensity_score) },
              { name: 'Energy Score', value: energy_intensity_score },
              { name: 'Energy Opportunity', value: (100 - energy_intensity_score) },
              { name: 'Carbon Score', value: carbon_intensity_score },
              { name: 'Carbon Opportunity', value: (100 - carbon_intensity_score) },
            ];

            let score = Math.round((air_quality_score + 
                          water_intensity_score + 
                          energy_intensity_score + 
                          carbon_intensity_score) / 4);

            let cardClass = "card mb-3 widget-chart widget-chart2 text-left card-btm-border ";
          
            if (score < 25) {
              cardClass += "card-shadow-secondary border-danger";
            } else if (score >= 25 && score < 50) {
              cardClass += "card-shadow-warning border-warning";
            } else if (score >= 50 && score < 75) {
              cardClass += "card-shadow-warning border-secondary";
            } else if (score >= 75) {
              cardClass += "card-shadow-danger border-success";
            }

            console.log("this.state.activeIndex:" + this.state.activeIndex);

            return(
              <Col md="6" xl="4">
                <div className={cardClass}>
                  <div className="resource-card">
                    <div className="header">
                    {/* <div className="location">{item.address.street}, {item.address.city}, {item.address.country}</div> */}
                      <div className="location"> {item.address.city}, {item.address.country}</div>
                      <div className="floor-area">FLoor Size: {item.size_sq_meter.present_value} sq m</div>
                    </div>
                    <div className="chart-container">
                      <PieChart width={400} height={200}>
                        <Pie
                          dataKey="value"
                          startAngle={180}
                          endAngle={0}
                          data={needleData}
                          cx={needleCx}
                          cy={needleCy}
                          innerRadius={needleIr}
                          outerRadius={needleOr}
                          fill="#8884d8"
                          stroke="none"
                        >
                          {needleData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          ))}
                        </Pie>
                        {needle(score, needleData, needleCx, needleCy, needleIr, needleOr, '#000')}
                      </PieChart>
                      </div>
                      <div className="score-container">

                      <ScoreSection title="Total Score" value={`${score} %`} />
                    </div>
                    <div className="intensity-container">
                      <IntensitySection title="Air Quality" score={air_quality_score} value={[`${item.air_quality.relative_humidity.present_value} %RH`,`${item.air_quality.carbon_dioxide.present_value} co2`]} />
                      <IntensitySection title="Water Intensity" score={water_intensity_score} value={`${item.water_intensity.litres.present_value} litres`} />
                      <IntensitySection title="Energy Intensity" score={energy_intensity_score} value={`${item.energy_intensity.kWh.present_value} kWh`} />
                      <IntensitySection title="Carbon Intensity" score={carbon_intensity_score} value={`${item.carbon_intensity.grams_carbon_dioxide_per_kWh.present_value} gCO₂/kWh`} />
                      </div>
                      <PieChart width={500} height={280} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
                        <Pie
                          activeIndex={this.state.activeIndex}
                          activeShape={renderActiveShape}
                          data={pieChartData}
                          cx="50%"
                          cy="50%"
                          innerRadius={80}
                          outerRadius={110}
                          fill="#8884d8"
                          dataKey="value"
                          onMouseEnter={this.onPieEnter}
                        >
                          {pieChartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                          </Pie>
                      </PieChart>

                  </div>
                </div>
              </Col>
            )
          });
        };

        return (
          <>
            <Container fluid>
              <Row> 
                {renderMenuItems(data.metrics)}
              </Row>
            </Container> 
          </>
        );
    }
}