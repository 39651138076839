import React, { Component, Fragment } from "react";
import { CircleSpinner } from 'react-spinners-kit';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './index.css';
import {DeviceName, CtNetChartDataItem, CtNetChartDataValues, CompareCtNetChartData, CtNetChartData, CtNetChartDisplay, DateOption} from '../../../redux/types/chartData.type';
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import {HistoryICtNetData} from '../../../firebase/types/ctnet.type';
import { secondsToIso, createHumanTimestamp } from '../../../utils/timestamp';
import { modeToNumber } from '../../../utils/acstatus';

type Props = {
    deviceId: string;
    handleFilterData: Function;
    dates: DateOption;
    comparisonDates: DateOption;
    firstSelectedDateIndex:number;
    secondSelectedDateIndex:number;
    deviceNames:DeviceName;
    level:string
};
  
type State = {
    fetchedOnce: boolean;
    firstSelectedDateIndex: number;
    secondSelectedDateIndex:number;
    queryElapsedTime: number;
    loading: boolean;
    firstDate: [Date, Date];
    secondDate: [Date, Date];
    firstDateChanged: boolean;
    secondDateChanged: boolean;
    percentagePointsCount: number;
    temperaturePointsCount: number;
    firstEndDate: Date;
    // secondEndDate: Date;
    chartData: CtNetChartData;
    chartDisplay: CtNetChartDisplay;
    deviceNames:DeviceName;
    deviceIdSelected: string;
};

type DatePiece = Date | null;

const defaultProps: Partial<Props> = {
    dates: [
        {id:0, label:'1 Hour', hours:1},
        {id:1, label:'3 Hours', hours:3},
        {id:2, label:'12 Hours', hours:12},
        {id:3, label:'1 Day', hours:24},
        {id:4, label:'3 Days', hours:24*3},
        {id:5, label:'1 Week', hours:24*7},
        {id:6, label:'2 Weeks', hours:24*7*2},
        {id:7, label:'4 Weeks', hours:24*7*4},
        {id:8, label:'2 Months', hours:24*(366/6)},
        {id:9, label:'3 Months', hours:24*(366/4)},
        {id:10, label:'6 Months', hours:24*(366/2)},
        {id:11, label:'1 Year', hours:24*365}
      ],
      comparisonDates:[
        {id:0, label:'1 Hour', hours:1},
        {id:1, label:'3 Hours', hours:3},
        {id:2, label:'12 Hours', hours:12},
        {id:3, label:'1 Day', hours:24},
        {id:4, label:'3 Days', hours:24*3},
        {id:5, label:'1 Week', hours:24*7},
        {id:6, label:'2 Weeks', hours:24*7*2},
        {id:7, label:'4 Weeks', hours:24*7*4},
        {id:8, label:'2 Months', hours:24*(366/6)},
        {id:9, label:'3 Months', hours:24*(366/4)},
        {id:10, label:'6 Months', hours:24*(366/2)},
        {id:11, label:'1 Year', hours:24*365}
      ],
      firstSelectedDateIndex: 5,
      secondSelectedDateIndex: 5
    };
    

export default class CtNetFilterData extends Component<Props, State> {

    static defaultProps = defaultProps;
        
    constructor(props: Props) {
        super(props);
        this.handleFilterData = this.handleFilterData.bind(this);
        this.getFirstStartDate = this.getFirstStartDate.bind(this);
        this.onFirstDateChange = this.onFirstDateChange.bind(this);
        this.onFirstCalendarClose = this.onFirstCalendarClose.bind(this);
        this.getSecondStartDate = this.getSecondStartDate.bind(this);
        this.onSecondDateChange = this.onSecondDateChange.bind(this);
        this.onSecondCalendarClose = this.onSecondCalendarClose.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.formatPointname = this.formatPointname.bind(this);
        this.processDataIntoSegments = this.processDataIntoSegments.bind(this);

        this.state = {
            deviceIdSelected:this.props.deviceId,
            fetchedOnce:false,
            firstSelectedDateIndex: 0,
            secondSelectedDateIndex: 0,
            queryElapsedTime: 0,
            loading: false,
            firstEndDate:new Date(Date.now()),
            firstDate: [new Date(Date.now()), new Date(Date.now())],
            // secondEndDate:new Date('2024-01-31T00:00:00.000Z'),
            secondDate: [new Date(Date.now()), new Date(Date.now())],
            firstDateChanged: false,
            secondDateChanged: false,
            percentagePointsCount:1,
            temperaturePointsCount:1,
            chartData:[
                { timestamp: "2022-08-28T10:50:00.000Z", 
                    phase_1: 1,
                    phase_2:1,
                    phase_3: 1,
                    total:33.8,
                    sensor_battery: 3.5,
                    compare_phase_1: 1,
                    compare_phase_2:1,
                    compare_phase_3: 1,
                    compare_total:33.8,
                    compare_sensor_battery: 3.5
                  },
                  { timestamp: "2022-09-04T08:30:00.000Z", 
                    phase_1: 1,
                    phase_2:1,
                    phase_3: 1,
                    total:33.8,
                    sensor_battery: 3.5,
                    compare_phase_1: 1,
                    compare_phase_2:1,
                    compare_phase_3: 1,
                    compare_total:33.8,
                    compare_sensor_battery: 3.5
                  },
              ],
            chartDisplay:{
                phase_1: true,
                phase_2:true,
                phase_3: true,
                total:true,
                sensor_battery: true,
                compare_phase_1: false,
                compare_phase_2: false,
                compare_phase_3:false,
                compare_total: false,
                compare_sensor_battery:false
              },
              deviceNames:this.props.deviceNames
        };
    }

    componentDidMount = async () => {
        this.getFirstStartDate(this.state.firstEndDate, this.props.firstSelectedDateIndex); 
      };

    processDataIntoSegments(data: CtNetChartData, numSegments: number): CtNetChartData {
        const segmentSize = Math.ceil(data.length / numSegments);
        const averagedData: CtNetChartData = [];
    
        for (let i = 0; i < data.length; i += segmentSize) {
            const segment = data.slice(i, i + segmentSize);
    
            // Initialize the segment average with zeros for all fields except timestamp
            const segmentAverageValues: CtNetChartDataValues = {
                phase_1: 0,
                phase_2:0,
                phase_3: 0,
                total: 0,
                sensor_battery: 0,
                compare_phase_1: 0,
                compare_phase_2: 0,
                compare_phase_3: 0,
                compare_total: 0,
                compare_sensor_battery: 0
            };
    
            // Sum up values for each field in the segment
            for (const item of segment) {
                for (const [key, value] of Object.entries(item)) {
                    if (key !== 'timestamp') {
                        segmentAverageValues[key as keyof CtNetChartDataValues] += value as number;
                    }
                }
            }
    
            // Calculate the average for each field
            for (const key of Object.keys(segmentAverageValues)) {
                segmentAverageValues[key as keyof CtNetChartDataValues] /= segment.length;
            }

            const segmentAverage: CtNetChartDataItem = {
                timestamp: segment[0].timestamp,
                phase_1: segmentAverageValues.phase_1,
                phase_2:segmentAverageValues.phase_2,
                phase_3: segmentAverageValues.phase_3,
                total: segmentAverageValues.total,
                sensor_battery:segmentAverageValues.sensor_battery,
                compare_phase_1:segmentAverageValues.compare_phase_1,
                compare_phase_2: segmentAverageValues.compare_phase_2,
                compare_phase_3:segmentAverageValues.compare_phase_3,
                compare_total:segmentAverageValues.compare_total,
                compare_sensor_battery:segmentAverageValues.compare_sensor_battery
            };
    
            averagedData.push(segmentAverage as CtNetChartDataItem);
        }
    
        return averagedData;
    };

    handleFilterData() {
        const { fetchedOnce } = this.state;
        this.props.handleFilterData(this.state.chartData, this.state.chartDisplay)
        .then(() => {
            if(fetchedOnce) {
                this.runSecondDatabaseQuery();
            }
        });
    };

    getFirstStartDate = (endDate:Date, selectedDateIndex:number) => {
        let startDate = new Date(endDate.getTime());
        startDate.setTime(startDate.getTime() - this.props.dates[selectedDateIndex].hours * 60 * 60 * 1000);
        console.log('getFirstStartDate startDate:' + startDate + ' endDate:' + endDate);
        this.setState({firstDate: [startDate, endDate], firstSelectedDateIndex:selectedDateIndex}, () => {
            this.selectSecondDate(selectedDateIndex);
            this.runFirstDatabaseQuery();
        });
    };

    getSecondStartDate = (firstDate:[Date, Date], selectedDateIndex:number) => {
      let startDate = new Date(firstDate[0].getTime());
    //   startDate.setTime(startDate.getTime() - this.props.comparisonDates[selectedDateIndex].hours * 60 * 60 * 1000);
      startDate.setTime(startDate.getTime() - 24*7 * 60 * 60 * 1000);
      let endDate = new Date(firstDate[1].getTime());
    //   endDate.setTime(endDate.getTime() - this.props.comparisonDates[selectedDateIndex].hours * 60 * 60 * 1000);
      endDate.setTime(endDate.getTime() - 24*7 * 60 * 60 * 1000);
      console.log('getSecondStartDate startDate:' + startDate + ' endDate:' + endDate);
      this.setState({secondDate: [startDate, endDate], secondSelectedDateIndex:selectedDateIndex});
    };

    selectFirstDate = async (selectedDateIndex:number) => {
        console.log("selectFirstDate:" + selectedDateIndex);
        this.getFirstStartDate(this.state.firstEndDate, selectedDateIndex); 
    };

    selectSecondDate = async (selectedDateIndex:number) => {
      console.log("selectSecondDate:" + selectedDateIndex);
      this.getSecondStartDate(this.state.firstDate, selectedDateIndex); 
    };

    runFirstDatabaseQuery = async () => {
        const { firstDate } = this.state;
        this.setState({fetchedOnce:true});
        
        let queryResult: HistoryICtNetData[] = [];
        const start = firstDate[0];
        const end = firstDate[1];
        const deviceId = this.state.deviceIdSelected;
        console.log('runFirstDatabaseQuery deviceId:' + deviceId + ' startDate:' + start + ' endDate:' + end);
        LuminaireDataService.getDeviceTimeseries(this.props.level, deviceId, start, end)
        .get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc:any) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                queryResult.push(doc.data());
            });
        }).then(()=> {
            console.log("runFirstDatabaseQueryResult.length:"+ queryResult.length)
            this.setState({ chartData: this.flattenObjects(queryResult) }, () => {
              this.handleFilterData();
          });
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });
  
    };

    runSecondDatabaseQuery = async () => {
      const { chartData, secondDate } = this.state;
      
      let queryResult: HistoryICtNetData[] = [];
      const start = secondDate[0];
      const end = secondDate[1];
      console.log('runSecondDatabaseQuery startDate:' + start + ' endDate:' + end);
      LuminaireDataService.getDeviceTimeseries('Level-01', this.state.deviceIdSelected, start, end)
      .get()
      .then((querySnapshot: any) => {
          querySnapshot.forEach((doc:any) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              queryResult.push(doc.data());
          });
      }).then(()=> {
          console.log("runSecondDatabaseQueryResult.length:"+ queryResult.length)
          let compareObjects = this.flattenCompareObjects(queryResult);

          this.setState({ chartData: this.mergeChartData(chartData, compareObjects)}, () => {
            this.setState({fetchedOnce:false}, () => {
                this.handleFilterData();
            });
        });
      }).catch((error) => {
          console.log("Error getting documents: ", error);
      });
      
  };

  mergeChartData(originalData: CtNetChartData, compareData: CompareCtNetChartData): CtNetChartData {
    // Assuming originalData and compareData are arrays of objects with matching timestamps
    // Update originalData objects with compareData properties based on matching timestamps
    console.log("originalData:");
    console.log(originalData);
    console.log("compareData:");
    console.log(compareData);
    const mergedData = originalData.map((data) => {
      const compare = compareData.find(compareItem => compareItem.timestamp === data.timestamp);
      if (compare) {
        console.log("compareFound")
        // Spread operator (...) is used to merge the properties of the original and compare objects
        return { ...data, ...compare };
      }
      return data; // In case there's no matching timestamp in compareData, return the original data
    });
  
    return mergedData;
  }

    flattenObjects(historyObjectArray: HistoryICtNetData[]){
        console.log('flattenObjects');
        let chartData: CtNetChartData = historyObjectArray.map(item => ({
            timestamp: secondsToIso(item.timestamp.seconds),
            phase_1: item.points.phase_1?.present_value ?? 0,
            phase_2: item.points.phase_2?.present_value ?? 0,
            phase_3: item.points.phase_3?.present_value ?? 0,
            total: item.points.total?.present_value ?? 0,
            sensor_battery: item.points.sensor_battery?.present_value ?? 0,
            compare_phase_1:  0,
            compare_phase_2:  0,
            compare_phase_3:  0,
            compare_total: 0,
            compare_sensor_battery: 0
          }));
          console.log('flattenObjects' + chartData.length);
        return chartData;
    };

    flattenCompareObjects(historyObjectArray: HistoryICtNetData[]){
      console.log('flattenCompareObjects this.state.secondSelectedDateIndex:' + this.state.secondSelectedDateIndex);
      let compareChartData: CompareCtNetChartData = historyObjectArray.map(item => {
        // const adjustedTimestamp = item.timestamp.seconds + (this.props.comparisonDates[this.state.secondSelectedDateIndex].hours * 60 * 60)
        const adjustedTimestamp = item.timestamp.seconds + (24*365 * 60 * 60)
        return {
          timestamp: secondsToIso(adjustedTimestamp),
          compare_phase_1: item.points.phase_1?.present_value ?? 0,
          compare_phase_2: item.points.phase_2?.present_value ?? 0,
          compare_phase_3: item.points.phase_3?.present_value ?? 0,
          compare_total: item.points.total?.present_value ?? 0,
          compare_sensor_battery: item.points.sensor_battery?.present_value ?? 0,
        }}
      );
      return compareChartData;
  };

    fixNegativeValue(value:number){
        return (value < 0) ? value * -1 : value;
    }

    onFirstDateChange = async (value: DatePiece | [DatePiece, DatePiece]) => {
        if (Array.isArray(value)) {
            // Assuming value is [DatePiece, DatePiece]
            const [startDate, endDate] = value;
            if (startDate && endDate) {
                // Both dates are not null
                this.setState({ firstDate: [startDate, endDate], firstDateChanged: true }, () => {
                    this.getSecondStartDate(this.state.firstDate, 99);
                });
            } else {
                // Handle cases where one or both dates in the tuple are null
                // You might want to reset the state or set it to a default value
            }
        } else {
            // Handle the case where value is a single Date object or null
            // You might want to set an appropriate state here
        }
    };

    onFirstCalendarClose = async () => {
        if (this.state.firstDateChanged) {
          this.setState({firstSelectedDateIndex:99, firstDateChanged: false });
          return;
        } else {
            // await this.getStartDate(new Date(), this.props.selectedDateIndex);
        }
    };

    onSecondDateChange = async (value: DatePiece | [DatePiece, DatePiece]) => {
      if (Array.isArray(value)) {
          // Assuming value is [DatePiece, DatePiece]
          const [startDate, endDate] = value;
          if (startDate && endDate) {
              // Both dates are not null
              this.setState({ secondDate: [startDate, endDate], secondDateChanged: true });
          } else {
              // Handle cases where one or both dates in the tuple are null
              // You might want to reset the state or set it to a default value
          }
      } else {
          // Handle the case where value is a single Date object or null
          // You might want to set an appropriate state here
      }
  };

  onSecondCalendarClose = async () => {
      if (this.state.secondDateChanged) {
        this.setState({secondSelectedDateIndex:99, secondDateChanged: false });
        return;
      } else {
          // await this.getStartDate(new Date(), this.props.selectedDateIndex);
      }
  };

    handleCheckboxChange = (pointName: keyof CtNetChartDisplay) => {
      console.log('handleCheckboxChange');
        this.setState(prevState => ({
            chartDisplay: {
                ...prevState.chartDisplay,
                [pointName]: !prevState.chartDisplay[pointName]
            }
        }), () => {
            this.handleFilterData();
        });
    };

    //multiple checkboxes checked
    // handleDeviceCheckboxChange = (index: number) => {
    //     this.setState(prevState => {
    //         const updatedDeviceNames = [...prevState.deviceNames];
    //         updatedDeviceNames[index] = {
    //             ...updatedDeviceNames[index],
    //             checked: !updatedDeviceNames[index].checked
    //         };
    
    //         return { deviceNames: updatedDeviceNames };
    //     }, () => {
    //         // this.handleFilterData();
    //     });
    // };

    //single checkbox checked
    handleDeviceCheckboxChange = (index: number) => {
        this.setState(prevState => {
            const updatedDeviceNames = prevState.deviceNames.map((device, i) => ({
                ...device,
                checked: i === index ? !device.checked : false 
            }));
    
            return { deviceNames: updatedDeviceNames };
        }, () => {
            const checkedDevice = this.state.deviceNames.find(device => device.checked);
            const checkedDeviceName: string = checkedDevice ? checkedDevice.name : 'TX-00';
            this.setState({deviceIdSelected: checkedDeviceName})
        });
    };

    formatPointname(text:String) {
        return text
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    render() {
        const { loading, firstSelectedDateIndex, secondSelectedDateIndex } = this.state;

        try{
            return(
                <div>

                    <div className='pf-filter-timerange-container'>
                    <div className='pf-filter-time-series-container'>
                            <h4 className='pf-list-header'>
                                Select asset to analyse.
                            </h4>
                            <div className='pf-filter-timerange-predefine2'>
                                    {this.state.deviceNames.map((device, index) => (
                                        // <div className="pf-filter-check-box" key={device.name}>
                                        //     <label>
                                        //         {device.name}
                                        //         <input
                                        //             type="checkbox"
                                        //             checked={device.checked}
                                        //             onChange={() => this.handleDeviceCheckboxChange(index)}
                                        //         />
                                        //     </label>
                                        // </div>
                                        <div
                                        key={index}
                                        className={
                                            device.checked
                                            ? 'pf-filter-time-box time-box-selected'
                                            : 'pf-filter-time-box'
                                        }
                                        onClick={() => {
                                          this.handleDeviceCheckboxChange(index);
                                        }}
                                        >
                                            <p>{device.name}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className='pf-filter-time-series-container'>
                            <h4 className='pf-list-header'>
                                Select pre-defined time period.
                            </h4>
                            <div className='pf-filter-timerange-predefine2'>
                                {this.props.dates.map((obj, index) => (
                                    <div
                                    key={index}
                                    className={
                                      firstSelectedDateIndex === index
                                        ? 'pf-filter-time-box time-box-selected'
                                        : 'pf-filter-time-box'
                                    }
                                    onClick={() => {
                                      this.selectFirstDate(index);
                                    }}
                                    >
                                        <p>{obj.label}</p>
                                    </div>
                                ))}
                            </div>
                            <h4 className='pf-list-header'>
                                Select custom time period.
                            </h4>
                            <div className="pf-datetimerangepicker-container">
                                <div className="pf-datetimerangepicker-content">
                                    <DateTimeRangePicker
                                    onChange={this.onFirstDateChange}
                                    value={this.state.firstDate}
                                    maxDate={this.state.firstEndDate}
                                    onCalendarClose={this.onFirstCalendarClose}
                                    />
                                </div>
                            </div>
                            <h4 className='pf-btn-text' onClick={
                                this.runFirstDatabaseQuery
                                }>
                                Fetch Data
                            </h4>
                        </div>
                        <div className='pf-filter-time-series-container'>
                            {/* <h4 className='pf-list-header'>
                                Select pre-defined time period to compare.
                            </h4>
                            <div className='pf-filter-timerange-predefine2'>
                                {this.props.comparisonDates.map((obj, index) => (
                                    <div
                                    key={index}
                                    className={
                                      secondSelectedDateIndex === index
                                        ? 'pf-filter-time-box time-box-selected'
                                        : 'pf-filter-time-box'
                                    }
                                    onClick={() => this.selectSecondDate(index)}
                                    >
                                        <p>{obj.label}</p>
                                    </div>
                                ))}
                            </div>
                            <h4 className='pf-list-header'>
                                Select custom time period to compare.
                            </h4>
                            <div className="pf-datetimerangepicker-container">
                                <div className="pf-datetimerangepicker-content">
                                    <DateTimeRangePicker
                                    onChange={this.onSecondDateChange}
                                    value={this.state.secondDate}
                                    maxDate={this.state.firstDate[0]}
                                    onCalendarClose={this.onSecondCalendarClose}
                                    />
                                </div>
                            </div>
                            <h4 className='pf-btn-text' onClick={this.runSecondDatabaseQuery}>
                                Fetch Comparison Data
                            </h4>          */}
                            <h4 className='pf-list-header'>
                                Select fields to compare previous period.
                                <br/>{createHumanTimestamp(this.state.secondDate[0].toISOString())} to {createHumanTimestamp(this.state.secondDate[1].toISOString())}
                            </h4>
                            <div className='pf-filter-timerange-predefine'>
                                <div className='checkbox-container'>
                                    {Object.entries(this.state.chartDisplay as CtNetChartDisplay)
                                    .filter(([pointName, _]) => pointName.includes('compare'))
                                    .filter(([pointName, _]) => !pointName.includes('weeks'))
                                    .filter(([pointName, _]) => !pointName.includes('months'))
                                    .filter(([pointName, _]) => !pointName.includes('yesterdays'))
                                    .map(([pointName, isChecked]) => (
                                        <div className="pf-filter-check-box" key={pointName}>
                                            <label>
                                                {this.formatPointname(pointName)}
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => this.handleCheckboxChange(pointName as keyof CtNetChartDisplay)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            
                        </div>
                        <div className='pf-filter-time-series-container'>
                            <h4 className='pf-list-header'>
                                Select fields to chart.
                            </h4>
                            <div className='pf-filter-timerange-predefine'>
                                <div className='checkbox-container'>
                                    {Object.entries(this.state.chartDisplay as CtNetChartDisplay)
                                    .filter(([pointName, _]) => !pointName.includes('compare'))
                                    .filter(([pointName, _]) => !pointName.includes('weeks'))
                                    .filter(([pointName, _]) => !pointName.includes('months'))
                                    .filter(([pointName, _]) => !pointName.includes('yesterdays'))
                                    .map(([pointName, isChecked]) => (
                                        <div className="pf-filter-check-box" key={pointName}>
                                            <label>
                                                {this.formatPointname(pointName)}
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => this.handleCheckboxChange(pointName as keyof CtNetChartDisplay)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    

                        
                    {loading ? (
                        <div className='pf-loading-container'>
                        <CircleSpinner size={50} color='black' loading={true} />
                        <h4>Fetching data - This could take several minutes...</h4>
                        </div>
                    ) : null}
                </div>
            );
        } catch (exception) {
            console.log(exception);
            return (
                <h2>An Error has occurred...</h2>
            );
        }
    }
}