import React, { FC } from 'react';

interface BoxProps {
  top?: number;
  left?: number;
  backgroundColor?: string;
  color?: string;
  value?: number | string;
}

export const Box: FC<BoxProps> = ({
  top,
  left,
  backgroundColor = 'white',
  color = 'black',
  value,
}) => {
  return (
    <div
      className="fcu_type_1_b__box-wrapper"
      style={{ top: top, left: left }}
    >
      <div
        className="fcu_type_1_b__box-content"
        style={{ backgroundColor: backgroundColor }}
      >
        <p
          className="fcu_type_1_b__box-value-text"
          style={{ color: color }}
        >
          {value === undefined ? '...' : String(value)}
        </p>
      </div>
    </div>
  );
};