import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import "firebase/compat/auth";
import "firebase/compat/functions";

const devConfig = {
    apiKey: "AIzaSyC44etIszqpjMDqrTnKr-GCMypXHfrlV44",
    authDomain: "dev-thaniumos.firebaseapp.com",
    databaseURL: 'https://dev-thaniumos.firebaseio.com',
    projectId: "dev-thaniumos",
    storageBucket: "dev-thaniumos.appspot.com",
    messagingSenderId: "847292574198",
    appId: "1:847292574198:web:de4b86a87d560ed3e2bfd7",
    measurementId: "G-QC40Q6K34F"
  };

const prodConfig = {
  apiKey: "AIzaSyBXi4ukkef_Aky4g8Wrc4qIOiNPpti176A",
  authDomain: "prod-thaniumos.firebaseapp.com",
  databaseURL: 'https://prod-thaniumos.firebaseio.com',
  projectId: "prod-thaniumos",
  storageBucket: "prod-thaniumos.appspot.com",
  messagingSenderId: "146088666036",
  appId: "1:146088666036:web:46660ab3383401db5188e1",
  measurementId: "G-L47E60ES5C"
};

firebase.initializeApp(prodConfig);

const firestore = firebase.firestore();

export const functions = firebase.functions();

export const auth = firebase.auth();

export default firestore;