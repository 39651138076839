import React, { useCallback, useState, useEffect  } from "react";

import { Slider } from "react-burgers";

import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

// import AppMobileMenu from "../AppMobileMenu";

import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { StoreState } from '../../redux/types/store.type';

import {setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall, ThemeOptionsState} from "../../redux/types/themeOptions.type";
import {sEnableClosedSidebar, sEnableMobileMenu, sEnableMobileMenuSmall} from "../../redux/reducers/ThemeOptions";

import UserDataService from "../../firebase/services/user.service";
import firebase from "firebase/compat/app";

interface OwnProps {
    user: firebase.User | null
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
};

type MapDispatchToPropsType = {
    setEnableClosedSidebar: (enable: boolean) => void;
    setEnableMobileMenu: (enable: boolean) => void;
    setEnableMobileMenuSmall: (enable: boolean) => void;
};
  
  type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType & MapDispatchToPropsType;
  
  const useThemeActions = () => {
    const dispatch = useDispatch<ThunkDispatch<setEnableClosedSidebar | setEnableMobileMenu | setEnableMobileMenuSmall, unknown, AnyAction>>();
  
    const memoizedSetEnableClosedSidebar = useCallback((enable: boolean) => dispatch(sEnableClosedSidebar(enable)), [dispatch]);
    const memoizedSetEnableMobileMenu = useCallback((enable: boolean) => dispatch(sEnableMobileMenu(enable)), [dispatch]);
    const memoizedSetEnableMobileMenuSmall = useCallback((enable: boolean) => dispatch(sEnableMobileMenuSmall(enable)), [dispatch]);

    return {
        setEnableClosedSidebar: memoizedSetEnableClosedSidebar,
        setEnableMobileMenu: memoizedSetEnableMobileMenu,
        setEnableMobileMenuSmall: memoizedSetEnableMobileMenuSmall
    };
};

const HeaderLogo: React.FC<Props> = ({ ThemeOptions, setEnableClosedSidebar, user }) => {
    const [active, setActive] = useState<boolean>(false);
    const [logoBranding, setLogoBranding] = useState<string>("logo-src"); // Use state for logoBranding

    useEffect(() => {
        if (user && user.uid) {
            UserDataService.getBrandingByUserId(user.uid)
                .then(brand => {
                    if (brand === "electracom") {
                      setLogoBranding("logo-electracom"); // Update state
                    } else if (brand === "simmtronic") {
                      setLogoBranding("logo-simmtronic"); // Update state
                    } else {
                      setLogoBranding("logo-src"); // Update state
                    }
                })
                .catch(error => {
                    console.error('Error fetching branding:', error);
                });
        } else {
            console.log('User or User UID is undefined');
        }
    }, [user]); 

    const toggleEnableClosedSidebar = () => {
        setEnableClosedSidebar(!ThemeOptions.enableClosedSidebar);
    };
  
    return (
      <>
        <div className="app-header__logo">

          <div className={logoBranding} />
          <div className="header__pane ml-auto">
            <div onClick={toggleEnableClosedSidebar}>
              <Slider
                width={26}
                lineHeight={2}
                lineSpacing={5}
                color="#6c757d"
                active={active}
                onClick={() => setActive(!active)}
              />
            </div>
          </div>
        </div>
        {/* <AppMobileMenu /> */}
      </>
    );
  };
  
const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
  return {
    ThemeOptions: state.ThemeOptions,
  };
};
  
const connector = connect(mapStateToProps);

export default connector((props: Props) => {
  const { setEnableClosedSidebar, setEnableMobileMenu, setEnableMobileMenuSmall } = useThemeActions();

  return <HeaderLogo {...props} setEnableClosedSidebar={setEnableClosedSidebar} setEnableMobileMenu={setEnableMobileMenu} setEnableMobileMenuSmall={setEnableMobileMenuSmall} />;
});