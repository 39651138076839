import React from 'react';
import styled from 'styled-components';
import { ScoreSection } from "../scoreSection";

interface IntensitySectionProps {
    title: string;
    score: number;
    value: string | string[];  // Now value can be either a string or an array of strings
  }

const Section = styled.div`
  margin-bottom: 20px;
  justify-content: center; /* Centers horizontally */
  width: 100%;
  text-align: center;
`;

const IntensityTitle = styled.h4`
  font-size: 1.1em;
  color: #000;
  margin: 0;
`;

const IntensityValue = styled.div`
  font-size: 0.95em;
  color: #000;
  display: block;  // Each value will be in a new line
`;

const getBackgroundColor = (score: number) => {
    if (score >= 75) {
      return '#28a745'; // Green for scores 75 to 100
    } else if (score >= 50) {
      return '#6c757d'; // Grey for scores 50 to 75
    } else if (score >= 25) {
      return '#ffc107'; // Yellow for scores 25 to 50
    } else {
      return '#dc3545'; // Red for scores 0 to 25
    }
  };
const IntensityValues = styled.div<{ score: number }>`
  background-color: ${props => getBackgroundColor(props.score)};
  width: 120px;
  height: 90px;
  padding: 10px;
  margin: 10px auto;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-radius: 8px;
`;

export const IntensitySection: React.FC<IntensitySectionProps> = ({ title, score, value }) => (
  <Section>
    <IntensityTitle>{title}</IntensityTitle>
    {/* Check if value is an array and map over it, otherwise just display the single value */}
    <IntensityValues score={score}> 
    <ScoreSection title="Score" value={`${score} %`} />
        {Array.isArray(value) ? (
        value.map((val, index) => <IntensityValue key={index}>{val}</IntensityValue>)
        ) : (
        <IntensityValue>{value}</IntensityValue>
        )}
    </IntensityValues>
  </Section>
);