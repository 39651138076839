import React, { Component, Fragment } from "react";
import { CircleSpinner } from 'react-spinners-kit';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './index.css';
import {DeviceName, ChlChartDataItem, ChlChartDataValues, ChlChartData, ChlChartDisplay, DateOption} from '../../../redux/types/chartData.type';
import ChlDataService from "../../../firebase/services/chl.service";
import {ExHistoryIChlData} from '../../../firebase/types/chl.type';
import { secondsToIso } from '../../../utils/timestamp';

type Props = {
    deviceId: string;
    handleFilterData: Function;
    dates: DateOption;
    selectedDateIndex:number;
    
};
  
type State = {
    selectedDateIndex: number;
    queryElapsedTime: number;
    loading: boolean;
    date: [Date, Date];
    dateChanged: boolean;
    percentagePointsCount: number;
    temperaturePointsCount: number;
    endDate: Date;
    chartData: ChlChartData;
    chartDisplay: ChlChartDisplay;
    deviceNames:DeviceName;
    deviceIdSelected: string;
};

type DatePiece = Date | null;

const defaultProps: Partial<Props> = {
    dates: [
        {id:0, label:'1 hour', hours:1},
        {id:1, label:'3 hours', hours:3},
        {id:2, label:'12 hours', hours:12},
        {id:3, label:'1 day', hours:24},
        {id:4, label:'3 days', hours:24*3},
        {id:5, label:'1 week', hours:24*7},
        {id:6, label:'2 weeks', hours:24*7*2},
        {id:7, label:'1 month', hours:24*7*4}
      ],
      selectedDateIndex: 4
    };
    

export default class ChlFilterData extends Component<Props, State> {

    static defaultProps = defaultProps;
        
    constructor(props: Props) {
        super(props);
        this.handleFilterData = this.handleFilterData.bind(this);
        this.getStartDate = this.getStartDate.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onCalendarClose = this.onCalendarClose.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.formatPointname = this.formatPointname.bind(this);
        this.processDataIntoSegments = this.processDataIntoSegments.bind(this);

        this.state = {
            deviceIdSelected:this.props.deviceId,
            selectedDateIndex: 0,
            queryElapsedTime: 0,
            loading: false,
            endDate:new Date('2024-01-31T00:00:00.000Z'),
            date: [new Date('2024-01-31T00:00:00.000Z'), new Date('2024-01-31T00:00:00.000Z')],
            dateChanged: false,
            percentagePointsCount:1,
            temperaturePointsCount:1,
            chartData:[
                { timestamp: "2021-09-11T04:00:00.000Z", 
                  entering_water_temp:18.2,
                  leaving_water_temp:18.0,
                  percentage_total_capacity:0.0,
                  suction_pressure_a:435.8,
                  suction_pressure_b:437,
                  discharge_pressure_a:460.4,
                  discharge_pressure_b:473.5,
                  machine_operation_hours:14737,
                  machine_starts: 47637,
                  compressor_a_hours_run: 9342,
                  compressor_a_starts: 25696,
                  compressor_b_hours_run:9342,
                  compressor_b_starts: 23875
                },
                { timestamp: "2021-11-03T15:15:00.000Z", 
                  entering_water_temp:12.8,
                  leaving_water_temp:11,
                  percentage_total_capacity:13,
                  suction_pressure_a:310.60,
                  suction_pressure_b:261.2,
                  discharge_pressure_a:331.30,
                  discharge_pressure_b:742.20,
                  machine_operation_hours:15336,
                  machine_starts: 47686,
                  compressor_a_hours_run: 8830,
                  compressor_a_starts:25781 ,
                  compressor_b_hours_run:9780,
                  compressor_b_starts:23934
                },
              ],
            chartDisplay:{
                entering_water_temp:true,
                leaving_water_temp:true,
                percentage_total_capacity:true,
                suction_pressure_a:true,
                suction_pressure_b:true,
                discharge_pressure_a:true,
                discharge_pressure_b:true,
                machine_operation_hours:false,
                machine_starts: false,
                compressor_a_hours_run: false,
                compressor_a_starts: false,
                compressor_b_hours_run:false,
                compressor_b_starts: false
              },
              deviceNames:[
                {name:"ACC-2", checked:true},
                {name:"ACC-3", checked:false},
                {name:"ACC-4", checked:false},
                {name:"ACC-5", checked:false},
            ]
        };
    }

    componentDidMount = async () => {
        this.getStartDate(this.state.endDate, this.props.selectedDateIndex); 
    };

    processDataIntoSegments(data: ChlChartData, numSegments: number): ChlChartData {
        const segmentSize = Math.ceil(data.length / numSegments);
        const averagedData: ChlChartData = [];
    
        for (let i = 0; i < data.length; i += segmentSize) {
            const segment = data.slice(i, i + segmentSize);
    
            // Initialize the segment average with zeros for all fields except timestamp
            const segmentAverageValues: ChlChartDataValues = {
                entering_water_temp:0,
                leaving_water_temp:0,
                percentage_total_capacity:0,
                suction_pressure_a:0,
                suction_pressure_b:0,
                discharge_pressure_a:0,
                discharge_pressure_b:0,
                machine_operation_hours:0,
                machine_starts: 0,
                compressor_a_hours_run: 0,
                compressor_a_starts:0,
                compressor_b_hours_run:0,
                compressor_b_starts:0
            };
    
            // Sum up values for each field in the segment
            for (const item of segment) {
                for (const [key, value] of Object.entries(item)) {
                    if (key !== 'timestamp') {
                        segmentAverageValues[key as keyof ChlChartDataValues] += value as number;
                    }
                }
            }
    
            // Calculate the average for each field
            for (const key of Object.keys(segmentAverageValues)) {
                segmentAverageValues[key as keyof ChlChartDataValues] /= segment.length;
            }

            const segmentAverage: ChlChartDataItem = {
                timestamp: segment[0].timestamp,
                entering_water_temp: segmentAverageValues.entering_water_temp,
                leaving_water_temp: segmentAverageValues.leaving_water_temp,
                percentage_total_capacity: segmentAverageValues.percentage_total_capacity,
                suction_pressure_a: segmentAverageValues.suction_pressure_a,
                suction_pressure_b: segmentAverageValues.suction_pressure_b,
                discharge_pressure_a: segmentAverageValues.discharge_pressure_a,
                discharge_pressure_b: segmentAverageValues.discharge_pressure_b,
                machine_operation_hours: segmentAverageValues.machine_operation_hours,
                machine_starts: segmentAverageValues.machine_starts,
                compressor_a_hours_run: segmentAverageValues.compressor_a_hours_run,
                compressor_a_starts: segmentAverageValues.compressor_a_starts,
                compressor_b_hours_run: segmentAverageValues.compressor_b_hours_run,
                compressor_b_starts: segmentAverageValues.compressor_b_starts
            };
    
            averagedData.push(segmentAverage as ChlChartDataItem);
        }
    
        return averagedData;
    };

    handleFilterData() {
        this.props.handleFilterData(this.state.chartData, this.state.chartDisplay);
    };

    getStartDate = (endDate:Date, selectedDateIndex:number) => {
        let startDate = new Date(endDate.getTime());
        startDate.setTime(startDate.getTime() - this.props.dates[selectedDateIndex].hours * 60 * 60 * 1000);
        this.setState({date: [startDate, endDate], selectedDateIndex});
    };

    selectDate = async (selectedDateIndex:number) => {
        this.getStartDate(this.state.endDate, selectedDateIndex); 
    };

    runDatabaseQuery = async () => {
        const { date } = this.state;

        let queryResult: ExHistoryIChlData[] = [];
        // const start = new Date('2020-04-17T00:00:00.000Z');
        // const end = new Date('2020-04-18T00:30:59.000Z');
        const start = date[0];
        const end = date[1];
        ChlDataService.getDeviceTimeseries(this.state.deviceIdSelected, start, end)
        .get()
        .then((querySnapshot: any) => {
            querySnapshot.forEach((doc:any) => {
                // doc.data() is never undefined for query doc snapshots
                console.log(doc.id, " => ", doc.data());
                queryResult.push(doc.data());
            });
        }).then(()=> {
            console.log("queryResult.length:"+ queryResult.length)
            // let flattened = (this.state.selectedDateIndex > 3) ? 
            // this.processDataIntoSegments(this.flattenObjects(queryResult), 100) : 
            // this.flattenObjects(queryResult);
            this.setState({ chartData: this.flattenObjects(queryResult) }, () => {
              this.handleFilterData();
          });
        }).catch((error) => {
            console.log("Error getting documents: ", error);
        });
        
    };

    flattenObjects(historyObjectArray: ExHistoryIChlData[]){
        let demoObjectArray = [{
            "timestamp": "2024-01-26T23:01:47.651Z",
            "version": 1,
            "points": {
                "chiller_removed_from_sequence": {
                  "present_value":  "false"
                },
                "chiller_enable_status": {
                  "present_value":  "false"
                },
                "isolation_valve_failed": {
                  "present_value":  "false"
                },
                "percentage_total_capacity": {
                  "present_value":  "0"
                },
                "entering_water_temp": {
                  "present_value":  "9.78"
                },
                "leaving_water_temp": {
                  "present_value":  "10.06"
                },
                "chiller_shutdown_alarm": {
                  "present_value":  "false"
                },
                "isolation_valve_open": {
                  "present_value":  "false"
                },
                "isolation_valve_enable": {
                  "present_value":  "false"
                },
                "chiller_enabled": {
                  "present_value":  "false"
                },
                "chiller_required": {
                  "present_value":  "false"
                },
                "status": {
                  "present_value":  "true"
                },
                "partial_alarm": {
                  "present_value":  "false"
                },
                "shutdown_alarm": {
                  "present_value":  "false"
                },
                "suction_pressure_a": {
                  "present_value":  "316.59"
                },
                "suction_pressure_b": {
                  "present_value":  "325.01"
                },
                "discharge_pressure_a": {
                  "present_value":  "344.51"
                },
                "discharge_pressure_b": {
                  "present_value":  "349.78"
                },
                "machine_operation_hours": {
                  "present_value":  "16903"
                },
                "machine_starts": {
                  "present_value":  "33865"
                },
                "pump_duty": {
                  "present_value":  "2"
                },
                "pump_flow_proving": {
                  "present_value":  "false"
                },
                "compressor_a_hours_run": {
                  "present_value":  "8561"
                },
                "compressor_a_starts": {
                  "present_value":  "17926"
                },
                "compressor_b_hours_run": {
                  "present_value":  "10897"
                },
                "compressor_b_starts": {
                  "present_value":  "17693"
                },
                "chiller_fan_a1_hours_run": {
                  "present_value":  "8486.25"
                },
                "chiller_fan_a2_hours_run": {
                  "present_value":  "8185.25"
                },
                "chiller_fan_a3_hours_run": {
                  "present_value":  "7747.5"
                },
                "chiller_fan_a4_hours_run": {
                  "present_value":  "7035.75"
                },
                "chiller_fan_a5_hours_run": {
                  "present_value":  "6574.75"
                },
                "chiller_fan_a6_hours_run": {
                  "present_value":  "6012.25"
                },
                "chiller_fan_a7_hours_run": {
                  "present_value":  "8681.75"
                },
                "chiller_fan_b1_hours_run": {
                  "present_value":  "10818"
                },
                "chiller_fan_b2_hours_run": {
                  "present_value":  "10420.5"
                },
                "chiller_fan_b3_hours_run": {
                  "present_value":  "9761.75"
                },
                "chiller_fan_b4_hours_run": {
                  "present_value":  "9155.25"
                },
                "chiller_fan_b5_hours_run": {
                  "present_value":  "9869.5"
                }
              }
        }];
        let chartData: ChlChartData = historyObjectArray.map(item => ({
            timestamp: secondsToIso(item.timestamp.seconds),
            entering_water_temp: item.points.entering_water_temp.present_value,
            leaving_water_temp: item.points.leaving_water_temp.present_value,
            percentage_total_capacity: item.points.percentage_total_capacity.present_value,
            suction_pressure_a: item.points.suction_pressure_a.present_value,
            suction_pressure_b: item.points.suction_pressure_b.present_value,
            discharge_pressure_a: item.points.discharge_pressure_a.present_value,
            discharge_pressure_b: item.points.discharge_pressure_b.present_value,
            machine_operation_hours: item.points.machine_operation_hours.present_value,
            machine_starts: item.points.machine_starts.present_value,
            compressor_a_hours_run: item.points.compressor_a_hours_run.present_value,
            compressor_a_starts: item.points.compressor_a_starts.present_value,
            compressor_b_hours_run: item.points.compressor_b_hours_run.present_value,
            compressor_b_starts: item.points.compressor_b_starts.present_value,
          }));
        return chartData;
    };

    fixNegativeValue(value:number){
        return (value < 0) ? value * -1 : value;
    }

    onDateChange = async (value: DatePiece | [DatePiece, DatePiece]) => {
        if (Array.isArray(value)) {
            // Assuming value is [DatePiece, DatePiece]
            const [startDate, endDate] = value;
            if (startDate && endDate) {
                // Both dates are not null
                this.setState({ date: [startDate, endDate], dateChanged: true });
            } else {
                // Handle cases where one or both dates in the tuple are null
                // You might want to reset the state or set it to a default value
            }
        } else {
            // Handle the case where value is a single Date object or null
            // You might want to set an appropriate state here
        }
    };

    onCalendarClose = async () => {
        if (this.state.dateChanged) {
          this.setState({selectedDateIndex:99, dateChanged: false });
          return;
        } else {
            // await this.getStartDate(new Date(), this.props.selectedDateIndex);
        }
    };

    handleCheckboxChange = (pointName: keyof ChlChartDisplay) => {
        this.setState(prevState => ({
            chartDisplay: {
                ...prevState.chartDisplay,
                [pointName]: !prevState.chartDisplay[pointName]
            }
        }), () => {
            this.handleFilterData();
        });
    };
    //multiple checkboxes checked
    // handleDeviceCheckboxChange = (index: number) => {
    //     this.setState(prevState => {
    //         const updatedDeviceNames = [...prevState.deviceNames];
    //         updatedDeviceNames[index] = {
    //             ...updatedDeviceNames[index],
    //             checked: !updatedDeviceNames[index].checked
    //         };
    
    //         return { deviceNames: updatedDeviceNames };
    //     }, () => {
    //         // this.handleFilterData();
    //     });
    // };

    //single checkbox checked
    handleDeviceCheckboxChange = (index: number) => {
      this.setState(prevState => {
          const updatedDeviceNames = prevState.deviceNames.map((device, i) => ({
              ...device,
              checked: i === index ? !device.checked : false 
          }));
  
          return { deviceNames: updatedDeviceNames };
      }, () => {
          const checkedDevice = this.state.deviceNames.find(device => device.checked);
          const checkedDeviceName: string = checkedDevice ? checkedDevice.name : 'TX-00';
          this.setState({deviceIdSelected: checkedDeviceName})
      });
  };

    formatPointname(text:String) {
        return text
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    render() {
        const { loading, selectedDateIndex } = this.state;

        try{
            return(
                <div>

                    <div className='pf-filter-timerange-container'>
                      <div className='pf-filter-time-series-container'>
                            <h4 className='pf-list-header'>
                              Select asset to analyse.
                            </h4>
                            <div className='pf-filter-timerange-predefine2'>
                                    {this.state.deviceNames.map((device, index) => (
                                        // <div className="pf-filter-check-box" key={device.name}>
                                        //     <label>
                                        //         {device.name}
                                        //         <input
                                        //             type="checkbox"
                                        //             checked={device.checked}
                                        //             onChange={() => this.handleDeviceCheckboxChange(index)}
                                        //         />
                                        //     </label>
                                        // </div>
                                        <div
                                        key={index}
                                        className={
                                            device.checked
                                            ? 'pf-filter-time-box time-box-selected'
                                            : 'pf-filter-time-box'
                                        }
                                        onClick={() => {
                                          this.handleDeviceCheckboxChange(index);
                                        }}
                                        >
                                            <p>{device.name}</p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <div className='pf-filter-time-series-container'>
                            <h4 className='pf-list-header'>
                                Select pre-defined time period.
                            </h4>
                            <div className='pf-filter-timerange-predefine2'>
                                {this.props.dates.map((obj, index) => (
                                    <div
                                    key={index}
                                    className={
                                        selectedDateIndex === index
                                        ? 'pf-filter-time-box time-box-selected'
                                        : 'pf-filter-time-box'
                                    }
                                    onClick={() => this.selectDate(index)}
                                    >
                                        <p>{obj.label}</p>
                                    </div>
                                ))}
                            </div>
                            <h4 className='pf-list-header'>
                                Select custom time period.
                            </h4>
                            <div className="pf-datetimerangepicker-container">
                                <div className="pf-datetimerangepicker-content">
                                    <DateTimeRangePicker
                                    onChange={this.onDateChange}
                                    value={this.state.date}
                                    maxDate={this.state.endDate}
                                    onCalendarClose={this.onCalendarClose}
                                    />
                                </div>
                            </div>
                            <h4 className='pf-btn-text' onClick={this.runDatabaseQuery}>
                                Fetch Data
                            </h4>
                        </div>
                        <div className='pf-filter-time-series-container'>
                            <h4 className='pf-list-header'>
                                Select fields to chart.
                            </h4>
                            <div className='pf-filter-timerange-predefine'>
                                <div className='checkbox-container'>
                                    {Object.entries(this.state.chartDisplay as ChlChartDisplay).map(([pointName, isChecked]) => (
                                        <div className="pf-filter-check-box" key={pointName}>
                                            <label>
                                                {this.formatPointname(pointName)}
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => this.handleCheckboxChange(pointName as keyof ChlChartDisplay)}
                                                />
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                    

                        
                    {loading ? (
                        <div className='pf-loading-container'>
                        <CircleSpinner size={50} color='black' loading={true} />
                        <h4>Fetching data - This could take several minutes...</h4>
                        </div>
                    ) : null}
                </div>
            );
        } catch (exception) {
            console.log(exception);
            return (
                <h2>An Error has occurred...</h2>
            );
        }
    }
}