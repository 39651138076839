import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { DraggableComponentProps } from "../../types/draggable-component";

interface Props {
  children?: React.ReactNode;
}

interface ContextProps {
  selectedComponent: DraggableComponentProps | null;
  setSelectedComponent: Dispatch<
    SetStateAction<DraggableComponentProps | null>
  >;
}

const SelectorContext = createContext<ContextProps>({
  selectedComponent: null,
  setSelectedComponent: () => {},
});

const SelectorProvider: FC<Props> = ({ children }) => {
  const [selectedComponent, setSelectedComponent] =
    useState<DraggableComponentProps | null>(null);

  return (
    <SelectorContext.Provider
      value={{ selectedComponent, setSelectedComponent }}
    >
      {children}
    </SelectorContext.Provider>
  );
};

export const useSelectorContext = () => {
  const context = useContext(SelectorContext);
  if (context === undefined) {
    throw new Error("useSelectorContext must be within SelectorProvider");
  }

  return context;
};

export default SelectorProvider;
