import * as actionTypes from "../types/article.action.type"
import { 
  IArticle, 
  ArticleState, 
  ArticleAction, 
  FetchArticlesRequestAction,
  FetchArticlesSuccessAction,
  FetchArticlesFailureAction
 } from '../types/article.type';
import { ThunkAction } from 'redux-thunk';

export function addArticle(article: IArticle): ThunkAction<void, ArticleState, unknown, ArticleAction> {
  return (dispatch) => {
    const action: ArticleAction = {
      type: actionTypes.ADD_ARTICLE,
      article,
    };
    dispatch(action);
  };
}

export function removeArticle(article: IArticle): ThunkAction<void, ArticleState, unknown, ArticleAction> {
  return (dispatch) => {
    const action: ArticleAction = {
      type: actionTypes.REMOVE_ARTICLE,
      article,
    };
    dispatch(action);
  };
}

export function fetchArticles(): ThunkAction<void, ArticleState, unknown, ArticleAction> {
  return (dispatch) => {
    dispatch(fetchArticlesRequest());
    fetch('https://jsonplaceholder.typicode.com/posts')
      .then((response) => response.json())
      .then((data) => {
        const articles = data.slice(0, 10);
        dispatch(fetchArticlesSuccess(articles));
      })
      .catch((error) => {
        dispatch(fetchArticlesFailure(error.message));
      });
  };
}

export function fetchArticlesRequest(): FetchArticlesRequestAction {
  return {
    type: actionTypes.FETCH_ARTICLES_REQUEST,
  };
}

export function fetchArticlesSuccess(articles: IArticle[]): FetchArticlesSuccessAction {
  return {
    type: actionTypes.FETCH_ARTICLES_SUCCESS,
    payload: articles,
  };
}

export function fetchArticlesFailure(error: string): FetchArticlesFailureAction {
  return {
    type: actionTypes.FETCH_ARTICLES_FAILURE,
    payload: error,
  };
}
