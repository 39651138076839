import {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import update from "immutability-helper";
import { useSelectorContext } from "../../../providers/selector-provider";
import { useGraphicComponentsContext } from "../../../providers/graphic-components-provider";
import { DevicePointMapping } from "../../../../types/draggable-component";

interface Props {
  points: Points;
}

interface Points {
  [device: string]: {
    [deviceType: string]: Array<string>;
  };
}

const InputDropdown = ({ points }: Props) => {
  let isDropdownOpen = false;
  const { selectedComponent } = useSelectorContext();
  const { components, setComponents } = useGraphicComponentsContext();
  const [buttonLabel, setButtonLabel] = useState<string | null>(
    selectedComponent?.devicePointMapping?.pointName || null
  );

  const mapPointToComponent = (
    device: string,
    deviceType: string,
    pointName: string
  ): void => {
    const mapping: DevicePointMapping = {
      device,
      deviceType,
      pointName,
    };
    toggleDropdown();
    setButtonLabel(pointName);
    updateDevicePointMapping(selectedComponent!.id, mapping);
  };

  const updateDevicePointMapping = useCallback(
    (id: string, devicePointMapping: DevicePointMapping) => {
      setComponents(
        update(components, {
          [id]: {
            $merge: { devicePointMapping },
          },
        })
      );
    },
    [components]
  );

  useEffect(() => {
    const dropdownButton = document.getElementById("dropdown-button");
    const dropdownMenu = document.getElementById("dropdown-menu");
    window.addEventListener("click", (event) => {
      if (
        !dropdownButton!.contains(event.target as Node)
        // !dropdownMenu!.contains(event.target as Node)
      ) {
        dropdownMenu!.classList.add("hidden");
        isDropdownOpen = false;
      }
    });
  });

  useEffect(() => {
    setButtonLabel(selectedComponent?.devicePointMapping?.pointName || null);
  }, [selectedComponent?.id]);

  const toggleDropdown = () => {
    isDropdownOpen = !isDropdownOpen;
    const dropdownMenu = document.getElementById("dropdown-menu");
    if (isDropdownOpen) {
      dropdownMenu!.classList.remove("hidden");
    } else {
      dropdownMenu!.classList.add("hidden");
    }
  };

  return (
    <div className="relative inline-block text-left mb-2 w-full">
      <button
        onClick={toggleDropdown}
        id="dropdown-button"
        className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
      >
        {buttonLabel ? buttonLabel : "Assign device point"}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-5 h-5 ml-2 -mr-1"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        id="dropdown-menu"
        className="origin-top-left absolute left-0 mt-2 w-full rounded-md shadow-l bg-white ring-1 ring-black ring-opacity-5 hidden z-50 max-h-[200px] overflow-scroll shadow-lg border border-sky-800"
      >
        {Object.keys(points).map((device) =>
          Object.keys(points[device]).map((deviceType) => (
            <div
              key={deviceType}
              className="py-2 p-2 divide-y divide-gray-300"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="dropdown-button"
            >
              <div className="text-xs font-semibold py-2 text-gray-900">
                <div>
                  {device} - {deviceType}
                </div>
              </div>
              <ul>
                {points[device][deviceType].map((point) => (
                  <li key={point}>
                    <a
                      href="#"
                      className="!no-underline block py-2 text-xs hover:bg-gray-100 dark:hover:bg-gray-100 rounded-sm"
                      onClick={() =>
                        mapPointToComponent(device, deviceType, point)
                      }
                    >
                      {point}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default InputDropdown;
