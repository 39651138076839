import React, { Component, Fragment } from "react";

import {
    Row,
    Col,
    Button,
    Container,
    Card,
    Dropdown,
    ButtonGroup,
    ProgressBar
  } from "react-bootstrap";
  import ReactApexChart from 'react-apexcharts';
  import { ApexOptions } from 'apexcharts';

import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import PerfectScrollbar from "react-perfect-scrollbar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CountUp from "react-countup";

import bg1 from "../../../../../assets/utils/images/dropdown-header/abstract2.jpg";

import classnames from "classnames";
import { BuildingState } from '../../../../redux/types/building.type';

import {
  ResponsiveContainer,
  AreaChart,
  Tooltip,
  XAxis,
  CartesianGrid,
  Area,
} from "recharts";

const data55 = [
  { name: "Audi", uv: 4000, pv: 2400, amt: 2400 },
  { name: "BMW", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mercedes", uv: 2000, pv: 9800, amt: 2290 },
  { name: "Renault", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Opel", uv: 1890, pv: 4800, amt: 2181 },
];
type Props = {
    buildings: BuildingState;
    series:{name:string, data:number[]}[]
  };

  type State = {
    activeTab: number,
  };

export default class IncomeReport1 extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);

    this.state = {
      activeTab: 1,
    };
    this.unsubscribe = () => { };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.buildings !== prevProps.buildings) {
        if (this.unsubscribe) {
            this.unsubscribe();
        }
    }
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
  }
  }

  generateTwoHourlyCategories(): string[] {
    const categories: string[] = [];
    const now = new Date();
  
    // Set minutes, seconds, and milliseconds to zero
    now.setMinutes(0, 0, 0);
  
    // Calculate the starting hour, rounding down to the nearest even hour
    const startHour = (now.getHours() - (now.getHours() % 2))+1;
  
    // Loop back in two-hour increments
    for (let i = 0; i <= 24; i++) {
      const date = new Date(now.getTime());
      date.setHours(startHour - i);
  
      const hours = String(date.getHours()).padStart(2, '0');
      const timeStr = `${hours}:00`;
      categories.unshift(timeStr);
    }
  
    return categories;
  }

  render() {

     

   const series = [
    {
      name: 'Unit 1a',
      data: [1, 2, 2, 3, 5, 5],
    },
    {
      name: 'Unit 1b',
      data: [1, 1, 2, 3, 3, 3],
    },
    {
      name: 'Unit 1c',
      data: [2, 2, 3, 3, 2, 5],
    },
    {
      name: 'Unit 2a',
      data: [1, 1, 1, 2, 3, 2],
    },
    {
      name: 'Unit 2b',
      data: [1, 2, 2, 3, 4, 3],
    },
    {
      name: 'Unit 2c',
      data: [1, 3, 2, 4, 3, 4],
    },
  ];
  
    const options: ApexOptions = {
      chart: {
        type: 'bar', // TypeScript now knows this is the literal 'bar'
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        categories: this.generateTwoHourlyCategories(),
      },
      legend: {
        position: 'top',
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return `${val} kWh`;
          },
        },
      },
    };
    return (
      <Fragment>
        <Card.Header className="rm-border">
          <div>
            <h5 className="menu-header-title text-capitalize text-primary">
              Day Power Total
            </h5>
          </div>
          <div className="btn-actions-pane-right text-capitalize">
          </div>
        </Card.Header>
        <Card.Body>
          <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
            <ResponsiveContainer width="100%" height={250}>
            <ReactApexChart
              options={options}
              series={this.props.series}
              type="bar"
              height={350}
            />
            </ResponsiveContainer>
          </div>
        </Card.Body>
        {/* <Card.Body>
              <h5 className="menu-header-title">Target Sales</h5>
              <h6 className="menu-header-subtitle opacity-6">
                Total performance for this month
              </h6>
              <Row className="mt-3">
                <Col md="6" sm="12">
                  <Card className="card-border mb-sm-3 mb-md-0 border-light no-shadow">
                    <div className="widget-content">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">Orders</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers line-height-1 text-primary">
                              <CountUp start={0} end={452} separator="" decimals={0}
                                decimal="." prefix="" useEasing={false} suffix=""/>
                            </div>
                          </div>
                        </div>
                        <div className="widget-progress-wrapper mt-1">
                          <ProgressBar className="progress-bar-xs" color="success" now={78}/>
                          <div className="progress-sub-label">
                            <div className="sub-label-left">Monthly Target</div>
                            <div className="sub-label-right">100%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col md="6" sm="12">
                  <Card className="card-border border-light no-shadow">
                    <div className="widget-content">
                      <div className="widget-content-outer">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">Income</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers line-height-1 text-success">
                              <CountUp start={0} end={3456} separator="" decimals={0} decimal="."
                                prefix="$" useEasing={false} suffix=""/>
                            </div>
                          </div>
                        </div>
                        <div className="widget-progress-wrapper mt-1">
                          <ProgressBar className="progress-bar-xs progress-bar-animated" color="danger" now={23}/>
                          <div className="progress-sub-label">
                            <div className="sub-label-left">Monthly Target</div>
                            <div className="sub-label-right">100%</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
        </Card.Body> */}
      </Fragment>
    );
  }
}
