import React from 'react';
import './timeLineItem.css';

interface TimelineItemProps {
  data: {
    title: string;
    description: string;
    date: string;
    // Additional properties for positioning based on time
    startHour: number;
    endHour: number;
  };
}

const TimelineItem: React.FC<TimelineItemProps> = ({ data }) => {
  const { title, description, date, startHour, endHour } = data;
  
  // The timeline starts at 6 AM; calculate hours from 6 AM
  const timelineStartHour = 3;
  
  // Calculate left position based on start hour, assuming 500px total width
  const hourWidth = 500 / 24;
  const leftPositionPx = hourWidth * (startHour - timelineStartHour);
  const durationWidthPx = hourWidth * (endHour - startHour);
  
  // Convert pixel values to percentage of the .timeline-container width
  const leftPosition = (leftPositionPx / 500) * 100;
  const durationWidth = (durationWidthPx / 500) * 100;

  function formatHour(hour: number): string {
    if (hour === 0) {
      return '12 AM';
    } else if (hour < 12) {
      return `${hour} AM`;
    } else if (hour === 12) {
      return '12 PM';
    } else {
      return `${hour - 12} PM`;
    }
  }

  return (
<div className="timeline-item" style={{ left: `${leftPosition}%`, width: `${durationWidth}%`, top: '50%', transform: 'translateY(-50%)' }}>
  <div className="timeline-content">
    <h2>{title}</h2>
    <p>{description}</p>
    <span className="date">{formatHour(startHour)} to {formatHour(endHour)}</span>
  </div>
  {/* Pop-up box */}
  <div className="pop-up-box">
    <h2>{title}</h2>
    <p>{description}</p>
    <p>From: {formatHour(startHour)} To: {formatHour(endHour)}</p>
  </div>
</div>
  );
};

export default TimelineItem;
