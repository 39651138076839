import React, { Fragment } from "react";
import cx from "classnames";

import { CSSTransition, TransitionGroup } from "react-transition-group";

import HeaderLogo from "../AppLogo";

import SearchBox from "./Components/SearchBox";
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";

import HeaderDots from "./Components/HeaderDots";

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../redux/types/store.type';

import {ThemeOptionsState} from "../../redux/types/themeOptions.type";
import firebase from "firebase/compat/app";

interface OwnProps {
    user: firebase.User | null
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState
  };

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;


const Header: React.FC<Props> = ({ ThemeOptions, user }) => {
    const nodeRef = React.useRef(null);
    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    nodeRef={nodeRef}
                    classNames="HeaderAnimation"
                    timeout={{
                    appear: 1500,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div
                    ref={nodeRef}
                    className={cx("app-header", ThemeOptions.headerBackgroundColor, {
                        "header-shadow": ThemeOptions.enableHeaderShadow,
                    })}
                    >
                        <HeaderLogo user={user} setEnableClosedSidebar={() => undefined} setEnableMobileMenu={() => undefined} setEnableMobileMenuSmall={() => undefined}/>
                        <div className={cx("app-header__content", {
                            "header-mobile-open": ThemeOptions.enableMobileMenuSmall,
                            })}>
                            <div className="app-header-left">
                                <SearchBox />
                                {/* <MegaMenu /> */}
                            </div>
                            <div className="app-header-right">
                                <HeaderDots />
                                <UserBox user={user}/>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        
        </>
    );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <Header {...props} />;
});