export function createHumanTimestamp(isoTimestamp: string): string {
    const year = isoTimestamp.substring(0, 4);
    const month = isoTimestamp.substring(5, 7);
    const day = isoTimestamp.substring(8, 10);
    const hour = isoTimestamp.substring(11, 13);
    const minute = isoTimestamp.substring(14, 16);
    return `${day}/${month}/${year} ${hour}:${minute}`;
  }

export function secondsToIso(seconds:number): string {
    const tempDate = new Date(seconds * 1000)
    return tempDate.toISOString();
}

export function isDeviceOnline(deviceDate:Date): boolean {
    const tenMinutesAgo = new Date(Date.now() - 60 * 24 * 60 * 1000);
    return deviceDate > tenMinutesAgo;
  }

export function  calculateLastSeen(dateMillis:number):[number, number, number]{
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate.getTime() - dateMillis;
    // Convert the difference to days, hours, and minutes
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    const differenceInHours = Math.floor((differenceInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const differenceInMinutes = Math.floor((differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    return [differenceInDays, differenceInHours, differenceInMinutes]
  }