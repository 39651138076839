import { Component } from "react";
import TutorialDataService from "../firebase/services/tutorial.service";
import Tutorial from "./tutorial.component";
import ITutorialData from '../firebase/types/tutorial.type';
import firebase from "firebase/compat/app";
import AddTutorial from "./add-tutorial.component";

type Props = {
  user: firebase.User | null
};

type State = {
  tutorials: Array<ITutorialData>;
  currentTutorial: ITutorialData | null;
  currentIndex: number;
};

export default class TutorialsList extends Component<Props, State> {
  unsubscribe: () => void;

  constructor(props: Props) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.onDataChange = this.onDataChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
    };

    this.unsubscribe = () => { };
  }

  componentDidMount() {
    this.unsubscribe = TutorialDataService.getAll().orderBy("title", "asc").onSnapshot(this.onDataChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onDataChange(items: any) {
    let tutorials = new Array<ITutorialData>();

    items.forEach((item: any) => {
      let id = item.id;
      let data = item.data();
      tutorials.push({
        id: id,
        title: data.title,
        description: data.description,
        published: data.published,
      });
    });

    this.setState({
      tutorials: tutorials,
    });
  }

  refreshList() {
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial: ITutorialData, index: number) {
    this.setState({
      currentTutorial: tutorial,
      currentIndex: index,
    });
  }

  render() {
    const { tutorials, currentTutorial, currentIndex } = this.state;

    return (
      <>
      {this.props.user ? (
      <div className="list row">
        <AddTutorial/>
        <div className="col-md-6">
          <h4>Tutorials List</h4>

          <ul className="list-group">
            {tutorials &&
              tutorials.map((tutorial, index) => (
                <li
                  className={
                    "list-group-item " +
                    (index === currentIndex ? "active" : "")
                  }
                  onClick={() => this.setActiveTutorial(tutorial, index)}
                  key={index}
                >
                  {tutorial.title}
                </li>
              ))}
          </ul>
        </div>
        <div className="col-md-6">
          {currentTutorial ? (
            <Tutorial
              tutorial={currentTutorial}
              refreshList={this.refreshList}
            />
          ) : (
            <div>
              <br />
              <p>Please click on a Tutorial...</p>
            </div>
          )}
        </div>
      </div>
      ) : (
        <p className="text-center">Tutorials Access denied</p>
      )}
      </>
    );
  }
}