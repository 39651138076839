import React, { Fragment, Component, useState } from "react";

import { useNavigate } from 'react-router-dom';

import Slider from "react-slick";

import bg1 from "../../../assets/utils/images/originals/city.jpg";
import bg2 from "../../../assets/utils/images/originals/citydark.jpg";
import bg3 from "../../../assets/utils/images/originals/citynights.jpg";

import { Col, Row, Button, Form } from "react-bootstrap";

import { auth } from "../../../components/firebase";
import firebase from "firebase/app";

interface ErrorType {
  message: string;
  code?: string;
}

type Props = {
};

type State = {
  email: string;
  password: string;
  error: ErrorType | null;
};

const INITIAL_STATE = {
  email: '',
  password: '',
  error: {message:'', code:''}
};

const ERROR_CODE_ACCOUNT_EXISTS = "email-already-in-use";
const ERROR_MSG_ACCOUNT_EXISTS = "An account with this email already exists.";


const LoginBase: React.FC<Props> = () => {
  const [state, setState] = useState(INITIAL_STATE);
  const navigate = useNavigate();

  const onSubmit = async () => {
    const { email, password } = state;
    try {
      await auth.signInWithEmailAndPassword(
        email,
        password
      )
      .then(() => {
        setState({ ...INITIAL_STATE });
        navigate("/dashboards");
        // navigate("/dashboards/fcu");
      })
      .catch((error: firebase.FirebaseError) => {
        let errorMessage = error.message;
        let errorCode = error.code;
      
        if (errorCode === `auth/${ERROR_CODE_ACCOUNT_EXISTS}`) {
          errorMessage = ERROR_MSG_ACCOUNT_EXISTS;
        }
      
        setState({ ...state, error: { message: errorMessage, code: errorCode } });
      })
    } catch (error) {
      console.error(error);
    }
  };
  
  //need to be smarter about using this one
  // onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, email: event.target.value });
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setState({ ...state, password: event.target.value });
  };
  
  const { email, password, error } = state;

  const isInvalid = password === '' || email === '';

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    initialSlide: 0,
    autoplay: true,
    adaptiveHeight: true,
  };
  return (
    <div id="sign_in_form" className="margin-auto">
      <>
        <div className="h-100">
          <Row className="h-100 no-gutters">
            <Col lg="4" className="d-none d-lg-block">
              <div className="slider-light">
                <Slider {...settings}>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-plum-plate">
                    <div className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg1 + ")",
                      }}/>
                    <div className="slider-content">
                      <h3>MSI Project Portal</h3>
                      <p>
                      Access critical information and data remotely can help construction professionals make informed decisions, reducing downtime and minimizing the risk of costly errors. This can ultimately lead to increased efficiency and productivity, resulting in cost savings and a positive return on investment.
                      </p>
                      <p>
                      Benefits of fault detection and alarming functionality, deploying our MSI portal for Thanium Nine can also provide a significant return on investment for construction professionals. By logging onto the portal, users can easily and quickly view project completion status, as well as monitor plant operation status and system alarms in real-time.
                      </p>
                      <p>
                      Facilitate proactive maintenance, identifying potential issues before they become major problems. This proactive approach can save on repair costs and prevent costly production downtime. By having a centralized portal for monitoring and maintenance, construction professionals can streamline their operations and reduce unnecessary costs.
                      </p>
                      <p>
                      Provide both immediate and long-term benefits, helping construction professionals maximize their return on investment while improving the overall efficiency and effectiveness of their operations.
                      </p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                    <div className="slide-img-bg"
                      style={{
                        backgroundImage: "url(" + bg3 + ")",
                      }}/>
                    <div className="slider-content">
                      <h3>Semantic Modelling</h3>
                      <p>
                      Using Brick and Haystack schema technology can provide significant return on investment for businesses operating on Thanium Nine. By utilizing semantic tagging schemas, businesses can achieve a more efficient and streamlined operation by providing a better understanding of the interrelationships between systems and their respective sensors.
                      </p>
                      <p>
                      Optimize building systems, resulting in reduced energy consumption and costs. With the ability to accurately monitor and control building systems in real-time, businesses can achieve a more sustainable and cost-effective operation.
                      </p>
                      <p>
                      Significant benefits in terms of maintenance and upkeep. By providing a clear understanding of the interdependencies between systems and sensors, businesses can identify and address issues in a more timely and efficient manner, reducing the risk of system failures and costly downtime.
                      </p>
                      <p>
                      Using Brick and Haystack schema can provide a solid return on investment for businesses operating on Thanium Nine, by improving the efficiency and sustainability of their operations while reducing costs and minimizing downtime.
                      </p>
                    </div>
                  </div>
                  <div className="h-100 d-flex justify-content-center align-items-center bg-sunny-morning">
                    <div className="slide-img-bg opacity-6"
                      style={{
                        backgroundImage: "url(" + bg2 + ")",
                      }}/>
                    <div className="slider-content">
                      <h3>Artificial Intelligence Machine Learning</h3>
                      <p>
                      Use of Artificial Intelligence and Machine Learning in building management systems has proven to be an excellent investment for our clients. By developing bespoke cloud-integrated Neural Networks, we are able to optimize mechanical, electrical, and public health systems in buildings. Our team of experts consults with each client to tailor the Neural Networks to their specific needs, resulting in significant improvements in energy efficiency and cost savings.
                      </p>
                      <p>
                      The use of Neural Networks such as robust MPC, linear MPC, lead-lag, reinforcement learning, explore vs exploit, leader-follower, graph theoretic control, iterative learning control, and extremum-seeking allows for efficient and effective building management. These Neural Networks can analyze large amounts of data in real-time and make complex decisions based on the information, allowing for optimal control of building systems. This results in improved comfort levels for occupants, reduced energy consumption, and cost savings.
                      </p>
                      <p>
                      The return on investment for our clients is evident through reduced energy bills, decreased maintenance costs, and extended equipment life. Our Artificial Intelligence and Machine Learning technology allows for predictive maintenance, identifying issues before they become critical, and preventing expensive repairs. In addition, by optimizing building systems, we can reduce energy consumption, resulting in significant cost savings. Overall, our clients can expect a significant return on their investment by implementing Thanium Nine's Artificial Intelligence and Machine Learning solutions in their building management systems.
                      </p>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center">
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                <div className="app-logo" />
                <h4 className="mb-0">
                  <div>Welcome back,</div>
                  <span>Please sign in to your account.</span>
                </h4>
                <Row className="divider" />
                <div>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label htmlFor="exampleEmail">Email</Form.Label>
                          <Form.Control type="email" name="email" id="exampleEmail" value={email} onChange={onChangeEmail} placeholder="Email here..."/>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group>
                          <Form.Label htmlFor="examplePassword">Password</Form.Label>
                          <Form.Control type="password" name="password" id="examplePassword" value={password} onChange={onChangePassword} placeholder="Password here..."/>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <Form.Check type="checkbox" name="check" id="exampleCheck" />
                      <Form.Label htmlFor="exampleCheck">
                        Keep me logged in
                      </Form.Label>
                    </Form.Group>
                    <Row className="divider" />
                    <Form.Group>
                      <div className="d-flex align-items-center">
                        <div className="ml-auto">
                          <a href="https://colorlib.com/" onClick={(e) => e.preventDefault()} className="btn-lg btn btn-link" >
                            Recover Password
                          </a>{" "}
                          <Button color="primary" size="lg" disabled={isInvalid} onClick={onSubmit} type="button">
                            Login to Dashboard
                          </Button>
                        </div>
                      </div>
                    </Form.Group>
                    {error && <p>{error.message}</p>}
                  </Form>
                </div>
              </Col>
            </Col>
          </Row>
        </div>
      </>
    </div>
  );
}

export default LoginBase;