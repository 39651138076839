import * as actionTypes from "../types/building.action.type"
import { BuildingAction, BuildingState} from "../types/building.type"

const initialState: BuildingState = {
    "UK-LONDON-WENLOCK-22":{
        "Level-01":{
            "registries":{
                "device":["AHU-001"]
            }
        }
    }
};

const BuildingsReducer = (
    state: BuildingState = initialState, 
    action: BuildingAction
    ): BuildingState => {
    switch(action.type){
        case actionTypes.ADD_BUILDING:
            return {
                ...state,
                [action.building]: {
                    ...state[action.building],
                    [action.level]: {
                        registries: {
                            device: action.devices
                        }
                    }
                }
            };
        default:
            return state;
    }
};

export default BuildingsReducer;