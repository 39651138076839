import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';
import { BuildingState } from '../../../redux/types/building.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../../components/redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
    Buildings: BuildingState;
};

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

const FCUDashboard: React.FC<Props> = ({Buildings}) => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Fan Coil Unit"
                        subheading="Fan Coil Units (FCUs) are integral components designed to regulate the internal environment of designated zones. Operating on the principles of heat exchange, an FCU consists of a fan and a heat exchanger or coil. Depending on the desired ambient conditions, the FCU circulates either chilled or heated water through its coil. The fan then propels air over this coil, facilitating the transfer of heat between the water and the air. This conditioned air is subsequently distributed to the intended zones. Integrated with the BMS, FCUs provide localized climate control, optimizing energy consumption by operating based on specific zone requirements, and contributing to the overall energy efficiency and occupant comfort within a building."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 buildings={Buildings}/>
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

// // const mapStateToProps = (state: StoreState.All): ArticleState => {
// //     return {
// //       state: state
// //     };
// //   };

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
        Buildings: state.Buildings
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <FCUDashboard {...props} />;
});