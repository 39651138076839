import { useEffect, useRef } from "react";
import update from "immutability-helper";
import { useSettingsContext } from "../providers/settings-provider";

interface Props {
  displayDropdown: boolean;
  closeDropdown: Function;
}

function useOutsideHTMLElementClick(
  ref: React.RefObject<HTMLDivElement>,
  callback: Function
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        // alert("You clicked outside of me!");
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const SettingsDropdown = ({ displayDropdown, closeDropdown }: Props) => {
  const { settings, setSettings } = useSettingsContext();

  const ref = useRef<HTMLDivElement>(null);
  useOutsideHTMLElementClick(ref, closeDropdown);

  async function setSettingValue(settingName: string, value: any) {
    await setSettings(
      update(settings, {
        [settingName]: { $set: value },
      })
    );
  }

  return displayDropdown ? (
    <div
      ref={ref}
      className=" z-30 w-60 bg-white rounded shadow absolute bottom-20 right-0"
    >
      <ul
        className="p-3 space-y-1 text-sm text-gray-700"
        aria-labelledby="dropdownHelperButton"
      >
        <li>
          <div className="flex p-2 rounded hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                id="helper-checkbox-1"
                aria-describedby="helper-checkbox-text-1"
                type="checkbox"
                value="snapToGrid"
                checked={settings.snapToGrid}
                onChange={() =>
                  setSettingValue("snapToGrid", !settings.snapToGrid)
                }
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
              />
            </div>
            <div className="ml-2 text-sm">
              <label
                htmlFor="helper-checkbox-1"
                className="font-medium text-gray-900"
              >
                <div>Snap to grid</div>
                <p
                  id="helper-checkbox-text-1"
                  className="text-xs font-normal text-gray-500"
                >
                  Lines up all components to the closest intersection of the
                  grid.
                </p>
              </label>
            </div>
          </div>
        </li>
        <li>
          <div className="flex p-2 rounded hover:bg-gray-100">
            <div className="flex items-center h-5">
              <input
                id="helper-checkbox-2"
                aria-describedby="helper-checkbox-text-2"
                type="checkbox"
                value="componentDragTransparency"
                checked={settings.componentDragTransparency}
                onChange={() =>
                  setSettingValue(
                    "componentDragTransparency",
                    !settings.componentDragTransparency
                  )
                }
                className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
              />
            </div>
            <div className="ml-2 text-sm">
              <label
                htmlFor="helper-checkbox-2"
                className="font-medium text-gray-900"
              >
                <div>Transparency on drag</div>
                <p
                  id="helper-checkbox-text-2"
                  className="text-xs font-normal text-gray-500"
                >
                  See the component previous position as you drag.
                </p>
              </label>
            </div>
          </div>
        </li>
      </ul>
    </div>
  ) : null;
};

export default SettingsDropdown;
