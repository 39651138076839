import sideBar6 from '../../../assets/utils/images/sidebar/city1.jpg';

import {ThemeOptionsState, ThemeOptionsAction} from "../types/themeOptions.type";

import * as themeOptionTypes from "../types/themeOptions.action.type"

const initialState: ThemeOptionsState = {
    backgroundColor: '',
    headerBackgroundColor: '',
    enableMobileMenu: false,
    enableMobileMenuSmall: false,
    enableBackgroundImage: false,
    enableClosedSidebar: false,
    enableFixedHeader: true,
    enableHeaderShadow: true,
    enableSidebarShadow: true,
    enableFixedFooter: true,
    enableFixedSidebar: true,
    colorScheme: 'gray',
    backgroundImage: sideBar6,
    backgroundImageOpacity: 'opacity-06',
    enablePageTitleIcon: true,
    enablePageTitleSubheading: true,
    enablePageTabsAlt: true
  }

const reducer = (    
    state: ThemeOptionsState = initialState,
    action: ThemeOptionsAction  ): ThemeOptionsState => {
    switch (action.type) {
        case themeOptionTypes.SET_ENABLE_BACKGROUND_IMAGE:
            return {
                ...state,
                enableBackgroundImage: action.enableBackgroundImage
            };

        case themeOptionTypes.SET_ENABLE_FIXED_HEADER:
            return {
                ...state,
                enableFixedHeader: action.enableFixedHeader
            };

        case themeOptionTypes.SET_ENABLE_HEADER_SHADOW:
            return {
                ...state,
                enableHeaderShadow: action.enableHeaderShadow
            };

        case themeOptionTypes.SET_ENABLE_SIDEBAR_SHADOW:
            return {
                ...state,
                enableSidebarShadow: action.enableSidebarShadow
            };

        case themeOptionTypes.SET_ENABLE_PAGETITLE_ICON:
            return {
                ...state,
                enablePageTitleIcon: action.enablePageTitleIcon
            };

        case themeOptionTypes.SET_ENABLE_PAGETITLE_SUBHEADING:
            return {
                ...state,
                enablePageTitleSubheading: action.enablePageTitleSubheading
            };

        case themeOptionTypes.SET_ENABLE_PAGE_TABS_ALT:
            return {
                ...state,
                enablePageTabsAlt: action.enablePageTabsAlt
            };

        case themeOptionTypes.SET_ENABLE_FIXED_SIDEBAR:
            return {
                ...state,
                enableFixedSidebar: action.enableFixedSidebar
            };

        case themeOptionTypes.SET_ENABLE_MOBILE_MENU:
            return {
                ...state,
                enableMobileMenu: action.enableMobileMenu
            };

        case themeOptionTypes.SET_ENABLE_MOBILE_MENU_SMALL:
            return {
                ...state,
                enableMobileMenuSmall: action.enableMobileMenuSmall
            };

        case themeOptionTypes.SET_ENABLE_CLOSED_SIDEBAR:
            return {
                ...state,
                enableClosedSidebar: action.enableClosedSidebar
            };

        case themeOptionTypes.SET_ENABLE_FIXED_FOOTER:
            return {
                ...state,
                enableFixedFooter: action.enableFixedFooter
            };

        case themeOptionTypes.SET_BACKGROUND_COLOR:
            return {
                ...state,
                backgroundColor: action.backgroundColor
            };

        case themeOptionTypes.SET_HEADER_BACKGROUND_COLOR:
            return {
                ...state,
                headerBackgroundColor: action.headerBackgroundColor
            };

        case themeOptionTypes.SET_COLOR_SCHEME:
            return {
                ...state,
                colorScheme: action.colorScheme
            };

        case themeOptionTypes.SET_BACKGROUND_IMAGE:
            return {
                ...state,
                backgroundImage: action.backgroundImage
            };

        case themeOptionTypes.SET_BACKGROUND_IMAGE_OPACITY:
            return {
                ...state,
                backgroundImageOpacity: action.backgroundImageOpacity
            };
            default:
    }
    return state;
}

export default reducer


export const sEnableClosedSidebar = (enableClosedSidebar: boolean) => {
    return {
      type: themeOptionTypes.SET_ENABLE_CLOSED_SIDEBAR,
      enableClosedSidebar,
    };
  };

  export const sEnableMobileMenu = (enableMobileMenu: boolean) => {
    return {
      type: themeOptionTypes.SET_ENABLE_MOBILE_MENU,
      enableMobileMenu,
    };
  };

  export const sEnableMobileMenuSmall = (enableMobileMenuSmall: boolean) => {
    return {
      type: themeOptionTypes.SET_ENABLE_MOBILE_MENU_SMALL,
      enableMobileMenuSmall,
    };
  };