import { useState } from "react";
import { ColorResult, TwitterPicker, TwitterPickerProps } from "react-color";

interface Props extends TwitterPickerProps {
  title: string;
  color: string;
  onColorChange: (color: ColorResult) => void;
  left?: string;
  right?: string;
}

const COLORS_SET = [
  "#FF6900",
  "#FCB900",
  "#00D084",
  "#0693E3",
  "#ABB8C3",
  "#EB144C",
  "#F78DA7",
  "#9900EF",
  "#374151",
];

const ColorPicker = ({
  title,
  color,
  onColorChange,
  triangle,
  colors,
  left,
  right,
}: Props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const handleColorPickerClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div className="flex flex-col w-32 relative">
      <label
        htmlFor="custom-input-number"
        className="w-full mb-2 text-xs font-medium text-gray-500"
      >
        {title}
      </label>
      <div
        className="p-1 h-8 w-full rounded border shadow-sm inline-block cursor-pointer"
        onClick={handleColorPickerClick}
      >
        <div
          className="h-full w-full"
          style={{
            backgroundColor: color,
          }}
        />
      </div>
      {displayColorPicker && (
        <div className={`absolute z-10 top-[60px] ${left} ${right}`}>
          <div className="fixed inset-0" onClick={() => handleClose()} />
          <TwitterPicker
            triangle={triangle}
            color={color}
            colors={COLORS_SET}
            onChangeComplete={onColorChange}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
