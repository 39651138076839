import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux'
import { ThunkAction } from 'redux-thunk'
import logger from 'redux-logger';
import rootReducer from "./reducers"
import { StoreState } from './types/store.type';

// import { createStore, applyMiddleware, Store } from "redux"
// import thunk from "redux-thunk"
// import allReducers from "./components/redux/reducers"
// import {ArticleState, ArticleAction, DispatchType} from "./components/redux/types/article.type"
// import {ThemeOptionsState, ThemeOptionsAction} from "./components/redux/types/themeOptions.type"
// const store: Store<ArticleState & ThemeOptionsState, ArticleAction | ThemeOptionsAction> & {
//   dispatch: DispatchType
// } = createStore(allReducers, applyMiddleware(thunk))

const middleware = [...getDefaultMiddleware(), logger];

const store = configureStore({
    reducer: rootReducer,
    middleware
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch()
export type AppThunk = ThunkAction<void, StoreState.All, unknown, Action>

export default store