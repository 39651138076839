import firebase from "..";
// import {IChlData} from "../types/Chl.type"

const db = firebase.collection("Buildings/UK-LONDON-MARSHGATE-STADIUM/Level-01/registries/device/ACC-2/types/");

class ChlDataService {
  getAll() {
    return db;
  }
  
  getDevice(device:string) {
    return firebase.collection("Buildings/UK-LONDON-MARSHGATE-STADIUM/Level-01/registries/device/" + device + "/types/");
  }

  getDeviceTimeseries(device:string, start:Date, end:Date) {
    return firebase.collection('/Buildings/UK-LONDON-MARSHGATE-STADIUM/Level-01/registries/device/' + device + '/history/').where('timestamp', '>=', start).where('timestamp', '<=', end);
  }

  // create(tutorial: ITutorialData) {
  //   return db.add(tutorial);
  // }

  // update(id: string, value: any) {
  //   return db.doc(id).update(value);
  // }

  // delete(id: string) {
  //   return db.doc(id).delete();
  // }
}

export default new ChlDataService();
