import { Box } from './box';
import { Label } from './label';
import { HLabelBox } from './hlabelbox';
import { VLabelBox } from './vlabelbox';
import { ImageBox } from './imagebox';

import './boxes.css';

export const GraphicComponents = {
  Box,
  Label,
  HLabelBox,
  VLabelBox,
  ImageBox
};
