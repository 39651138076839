import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { ItemMap } from "../../types/item-map";
import firestore from "../../../../../../firebase";

interface Props {
  children?: React.ReactNode;
  graphicName: string;
}

interface ContextProps {
  components: ItemMap;
  setComponents: Dispatch<SetStateAction<ItemMap>>;
  saveComponentsToDB: () => Promise<void>;
}

const GraphicComponentsContext = createContext<ContextProps>({
  components: {},
  setComponents: () => {},
  saveComponentsToDB: () => new Promise((resolve, reject) => {}),
});

const GraphicComponentsProvider: FC<Props> = ({ children, graphicName }) => {
  const [components, setComponents] = useState<ItemMap>({});

  const saveComponentsToDB = async () => {
    let componentsCopy = {};
    Object.keys(components).forEach((key) => {
      componentsCopy = {
        ...componentsCopy,
        [key]: {
          label: components[key].label,
          textfield: components[key].textfield,
          top: components[key].top,
          left: components[key].left,
          devicePointMapping: components[key].devicePointMapping,
        },
      };
    });
    return firestore.doc(`graphicMappings/${graphicName}`).set(componentsCopy);
  };

  return (
    <GraphicComponentsContext.Provider
      value={{ components, setComponents, saveComponentsToDB }}
    >
      {children}
    </GraphicComponentsContext.Provider>
  );
};

export const useGraphicComponentsContext = () =>
  useContext(GraphicComponentsContext);

export default GraphicComponentsProvider;
