import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactECharts from 'echarts-for-react';

import {ICtNetData, ICtNetMetaData, ExtendedICtNetData} from '../../../../firebase/types/ctnet.type';

type Props = {
    item: ExtendedICtNetData;
    percentage:number;
    max_capacity:number;
    current_target:number;
  };
  

const SecondDial: React.FC<Props> = ({ item, percentage, max_capacity, current_target }) => {
  const gaugeHeight = 300; // Adjust as needed
  const scalingFactor = gaugeHeight / 400; // Assuming 400 is your base height

  function roundToTwo(value:number) {
    return(Math.round(value * 100) / 100);
  }

  const gaugeOption = {
    series: [
      {
        type: 'gauge',
        startAngle: 210,
        endAngle: -30,
        axisLine: {
          lineStyle: {
            width: 30 * scalingFactor,
            color: [
              [0.3, '#67e0e3'],
              [0.7, '#37a2da'],
              [1, '#fd666d'],
            ],
          },
        },
        pointer: {
          itemStyle: {
            color: 'auto',
          },
        },
        axisTick: {
          distance: -20 * scalingFactor,
          length: 8 * scalingFactor,
          lineStyle: {
            color: '#fff',
            width: 2 * scalingFactor,
          },
        },
        splitLine: {
          distance: -20 * scalingFactor,
          length: 20 * scalingFactor,
          lineStyle: {
            color: '#fff',
            width: 4 * scalingFactor,
          },
        },
        axisLabel: {
          distance: 25 * scalingFactor,
          fontSize: 12 * scalingFactor,
          color: '#464646',
        },
        detail: {
          valueAnimation: true,
          formatter: '{value}%',
          color: 'auto',
          fontSize: 16 * scalingFactor,
        },
        data: [
          {
            value: percentage,
          },
        ],
      },
    ],
  };

  return (
        <div className="widget-chat-wrapper-outer">
          <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
            <ReactECharts
              option={gaugeOption}
              style={{ height: `${gaugeHeight}px`, width: '100%' }}
            />
          </Row>
          <div style={{ textAlign: 'center', marginTop: '-100px' }}>
            <h4>{roundToTwo(item.total.present_value)} kW</h4>
            <p>Max Capacity {max_capacity} kW</p>
            <p>Current Target {current_target} kW</p>
          </div>
        </div>
  );
};

export default SecondDial;