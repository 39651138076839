import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";


import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

  const makeNewData: IExtendedEnergyData =
      {
        meta: {
          name: { present_value: "Energy" },
          timestamp: { present_value: "2023-03-28T09:30:00Z" },
          alarm: { present_value: "Healthy" },
        },
        metering:[
        {
          name:"Base Building",
          energy_use:{
            percent:{present_value: 77},
            kw:{present_value:569}},
          max_capacity:{present_value: 1670},
          current_target:{present_value: 740}
        },
        {
          name:"House Services",
          energy_use:{
            percent:{present_value: 107},
            kw:{present_value:155}},
          max_capacity:{present_value: 145},
          current_target:{present_value: 145}
        },
         {
          name:"Lifts",
          energy_use:{
            percent:{present_value: 66},
            kw:{present_value:145}},
          max_capacity:{present_value: 220},
          current_target:{present_value: 220}
        },
         {
          name:"CHW",
          energy_use:{
            percent:{present_value: 2},
            kw:{present_value:1}},
          max_capacity:{present_value: 800},
          current_target:{present_value: 55}
        },
        {
          name:"Tenant CCW Plant",
          energy_use:{
            percent:{present_value: 85},
            kw:{present_value:17}},
          max_capacity:{present_value: 110},
          current_target:{present_value: 20}
        },
        {
          name:"HHW Plant",
          energy_use:{
            percent:{present_value: 53},
            kw:{present_value:13}},
          max_capacity:{present_value: 0},
          current_target:{present_value: 24}
        },
         {
          name:"Level 2 Mech",
          energy_use:{
            percent:{present_value: 52},
            kw:{present_value:42}},
          max_capacity:{present_value: 120},
          current_target:{present_value: 80}
        },
         {
          name:"Level 3 Mech",
          energy_use:{
            percent:{present_value: 89},
            kw:{present_value:156}},
          max_capacity:{present_value: 260},
          current_target:{present_value: 175}
        },
        {
          name:"Level 4 Mech",
          energy_use:{
            percent:{present_value: 91},
            kw:{present_value:100}},
          max_capacity:{present_value: 155},
          current_target:{present_value: 110}
        },
        {
          name:"Mezz Mech",
          energy_use:{

            percent:{present_value: 58},
            kw:{present_value:10}},
          max_capacity:{present_value: 18},
          current_target:{present_value: 18}
        },
        {
          name:"Basement Mech",
          energy_use:{
            percent:{present_value: 15},
            kw:{present_value:6}},
          max_capacity:{present_value: 40},
          current_target:{present_value: 40}
        }
        ]
      }

  const chartData = [
    { timestamp: "2021-02-12T06:52:00Z", heating_valve: 20.0, cooling_valve: 70.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:52:30Z", heating_valve: 30.0, cooling_valve: 60.0, supply_temperature: 23.5, return_temperature: 21.3 },
    { timestamp: "2021-02-12T06:53:00Z", heating_valve: 25.0, cooling_valve: 55.0, supply_temperature: 23.5, return_temperature: 21.5 },
    { timestamp: "2021-02-12T06:53:30Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.6 },
    { timestamp: "2021-02-12T06:54:00Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.4 },
    { timestamp: "2021-02-12T06:54:30Z", heating_valve: 27.0, cooling_valve: 40.0, supply_temperature: 22.5, return_temperature: 21.2 },
    { timestamp: "2021-02-12T06:55:00Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:55:30Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 20.9 },
    { timestamp: "2021-02-12T06:56:00Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 22.5, return_temperature: 20.7 },
    { timestamp: "2021-02-12T06:56:30Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:57:00Z", heating_valve: 20.0, cooling_valve: 70.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T06:57:30Z", heating_valve: 30.0, cooling_valve: 60.0, supply_temperature: 23.5, return_temperature: 21.3 },
    { timestamp: "2021-02-12T06:58:00Z", heating_valve: 25.0, cooling_valve: 55.0, supply_temperature: 23.5, return_temperature: 21.5 },
    { timestamp: "2021-02-12T06:58:30Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.6 },
    { timestamp: "2021-02-12T06:59:00Z", heating_valve: 25.0, cooling_valve: 50.0, supply_temperature: 23.0, return_temperature: 21.4 },
    { timestamp: "2021-02-12T06:59:30Z", heating_valve: 27.0, cooling_valve: 40.0, supply_temperature: 22.5, return_temperature: 21.2 },
    { timestamp: "2021-02-12T07:00:00Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 21.0 },
    { timestamp: "2021-02-12T07:00:30Z", heating_valve: 30.0, cooling_valve: 40.0, supply_temperature: 23.0, return_temperature: 20.9 },
    { timestamp: "2021-02-12T07:01:00Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 22.5, return_temperature: 20.7 },
    { timestamp: "2021-02-12T07:01:30Z", heating_valve: 30.0, cooling_valve: 30.0, supply_temperature: 23.0, return_temperature: 21.0 }
  ];

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    container: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: 'red',
      justifyContent: 'center',
      alignItems: 'center',
    },

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
    
  `

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);
  
  type Props = {
    placeholder?: string | null
  };
  
  type State = {
      data: IExtendedEnergyData,
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: []
  };
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {

    constructor(props: Props) {
      super(props);

      this.state = {
        data: makeNewData,
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: []
      };


    }
  
    componentDidMount() {

    }
  
    componentWillUnmount() {

    }

      render() {
        const { data } = this.state;

        const renderMenuItems = (metering: IEnergyData[]) => {
          return metering.map((item: IEnergyData) => {
            const value = item.energy_use.percent.present_value;
            let cardClass = "card mb-3 widget-chart widget-chart2 text-left card-btm-border ";
          
            if (value < 70) {
              cardClass += "card-shadow-secondary border-secondary";
            } else if (value >= 70 && value < 100) {
              cardClass += "card-shadow-warning border-warning";
            } else if (value >= 100) {
              cardClass += "card-shadow-danger border-danger";
            }
            return(
              <Col md="6" xl="4">
                  <div className={cardClass}>
                    <div className="widget-chat-wrapper-outer">
                      <div className="widget-chart-content pt-3 pl-3 pb-1">
                        <h4 className="widget-subheading mb-0 opacity-5">
                          {item.name}
                        </h4>
                      </div>
                      <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                        <ReactGaugeMeter
                          firstColor="green"
                          secondColor="green"
                          thirdColor="orange"
                          value={item.energy_use.percent.present_value}
                          style={{height: '400px', width: '100%'}}
                        />
                        
                      </Row>
                      <div style={{textAlign: "center", marginTop: "-130px"}}>
                      <h4 >{item.energy_use.kw.present_value} kW</h4>
                      <p>Max Capacity {item.max_capacity.present_value} kW</p>
                      <p>Current Target {item.current_target.present_value} kW</p>
                      </div>
                    </div>
                  </div>
                </Col>
            )
          });
        };

        return (
          <>
            <Container fluid>
              <Row> 
                {renderMenuItems(data.metering)}
              </Row>
            </Container> 
          </>
        );
    }
}