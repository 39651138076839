import { SetStateAction } from "react";

interface Props {
  title: string;
  value: string;
  onChange: (event: { target: { value: SetStateAction<string> } }) => void;
  placeholder?: string;
}

const TextInput = ({
  title,
  value,
  placeholder = "Placeholder",
  onChange,
}: Props) => {
  return (
    <div className="mb-2">
      <label
        htmlFor="small-input"
        className="block mb-2 text-xs font-medium text-gray-500"
      >
        {title}
      </label>
      <input
        type="text"
        className="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-xs focus:ring-blue-500 focus:border-blue-500 focus:outline-none"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default TextInput;
