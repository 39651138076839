import React from 'react';
import './ahu_type_1.css';
import ahu_phx from '../images/ahu_phx.png';
import supply_on from '../images/supply_on.gif';
import exhaust_on from '../images/exhaust_on.gif';
import { GraphicComponents } from '../GraphicComponents';

import {IAhuData, ExtendedIAhuData} from '../../../../firebase/types/ahu.type';

type Props = {
  data?: IAhuData;
};

const AHU_TYPE_1: React.FC<Props> = ({data}) => {
  try {
    const isEmpty = (obj: IAhuData): boolean => {
      return Object.keys(obj).length === 0;
    };

    if (data === undefined || data === null || isEmpty(data)
    ) {
      return <h5>Loading...</h5>;
    }
    const sup_press = RoundNumber(data.supply_air_static_pressure_sensor.present_value) +' Pa';
    const sup_press_sp = RoundNumber(data.supply_air_static_pressure_sp.present_value) +' Pa';

    const sup_temp = RoundNumber(data.supply_air_temperature_sensor.present_value) +' °C';
    const sup_temp_sp = RoundNumber(data.supply_air_temperature_sp.present_value) +' °C';

    const flt_press = RoundNumber(data.filter_differential_pressure_sensor.present_value) + ' Pa';
    const flt_sts = data.filter_alarm_status_dirty.present_value;
    const flt_sts_sp = RoundNumber(data.filter_alarm_status_sp.present_value) + ' Pa';

    const htn_valve = RoundNumber(data.heating_valve_percentage_command.present_value) +' %';
    const clg_valve = RoundNumber(data.cooling_valve_percentage_command.present_value) + ' %';

    const fan_enbl = data.supply_fan_run_command.present_value;
    const fan_sts = data.supply_fan_run_status.present_value;
    const fan_speed = RoundNumber(data.supply_fan_speed_percentage_command.present_value) + ' %';

    const phx_off_temp = RoundNumber(data.phx_off_temp_sensor.present_value) +' °C';
    const phx_pos = RoundNumber(data.phx_pos.present_value) +' %';
   
    const exhaust_damper_cmd = data.exhaust_damper_command.present_value;
    const exhaust_damper_end_switch = data.exhaust_damper_end_switch.present_value;

    const exhaust_temp = RoundNumber(data.exhaust_air_temperature_sensor.present_value) +' °C';

    const rtn_press = RoundNumber(data.return_air_static_pressure_sensor.present_value) +' Pa';
    const rtn_press_sp = RoundNumber(data.return_air_static_pressure_sp.present_value) +' Pa';
    const rtn_temp = RoundNumber(data.return_air_temperature_sensor.present_value) + ' °C';

    const ext_fan_enbl = data.extract_fan_run_command.present_value;
    const ext_fan_sts = data.extract_fan_run_status.present_value;
    const ext_fan_speed = RoundNumber(data.extract_fan_speed_percentage_command.present_value) + ' %';

    const pnl_flt_press = RoundNumber(data.panel_filter_differential_pressure_sensor.present_value) + ' Pa';
    const pnl_flt_sts = data.panel_filter_alarm_status_dirty.present_value;
    const pnl_flt_sts_sp = RoundNumber(data.panel_filter_alarm_status_sp.present_value) + ' Pa';

    const phx_on_temp = RoundNumber(data.phx_on_temp_sensor.present_value) +' °C';

    const in_pnl_flt_press = RoundNumber(data.in_panel_filter_differential_pressure_sensor.present_value) + ' Pa';
    const in_pnl_flt_sts = data.in_panel_filter_alarm_status_dirty.present_value;
    const in_pnl_flt_sts_sp = RoundNumber(data.in_panel_filter_alarm_status_sp.present_value) + ' Pa';


    const frst_state = data.frost_state.present_value;
    const off_frost_temp = RoundNumber(data.off_frost_temp_sensor.present_value) +' °C';
    const off_frost_temp_sp = RoundNumber(data.off_frost_temp_sp.present_value) +' °C';
    const frost_coil = RoundNumber(data.frost_coil_command.present_value) + ' %';



    const intake_damper_cmd = data.intake_damper_command.present_value;
    const intake_damper_end_switch = data.intake_damper_end_switch.present_value;

    const intake_temp = RoundNumber(data.intake_air_temperature_sensor.present_value) +' °C';

    // const space_temp = RoundNumber(data.space_air_temperature_sensor.present_value) + ' °C';
    return (
        <>
        <div
          className='titleName'
        >
        <h1>AHU TYPE 1</h1>
        </div>
        <section
          style={{
            backgroundImage: `url(${ahu_phx})`
          }}
          className='ahu_type_1_wrapper'
        >
          <GraphicComponents.ImageBox
          top={290}
          left={610}
          value={fan_enbl}
          imageOn={supply_on}
          imageOff={supply_on}
          />

          <GraphicComponents.ImageBox
          top={185}
          left={835}
          value={ext_fan_enbl}
          imageOn={exhaust_on}
          imageOff={exhaust_on}
          />
          
          <GraphicComponents.VLabelBox
            top={380}
            left={1070}
            label='Supply Pressure'
            value={sup_press}
          />
            <GraphicComponents.Box
            top={460}
            left={1070}
            value={sup_press_sp}
            backgroundColor='green'
            color='white'
          />
          <GraphicComponents.VLabelBox
            top={380}
            left={975}
            label='Supply Temperature'
            value={sup_temp}
          />
             <GraphicComponents.Box
            top={460}
            left={975}
            value={sup_temp_sp}
            backgroundColor='green'
            color='white'
          />
          <GraphicComponents.VLabelBox
           top={400}
           left={560}
           label='Bag Filter DP'
           value={flt_press}
          />
          <GraphicComponents.VLabelBox
            top={455}
            left={560}
            value={flt_sts}
          />
            <GraphicComponents.Box
            top={505}
            left={560}
            value={flt_sts_sp}
            backgroundColor='green'
            color='white'
          />
          <GraphicComponents.VLabelBox
            top={430}
            left={840}
            label='Heating Valve'
            value={htn_valve}
          />
                <GraphicComponents.VLabelBox
            top={430}
            left={680}
            label='Cooling Valve'
            value={clg_valve}
          />
      
      <GraphicComponents.VLabelBox
            top={400}
            left={620}
            label='Supply Fan'
            value={fan_enbl}
          />
          <GraphicComponents.VLabelBox
            top={455}
            left={620}
            value={fan_sts}
          />
          <GraphicComponents.VLabelBox
            top={505}
            left={620}
            value={fan_speed}
          />
            <GraphicComponents.VLabelBox
            top={380}
            left={500}
            label='PHX Off Temp'
            value={phx_off_temp}
          />
        
               <GraphicComponents.VLabelBox
            top={370}
            left={120}
            label='Damper Command'
            value={exhaust_damper_cmd}
          />
          <GraphicComponents.VLabelBox
            top={455}
            left={120}
            label='Damper End Switch'
            value={exhaust_damper_end_switch}
          />
           <GraphicComponents.VLabelBox
            top={380}
            left={10}
            label='Exhaust Temp'
            value={exhaust_temp}
            backgroundColor='white'
          />
    
    <GraphicComponents.VLabelBox
            top={55}
            left={1070}
            label='Return Pressure'
            value={rtn_press}
            backgroundColor='white'
          />
     <GraphicComponents.Box
            top={130}
            left={1070}
            value={rtn_press_sp}
            backgroundColor='green'
            color='white'
          />
           <GraphicComponents.VLabelBox
            top={90}
            left={985}
            label='Return Temp'
            value={rtn_temp}
          />
            <GraphicComponents.VLabelBox
            top={0}
            left={845}
            label='Extract Fan'
            value={ext_fan_enbl}
          />
          <GraphicComponents.VLabelBox
            top={50}
            left={845}
            value={ext_fan_sts}
          />
          <GraphicComponents.VLabelBox
            top={90}
            left={845}
            value={ext_fan_speed}
          />       
      <GraphicComponents.VLabelBox
           top={10}
           left={670}
           label='Ext Flt DP'
           value={pnl_flt_press}
          />
          <GraphicComponents.VLabelBox
            top={60}
            left={670}
            value={pnl_flt_sts}
          />
            <GraphicComponents.Box
            top={110}
            left={670}
            value={pnl_flt_sts_sp}
            backgroundColor='green'
            color='white'
          />
           <GraphicComponents.VLabelBox
            top={65}
            left={455}
            label='PHX On Temp'
            value={phx_on_temp}
          />
            <GraphicComponents.VLabelBox
           top={10}
           left={390}
           label='In Flt DP'
           value={in_pnl_flt_press}
          />
          <GraphicComponents.VLabelBox
            top={60}
            left={390}
            value={in_pnl_flt_sts}
          />
            <GraphicComponents.Box
            top={110}
            left={390}
            value={in_pnl_flt_sts_sp}
            backgroundColor='green'
            color='white'
          />
            <GraphicComponents.VLabelBox
            top={380}
            left={440}
            label='PHX Position'
            value={phx_pos}
            backgroundColor='white'
          />
            <GraphicComponents.VLabelBox
            top={10}
            left={275}
            label='Frost Coil'
            value={off_frost_temp}
          />
            
           <GraphicComponents.VLabelBox
            top={40}
            left={175}
            value={frost_coil}
            label='Frost Coil Command'
          />
          <GraphicComponents.VLabelBox
            top={60}
            left={275}
            value={frst_state}
          />
           <GraphicComponents.Box
            top={110}
            left={275}
            value={off_frost_temp_sp}
            backgroundColor='green'
            color='white'
            />
            <GraphicComponents.VLabelBox
            top={10}
            left={120}
            label='Damper Command'
            value={intake_damper_cmd}
          />
          <GraphicComponents.VLabelBox
            top={98}
            left={120}
            label='Damper End Switch'
            value={intake_damper_end_switch}
          />
           <GraphicComponents.VLabelBox
            top={98}
            left={10}
            label='Intake Temp'
            value={intake_temp}
            backgroundColor='white'
          />
        </section>
        </>
    );
  } catch (exception) {
    console.log(exception);
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <p>Device Graphic not available.</p>
      </div>
    );
  }
};

function RoundNumber(number: number) {
  return Math.round(number * 100) / 100;
}

export default AHU_TYPE_1;
