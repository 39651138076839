import firebase from "..";
import {IAhuData} from "../types/ahu.type"

const db = firebase.collection("Buildings/UK-LONDON-WENLOCK-22/Level-01/registries/device/AHU-001/types/");

class AhuDataService {
  getAll() {
    return db;
  }

  // create(tutorial: ITutorialData) {
  //   return db.add(tutorial);
  // }

  // update(id: string, value: any) {
  //   return db.doc(id).update(value);
  // }

  // delete(id: string) {
  //   return db.doc(id).delete();
  // }
}

export default new AhuDataService();
