import React, { FC } from 'react';

interface VLabelBoxProps {
  top?: number;
  left?: number;
  label?: string;
  value?: number | string;
  backgroundColor?: string;
}

export const VLabelBox: FC<VLabelBoxProps> = ({
  top,
  left,
  label,
  value,
  backgroundColor,
}) => {
  return (
    <div
      className="fcu_type_1_b__box-wrapper"
      style={{ top: top, left: left }}
    >
      <p className="fcu_type_1_b__box-label-text">{label}</p>
      <div
        className="fcu_type_1_b__box-content"
        style={{ backgroundColor: backgroundColor }}
      >
        <p className="fcu_type_1_b__box-value-text">
          {value === undefined ? '...' : String(value)}
        </p>
      </div>
    </div>
  );
};