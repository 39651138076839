import React, { Component, Fragment } from "react";
import { CircleSpinner } from 'react-spinners-kit';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './index.css';
import {DeviceName, CtNetChartDataItem, CtNetChartDataValues, CompareCtNetChartData, CtNetChartData, DateOption, CtNetChartDataMap, PureCtNetChartData} from '../../../redux/types/chartData.type';
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import {HistoryICtNetData} from '../../../firebase/types/ctnet.type';
import { secondsToIso, createHumanTimestamp } from '../../../utils/timestamp';
import { modeToNumber } from '../../../utils/acstatus';

type Props = {
    handleAnalyseData: Function;
    dates: DateOption;
    firstSelectedDateIndex:number;
    deviceNames:DeviceName;
    level:string
};
  
type State = {
    fetchedOnce: boolean;
    firstSelectedDateIndex: number;
    queryElapsedTime: number;
    loading: boolean;
    firstDate: [Date, Date];
    firstDateChanged: boolean;
    percentagePointsCount: number;
    temperaturePointsCount: number;
    firstEndDate: Date;
    chartDataMap: CtNetChartDataMap[];
    deviceNames:DeviceName;
};

type DatePiece = Date | null;

const defaultProps: Partial<Props> = {
    dates: [
        {id:0, label:'1 Hour', hours:1},
        {id:1, label:'3 Hours', hours:3},
        {id:2, label:'12 Hours', hours:12},
        {id:3, label:'1 Day', hours:24},
        {id:4, label:'3 Days', hours:24*3},
        {id:5, label:'1 Week', hours:24*7},
        {id:6, label:'2 Weeks', hours:24*7*2},
        {id:7, label:'4 Weeks', hours:24*7*4},
        {id:8, label:'2 Months', hours:24*(366/6)},
        {id:9, label:'3 Months', hours:24*(366/4)},
        {id:10, label:'6 Months', hours:24*(366/2)},
        {id:11, label:'1 Year', hours:24*365}
      ],
      firstSelectedDateIndex: 3,
    };
    

export default class CtNetAnalyseData extends Component<Props, State> {

    static defaultProps = defaultProps;
        
    constructor(props: Props) {
        super(props);
        this.handleAnalyseData = this.handleAnalyseData.bind(this);
        this.getFirstStartDate = this.getFirstStartDate.bind(this);
        this.runFirstDatabaseQuery = this.runFirstDatabaseQuery.bind(this);

        this.state = {
            fetchedOnce:false,
            firstSelectedDateIndex: 0,
            queryElapsedTime: 0,
            loading: false,
            firstEndDate:new Date(Date.now()),
            firstDate: [new Date(Date.now()), new Date(Date.now())],
            firstDateChanged: false,
            percentagePointsCount:1,
            temperaturePointsCount:1,
            chartDataMap:[
                {
                  name:"EM",
                  chartData:[
                    { timestamp: "2022-08-28T10:50:00.000Z", 
                        phase_1: 1,
                        phase_2:1,
                        phase_3: 1,
                        total:33.8,
                        sensor_battery: 3.5,
                    },
                    { timestamp: "2022-09-04T08:30:00.000Z", 
                        phase_1: 1,
                        phase_2:1,
                        phase_3: 1,
                        total:33.8,
                        sensor_battery: 3.5,
                    },
                  ]
                }
              ],
            deviceNames:this.props.deviceNames
        };
    }

    componentDidMount = async () => {
        this.getFirstStartDate(this.state.firstEndDate, this.props.firstSelectedDateIndex); 
      };

    handleAnalyseData() {
        const { fetchedOnce, chartDataMap } = this.state;
        this.props.handleAnalyseData(chartDataMap)
        .then(() => {
            if(fetchedOnce) {
                //do next action() or not
            }
        });
    };

    getFirstStartDate = (endDate:Date, selectedDateIndex:number) => {
        let startDate = new Date(endDate.getTime());
        startDate.setTime(startDate.getTime() - this.props.dates[selectedDateIndex].hours * 60 * 60 * 1000);
        console.log('getFirstStartDate startDate:' + startDate + ' endDate:' + endDate);
        this.setState({firstDate: [startDate, endDate], firstSelectedDateIndex:selectedDateIndex}, () => {
            this.runTwoDatabaseQuery();
        });
    };

    runTwoDatabaseQuery = async () => {
        const { deviceNames, level } = this.props;
        
        const { firstDate, fetchedOnce} = this.state;
        
        if(!fetchedOnce){

            const start = firstDate[0];
            const end = firstDate[1];
            const chartDataEntries: CtNetChartDataMap[] = [];
            const promises = [];

            for (const device of deviceNames) {
            const deviceId = device.name;
            let queryResult: HistoryICtNetData[] = [];
            
            const promise = LuminaireDataService.getDeviceTimeseries(level, deviceId, start, end)
                .get()
                .then((querySnapshot: any) => {
                    querySnapshot.forEach((doc:any) => {
                    queryResult.push(doc.data());
                });
                })
                .then(() => {
                const newEntry = {
                    name: deviceId,
                    chartData: this.flattenObjects(queryResult),
                };
                chartDataEntries.push(newEntry);
                })
                .catch((error) => {
                console.log("Error getting documents: ", error);
                });
            
                promises.push(promise);
            }

            Promise.all(promises)
                .then(() => {
                    this.setState(
                    (prevState) => ({
                        // chartDataMap: [...(prevState.chartDataMap || []), ...chartDataEntries],
                        chartDataMap: chartDataEntries,
                    }),
                    () => {
                        this.handleAnalyseData();
                    }
                    );
                })
            .catch((error) => {
                console.log("Error in Promise.all:", error);
            });
            this.setState({fetchedOnce:true});
        }
    }
      

    runFirstDatabaseQuery = async () => {
        const { firstDate } = this.state;
        this.setState({fetchedOnce:true});
        
        let queryResult: HistoryICtNetData[] = [];
        const start = firstDate[0];
        const end = firstDate[1];
        // const deviceId = this.state.deviceIdSelected;
        for(const device of this.props.deviceNames){
            const deviceId = device.name;
            console.log('runFirstDatabaseQuery deviceId:' + deviceId + ' startDate:' + start + ' endDate:' + end);
            LuminaireDataService.getDeviceTimeseries(this.props.level, deviceId, start, end)
            .get()
            .then((querySnapshot: any) => {
                querySnapshot.forEach((doc:any) => {
                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());
                    queryResult.push(doc.data());
                });
            }).then(()=> {
                const newEntry = {
                    name: deviceId, // Use the deviceId as the name
                    chartData: this.flattenObjects(queryResult),
                };

                console.log("runFirstDatabaseQueryResult.length:"+ queryResult.length)
                this.setState(
                    (prevState) => {
                      console.log('Previous State:', prevState);
                      console.log('New Entry:', newEntry);
                      return {
                        chartDataMap: [...(prevState.chartDataMap || []), newEntry],
                      };
                    },
                    () => {
                    //   this.handleAnalyseData();
                    }
                  );
               
            }).catch((error) => {
                console.log("Error getting documents: ", error);
            });
        }
  
    };

    flattenObjects(historyObjectArray: HistoryICtNetData[]){
        // console.log('flattenObjects');
        let chartData: PureCtNetChartData = historyObjectArray.map(item => ({
            timestamp: secondsToIso(item.timestamp.seconds),
            phase_1: item.points.phase_1?.present_value ?? 0,
            phase_2: item.points.phase_2?.present_value ?? 0,
            phase_3: item.points.phase_3?.present_value ?? 0,
            total: item.points.total?.present_value ?? 0,
            sensor_battery: item.points.sensor_battery?.present_value ?? 0,
          }));
        //   console.log('flattenObjects' + chartData.length);
        return chartData;
    };

    fixNegativeValue(value:number){
        return (value < 0) ? value * -1 : value;
    }
    render() {
        return(
            <div></div>
        );
    }
}