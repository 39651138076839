import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';
import { BuildingState } from '../../../redux/types/building.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../../components/redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
    Buildings: BuildingState;
};

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

const MVHRDashboard: React.FC<Props> = ({Buildings}) => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Mechanical Ventilation Heat Recovery Unit"
                        subheading="Mechanical Ventilation Heat Recovery (MVHRs) units are a system that provides continuous fresh air to a building while recovering heat from the outgoing stale air. It uses a heat exchanger to transfer thermal energy from the warm exhaust air to the cooler incoming air without mixing the two air streams. This process reduces the energy required to heat or cool the incoming air, improving energy efficiency and indoor air quality. MVHR systems are commonly used in energy-efficient buildings to maintain a comfortable indoor environment while minimizing heating and cooling costs."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 buildings={Buildings}/>
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

// // const mapStateToProps = (state: StoreState.All): ArticleState => {
// //     return {
// //       state: state
// //     };
// //   };

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
        Buildings: state.Buildings
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <MVHRDashboard {...props} />;
});