import React, { Component, useEffect } from "react";

import { IoIosCalendar } from "react-icons/io";

import PerfectScrollbar from "react-perfect-scrollbar";
import {
    Dropdown,
    Nav,
    Col,
    Row,
    Button,
    NavItem,
    ButtonGroup,
    OverlayTrigger,
    Tooltip
  } from "react-bootstrap";

import '../../../../assets/overides/header/user-box.scss'

  
import { toast, Bounce } from "react-toastify";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { auth } from "../../../firebase";

//TODO signout
// import SignOutButton from './SignOut'

import city3 from "../../../../assets/utils/images/dropdown-header/city3.jpg";
import avatar1 from "../../../../assets/utils/images/avatars/0.jpg";

import UserDataService from "../../../firebase/services/user.service";
import firebase from "firebase/compat/app";

type Props = {
    name?: string;
    user: firebase.User | null
}
  
type State = {
    active: boolean;
    toastId: any | undefined;
    username: string;
    job_role:string;
};

export default class UserBox extends Component<Props, State> {
    unsubscribe: () => void;
  
    constructor(props: Props) {
      super(props);

      this.state = {
        active: false,
        toastId: undefined,
        username: "", // Default value
        job_role: "Engineer" // Default value
      };
  
      this.unsubscribe = () => { };
    }

    signOut = async () => {
      await auth.signOut();
    };

    componentDidMount() {
      if (this.props.user && this.props.user.uid) {
        UserDataService.getDetailsByUserId(this.props.user.uid)
          .then(userDetails => {
            this.setState({
              username: userDetails.username,
              job_role: userDetails.job_role
            });
          })
          .catch(error => {
            console.error('Error fetching user details:', error);
          });
      } else {
        console.log('User or User UID is undefined');
      }
    }

    notify2 = () => {
        const toastId = toast("You don't have any new items in your calendar for today! Go out and play!", {
          transition: Bounce,
          closeButton: true,
          autoClose: 5000,
          position: "bottom-center",
          type: "success",
        });
    
        this.setState({ toastId });
      };
    
    render() {
        const { username, job_role } = this.state;
        return (
          <>
            <div className="header-btn-lg pr-0">
              <div className="widget-content p-0">
                <div className="widget-content-wrapper">
                  <div className="widget-content-left">
                  <Dropdown className="userBox" as={ButtonGroup}>
                      <Dropdown.Toggle color="link" className="p-0">
                        <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                        <FontAwesomeIcon
                          className="ml-2 opacity-8"
                          icon={faAngleDown}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end" className="rm-pointers dropdown-menu-lg">
                        <div className="dropdown-menu-header">
                          <div className="dropdown-menu-header-inner bg-info">
                            <div className="menu-header-image opacity-2"
                              style={{
                                backgroundImage: "url(" + city3 + ")",
                              }}/>
                            <div className="menu-header-content text-left">
                              <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                  <div className="widget-content-left mr-3">
                                    <img width={42} className="rounded-circle" src={avatar1} alt=""/>
                                  </div>
                                  <div className="widget-content-left">
                                    <div className="widget-heading">
                                      {username}
                                    </div>
                                    <div className="widget-subheading opacity-8">
                                      With great power there must also come great responsibility!
                                    </div>
                                  </div>
                                  <div className="widget-content-right mr-2">
                                    {/* <SignOutButton/> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="scroll-area-xs"
                          style={{
                            height: "150px",
                          }}>
                          <PerfectScrollbar>
                            <Nav>
                              <NavItem className="nav-item-header">
                                Activity
                              </NavItem>
                              <NavItem>
                                <Nav.Link href="#/applications/chat">
                                  Chat
                                  <div className="ml-auto badge badge-pill badge-info">
                                    0
                                  </div>
                                </Nav.Link>
                              </NavItem>
                              <NavItem>
                                <Nav.Link href="#" onClick={() => {
                                          this.signOut();
                                        }}>Sign Out</Nav.Link>
                                
                              </NavItem>
                              <NavItem className="nav-item-header">
                                My Account
                              </NavItem>
                              <NavItem>
                                <Nav.Link href="#">
                                  Settings
                                  {/* <div className="ml-auto badge badge-success">
                                    New
                                  </div> */}
                                </Nav.Link>
                              </NavItem>
                              <NavItem>
                                <Nav.Link href="#">
                                  Messages
                                  <div className="ml-auto badge badge-warning">
                                    0
                                  </div>
                                </Nav.Link>
                              </NavItem>
                              {/* <NavItem>
                                <Nav.Link href="#">Logs</Nav.Link>
                              </NavItem> */}
                            </Nav>
                          </PerfectScrollbar>
                        </div>
                        {/* <Nav>
                          <NavItem className="nav-item-divider mb-0" />
                        </Nav> */}
                        {/* <div className="grid-menu grid-menu-2col">
                          <Row className="no-gutters">
                            <Col sm="6">
                              <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                variant="outline" color="warning" href="#/applications/chat">
                                <i className="pe-7s-chat icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> {" "} </i>
                                Message Inbox
                              </Button>
                            </Col>
                            <Col sm="6">
                              <Button className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                variant="outline" color="danger" href="#/applications/tickets">
                                <i className="pe-7s-ticket icon-gradient bg-love-kiss btn-icon-wrapper mb-2"> {" "} </i>
                                <b>Support Tickets</b>
                              </Button>
                            </Col>
                          </Row>
                        </div> */}
                        {/* <Nav>
                          <NavItem className="nav-item-divider" />
                          <NavItem className="nav-item-btn text-center">
                            <Button size="sm" className="btn-wide" color="primary">
                              Open Messages
                            </Button>
                          </NavItem>
                        </Nav> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="widget-content-left  ml-3 header-user-info">
                    <div className="widget-heading">{username}</div>
                    <div className="widget-subheading">{job_role}</div>
                  </div>
                  <div className="widget-content-right header-user-info ml-3">
                    <Button className="btn-shadow p-1" size="sm" onClick={this.notify2} color="info" id="Tooltip-1">
                      <IoIosCalendar color="#ffffff" fontSize="20px" />
                    </Button>
                    <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                            <Tooltip id="Tooltip-1">
                            Schedule Maintenance
                            </Tooltip>
                        }>
                        <button id="Tooltip-1">calendar</button>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
    }
}