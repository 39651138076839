import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './AiMenu.module.scss'; // Replace with the actual path to your CSS module
import { NavMenuItem } from './NavItems';

import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { StoreState } from '../../redux/types/store.type';

import {ThemeOptionsState} from "../../redux/types/themeOptions.type";

type MapStateToPropsType = {
  ThemeOptions: ThemeOptionsState;
};

interface OwnProps {
    content: NavMenuItem[];
    onSelected: (enable: boolean) => void;
    className?: string;
    classNameStateIcon?: string;
    open: boolean;
}

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

const Menu: React.FC<Props> = ({ ThemeOptions, open, content, onSelected, className, classNameStateIcon }) => {
    useEffect(() => {
        // onSelected(open);
      }, [open, onSelected]);
    
      const toggleOpen = () => {
        onSelected(!open);
      };

  const renderMenuItems = (menuItems: NavMenuItem[]) => {
    return menuItems.map((item: NavMenuItem) => {
        const iconElement = item.icon ? <i className={`${item.icon} ${styles.icon}`}></i> : null;
      if (item.content) {
        return (
        // <div key={item.label} onClick={() => onSelected(item)}>
          <div key={item.label} >
            
            <span className={styles.metismenulink} onClick={toggleOpen}>
              {iconElement} {ThemeOptions.enableClosedSidebar && <br/>} {ThemeOptions.enableClosedSidebar ? <> {item.labelAbreviation} </>: <>{item.label}</>}
            </span>
            {open && <div className={styles.subMenu}>{renderMenuItems(item.content)}</div>}
          </div>
        );
      }

      if(item.to !== undefined){
      return (
        <NavLink key={item.label} className={styles.metismenulink} to={item.to}>
          
          {/* <p>subheading</p> */}
          <span className={styles.linkText}>{iconElement} {ThemeOptions.enableClosedSidebar ? <><br/> {item.labelAbreviation} </>: <>{item.label} </>} </span>
        </NavLink>
      );
      }
    });
  };

  return (
    <div className={!ThemeOptions.enableClosedSidebar ? styles.metismenu : styles.aiMenuClosed}>
      {renderMenuItems(content)}
    </div>
  );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
  return {
    ThemeOptions: state.ThemeOptions,
  };
};
const connector = connect(mapStateToProps);

export default connector((props: Props) => {
  return <Menu {...props}/>;
});
// export default Menu;
