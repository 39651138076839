import { LabelComponentProps } from "../../types/draggable-component";

function Label({
  text = "Label12",
  className,
  fontSize = 12,
  color = "#374151",
}: LabelComponentProps) {
  return (
    <label
      style={{ fontSize, color }}
      className={`block font-medium mb-0 ${className}`}
      htmlFor="label"
    >
      {text}
    </label>
  );
}

export default Label;
