import { AssetAction, AssetState } from "../types/asset.type";
import * as actionTypes from "../types/asset.action.type";

const initialState: AssetState = {
  Types: {},
};

const AssetsReducer = (
  state: AssetState = initialState,
  action: AssetAction
): AssetState => {
  switch (action.type) {
    case actionTypes.GET_ASSET:
      return {
        ...state,
        Types: { ...action.Types },
      };
    default:
      return state;
  }
};

export default AssetsReducer;
