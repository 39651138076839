import { DropTargetMonitor, XYCoord } from "react-dnd";
import { ComponentProps } from "../../types/component";

export const calculateNewComponentPositionOnDrop = (
  monitor: DropTargetMonitor<ComponentProps, unknown>,
  containerRef: React.RefObject<HTMLDivElement>
): XYCoord => {
  const clientOffset = monitor.getClientOffset() as XYCoord;
  const initialClientOffset = monitor.getInitialClientOffset() as XYCoord;
  const initialSourceClientOffset =
    monitor.getInitialSourceClientOffset() as XYCoord;
  const containerPos = containerRef.current!.getBoundingClientRect() as XYCoord;
  const x =
    clientOffset.x -
    containerPos.x +
    (initialSourceClientOffset.x - initialClientOffset.x);
  const y =
    clientOffset.y -
    containerPos.y +
    (initialSourceClientOffset.y - initialClientOffset.y);
  return { x, y };
};
