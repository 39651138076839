import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";


import CountUp from "react-countup";

import styled from 'styled-components'
import { useTable, Column, useSortBy } from "react-table";

import CHL_TYPE_1 from '../../BMSComponents/components/chl_type_1';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import ChlDataService from "../../../../firebase/services/chl.service";
import CallbackQueue from "../../../../firebase/services/callback.service";
import {ExIChlData, ExIChlMetaData, ExExtendedIChlData} from '../../../../firebase/types/chl.type';
import {ChlChartData, ChlChartDisplay} from '../../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen } from '../../../../utils/timestamp';
import ChlFilterData from '../../../components/chlFilterData';

import { BuildingState } from '../../../../redux/types/building.type';

function Chl_Type_1_DeviceHolder () {
  let data = {
    "chiller_removed_from_sequence": {
      "present_value":  "false"
    },
    "chiller_enable_status": {
      "present_value":  "false"
    },
    "isolation_valve_failed": {
      "present_value":  "false"
    },
    "percentage_total_capacity": {
      "present_value":  0
    },
    "entering_water_temp": {
      "present_value":  9.78
    },
    "leaving_water_temp": {
      "present_value":  10.06
    },
    "chiller_shutdown_alarm": {
      "present_value":  "false"
    },
    "isolation_valve_open": {
      "present_value":  "false"
    },
    "isolation_valve_enable": {
      "present_value":  "false"
    },
    "chiller_enabled": {
      "present_value":  "false"
    },
    "chiller_required": {
      "present_value":  "false"
    },
    "status": {
      "present_value":  "true"
    },
    "partial_alarm": {
      "present_value":  "false"
    },
    "shutdown_alarm": {
      "present_value":  "false"
    },
    "suction_pressure_a": {
      "present_value":  316.59
    },
    "suction_pressure_b": {
      "present_value":  325.01
    },
    "discharge_pressure_a": {
      "present_value":  344.51
    },
    "discharge_pressure_b": {
      "present_value":  349.78
    },
    "machine_operation_hours": {
      "present_value":  16903
    },
    "machine_starts": {
      "present_value":  33865
    },
    "pump_duty": {
      "present_value":  2
    },
    "pump_flow_proving": {
      "present_value":  "false"
    },
    "compressor_a_hours_run": {
      "present_value":  8561
    },
    "compressor_a_starts": {
      "present_value":  17926
    },
    "compressor_b_hours_run": {
      "present_value":  10897
    },
    "compressor_b_starts": {
      "present_value":  17693
    },
    "chiller_fan_a1_hours_run": {
      "present_value":  8486.25
    },
    "chiller_fan_a2_hours_run": {
      "present_value":  8185.25
    },
    "chiller_fan_a3_hours_run": {
      "present_value":  7747.5
    },
    "chiller_fan_a4_hours_run": {
      "present_value":  7035.75
    },
    "chiller_fan_a5_hours_run": {
      "present_value":  6574.75
    },
    "chiller_fan_a6_hours_run": {
      "present_value":  6012.25
    },
    "chiller_fan_a7_hours_run": {
      "present_value":  8681.75
    },
    "chiller_fan_b1_hours_run": {
      "present_value":  10818
    },
    "chiller_fan_b2_hours_run": {
      "present_value":  10420.5
    },
    "chiller_fan_b3_hours_run": {
      "present_value":  9761.75
    },
    "chiller_fan_b4_hours_run": {
      "present_value":  9155.25
    },
    "chiller_fan_b5_hours_run": {
      "present_value":  9869.5
    }
  };
return (
  
      <div className='chl-holder'>

        <CHL_TYPE_1 data={data}/>

      </div>

);
}

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  interface ReactTableProps<T extends object> {
    data: T[];
    columns: Column<T>[];
  }



  function ReactTable<T extends object>({ data, columns }: ReactTableProps<ExExtendedIChlData>) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid grey' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '2px solid grey' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '5px',
                    backgroundColor: '#545cd8',
                    border: '1px solid grey',
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: rowIndex !== rows.length - 1 ? '1px solid grey' : undefined }}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '5px',
                        backgroundColor: cellIndex % 2 === 0 ? 'white' : 'lightgrey',
                        textAlign: 'center'
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
    
    
  }
  
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
  `
  
  type Props = {
    buildings: BuildingState
  };

  type State = {
      flattenedPointset: Array<ExExtendedIChlData>,
      metadata: Array<ExIChlMetaData>,
      brickschema: {},
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: [],
      chartData: ChlChartData;
      chartDisplay: ChlChartDisplay;
  };

  const callbackQueue = new CallbackQueue()
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);
      

      this.state = {
        flattenedPointset: [],
        metadata: [],
        brickschema: {},
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        chartData:[
          { timestamp: "2021-09-11T04:00:00.000Z", 
            entering_water_temp:18.2,
            leaving_water_temp:18.0,
            percentage_total_capacity:0.0,
            suction_pressure_a:435.8,
            suction_pressure_b:437,
            discharge_pressure_a:460.4,
            discharge_pressure_b:473.5,
            machine_operation_hours:14737,
            machine_starts: 47637,
            compressor_a_hours_run: 9342,
            compressor_a_starts: 25696,
            compressor_b_hours_run:9342,
            compressor_b_starts: 23875
          },
          { timestamp: "2021-11-03T15:15:00.000Z", 
            entering_water_temp:12.8,
            leaving_water_temp:11,
            percentage_total_capacity:13,
            suction_pressure_a:310.60,
            suction_pressure_b:261.2,
            discharge_pressure_a:331.30,
            discharge_pressure_b:742.20,
            machine_operation_hours:15336,
            machine_starts: 47686,
            compressor_a_hours_run: 8830,
            compressor_a_starts:25781 ,
            compressor_b_hours_run:9780,
            compressor_b_starts:23934
          },
        ],
        chartDisplay:{
          entering_water_temp:true,
          leaving_water_temp:true,
          percentage_total_capacity:true,
          suction_pressure_a:true,
          suction_pressure_b:true,
          discharge_pressure_a:true,
          discharge_pressure_b:true,
          machine_operation_hours:false,
          machine_starts: false,
          compressor_a_hours_run: false,
          compressor_a_starts: false,
          compressor_b_hours_run:false,
          compressor_b_starts: false
        }
      };

      this.fetchTableData = this.fetchTableData.bind(this);
      this.handleFilterData = this.handleFilterData.bind(this);
      this.onDataChange = this.onDataChange.bind(this);

      this.unsubscribe = () => { };
    }
  
    componentDidUpdate(prevProps: Props) {
      if (this.props.buildings !== prevProps.buildings) {
          if (this.unsubscribe) {
              this.unsubscribe();
          }
          this.fetchTableData();
      }
    }

    componentDidMount() {
      this.fetchTableData();
    }
  
    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
    }
    }

    fetchTableData(){
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      let subscriptions: any = []
      for (const [BuildingName, BuildingObject] of Object.entries(this.props.buildings)) {
        for (const [LevelName, LevelObject] of Object.entries(BuildingObject)) {
          for (const deviceName of LevelObject.registries.device) {
            const pattern = /^ACC-\d+$/;
            if(pattern.test(deviceName)){
              const unsubscribe = ChlDataService.getDevice(deviceName).onSnapshot((snapshot) => {
                callbackQueue.enqueue(() => {
                  this.onDataChange(snapshot, deviceName);
                  return Promise.resolve();
                });
              });
              subscriptions.push(unsubscribe);
            }
          }
        }
      }
      this.unsubscribe = () => {
        for (const unsubscribe of subscriptions) {
            unsubscribe();
        }
      };
    }

    handleFilterData(chartData:ChlChartData, chartDisplay:ChlChartDisplay) {
      this.setState({
        chartData: chartData,
        chartDisplay: chartDisplay
      });
    }

    onDataChange(items: any, deviceName: string) {
      console.log("onDataChange");
  
      items.forEach((item: any) => {
          let id:string = item.id;
          let data = item.data();
          console.log(deviceName)
  
          if(id === 'pointset'){
              // Ensure that 'data.timestamp' and 'data.timestamp.seconds' exist
              if (data.timestamp && typeof data.timestamp.seconds === 'number') {
                  const dateMillis = data.timestamp.seconds * 1000
                  const deviceDate = new Date(dateMillis)
                  
                  const isoTimestamp = deviceDate.toISOString();
                  const formattedTimestamp = createHumanTimestamp(isoTimestamp);

                  const deviceOnline = isDeviceOnline(deviceDate);
                  const lastSeen = calculateLastSeen(dateMillis);
                  const lastSeenDays = lastSeen[0];
                  const lastSeenHours = lastSeen[1];
                  const lastSeenMins = lastSeen[2];
  
                  const flattenedPoint: ExExtendedIChlData = {
                      ...data.points as any,  
                      version: data.version,
                      name: { present_value: deviceName },  
                      timestamp: { present_value: formattedTimestamp },
                      online: {present_value: deviceOnline},
                      last_seen_days: {present_value: lastSeenDays},
                      last_seen_hours: {present_value: lastSeenHours},
                      last_seen_mins: {present_value: lastSeenMins}
                  };
  
                  this.setState(prevState => {
                    const index = prevState.flattenedPointset.findIndex(point => point.name.present_value === deviceName);
                    
                    if (index !== -1) {
                        // If the device exists in the array, create a new array with updated data for that device
                        const updatedPoints = [...prevState.flattenedPointset];
                        updatedPoints[index] = flattenedPoint;
                        return {
                            ...prevState,
                            flattenedPointset: updatedPoints
                        };
                    } else {
                        // If the device doesn't exist in the array, append it
                        return {
                            ...prevState,
                            flattenedPointset: [...prevState.flattenedPointset, flattenedPoint]
                        };
                    }
                });
              } else {
                  console.error('Timestamp data is missing or in incorrect format', data);
              }
          } else if(id === 'metadata'){
              // Handle metadata logic here
          }
      });
    }

    calculateTicks(data:ChlChartData, numTicks:number) {
      const step = Math.floor(data.length / numTicks);
      return data
        .filter((_, index) => index % step === 0)
        .map(item => item.timestamp);
    }

      render() {
        const { flattenedPointset, chartData, chartDisplay } = this.state;

        console.log(flattenedPointset);

        return (
            <>
        
        <Container fluid>
        <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Chiller Unit Graph Filter
                  </div>
                  {/* <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Settings</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                </Card.Header>
                <Card.Body>
                  <ChlFilterData
                    deviceId={'ACC-2'}
                    handleFilterData={this.handleFilterData}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>Chiller Temperature Timeseries</Card.Title>
                  <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
                    <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <XAxis 
                        dataKey="timestamp" 
                        tickFormatter={createHumanTimestamp}
                        // ticks={this.calculateTicks(chartData, 7)}
                        angle={-45} 
                        textAnchor="end"
                        height={120}
                      />
                      <YAxis label={{ value: "%", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 100]} interval={0} ticks={[0, 20, 40, 60, 80, 100]}/> 
                      <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 50]} interval={0}/>
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      {chartDisplay.percentage_total_capacity ? (<Line yAxisId="left" type="monotone" dataKey="percentage_total_capacity" stroke="#d0312d" activeDot={{ r: 8 }}/>) : null}
                      {chartDisplay.entering_water_temp ? (<Line yAxisId="right" type="monotone" dataKey="entering_water_temp" stroke="#fece78" activeDot={{ r: 8 }}/>) : null}
                      {chartDisplay.leaving_water_temp ? (<Line yAxisId="right" type="monotone" dataKey="leaving_water_temp" stroke="#82ca9d" />) : null}
                    </LineChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>Chiller Pressure Timeseries</Card.Title>
                  <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
                    <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <XAxis 
                        dataKey="timestamp" 
                        tickFormatter={createHumanTimestamp}
                        // ticks={this.calculateTicks(chartData, 7)}
                        angle={-45} 
                        textAnchor="end"
                        height={120}
                      />
                      <YAxis label={{ value: "Kpa", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 1200]} interval={0} ticks={[0, 200, 400, 600, 800, 1000]}/> 
                      {/* <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 40]} interval={0}/> */}
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip />
                      <Legend />
                      {chartDisplay.suction_pressure_a ? (<Line yAxisId="left" type="monotone" dataKey="suction_pressure_a" stroke="#d0312d" activeDot={{ r: 8 }}/>) : null}
                      {chartDisplay.suction_pressure_b ? (<Line yAxisId="left" type="monotone" dataKey="suction_pressure_b" stroke="#fece78" activeDot={{ r: 8 }}/>) : null}
                      {chartDisplay.discharge_pressure_a ? (<Line yAxisId="left" type="monotone" dataKey="discharge_pressure_a" stroke="#82ca9d" />) : null}
                      {chartDisplay.discharge_pressure_b ? (<Line yAxisId="left" type="monotone" dataKey="discharge_pressure_b" stroke="#3C0071" />) : null}
                    </LineChart>
                  </ResponsiveContainer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        <Row>
          <Col>
            <Chl_Type_1_DeviceHolder />
          </Col>
        </Row>
          <Row> 
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-warning border-warning">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={9.78} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">°C </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Entering Water Temp
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--warning)",
                            fill: "none",
                          }}/>
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-primary border-primary">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={10.06} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">°C </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Leaving Water Temp
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--primary)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-danger border-danger">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={316.59} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">kPa </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Suction Pressure A
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--danger)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-info border-info">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={325.01} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">kPa</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                    Suction Pressure B
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--info)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-secondary border-secondary">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={344.51} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">kPa</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                      Discharge Pressure A
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--secondary)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md="6" xl="2">
              <div className="card mb-3 widget-chart widget-chart2 text-left card-btm-border card-shadow-secondary border-secondary">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-content pt-3 pl-3 pb-1">
                    <div className="widget-chart-flex">
                      <div className="widget-numbers">
                        <div className="widget-chart-flex">
                          <div className="fsize-4">
                            
                            <CountUp start={0} end={349.78} separator="" decimals={2} decimal="." prefix="" duration={2}/>
                            <small className="opacity-5">kPa </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="widget-subheading mb-0 opacity-5">
                    Discharge Pressure B
                    </h6>
                  </div>
                  <Row className="no-gutters widget-chart-wrapper mt-3 mb-3 pl-2 he-auto">
                    <Col md="9">
                      <Sparklines data={sampleData3}>
                        <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            stroke: "var(--secondary)",
                            fill: "none",
                          }} />
                      </Sparklines>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Chiller System
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Actions</Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Generate Report</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                    <div className="-striped -highlight -fixed">
                  <ReactTable data={flattenedPointset}
                     columns={[
                      {
                        Header: "CHL",
                        columns: [
                          {
                            Header: "Online",
                            id: "deviceOnline",
                            accessor: (row: ExExtendedIChlData) => row.online.present_value,
                            Cell: ({ value }: { value: boolean }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                            width: 100,
                          },
                          {
                            Header: "Name",
                            id: "deviceName",
                            accessor: (row: ExExtendedIChlData) => row.name.present_value,
                            width: 100,
                          },
                          
                          // {
                          //   Header: "Alarm",
                          //   id: "ahuAlarm",
                          //   accessor: (row: ExtendedIChlData) => row.alarm.present_value,
                          //   width: 100,
                          // }
                        ]
                      },
                      {
                        Header: "Last Seen",
                        columns: [
                          {
                            Header: "Timestamp",
                            id: "deviceTimestamp",
                            accessor: (row: ExExtendedIChlData) => row.timestamp.present_value,
                            width: 200,
                          },
                          {
                            Header: "Days",
                            id: "deviceLastSeenDays",
                            accessor: (row: ExExtendedIChlData) => row.last_seen_days.present_value,
                            width: 100,
                          },
                          {
                            Header: "Hours",
                            id: "deviceLastSeenHours",
                            accessor: (row: ExExtendedIChlData) => row.last_seen_hours.present_value,
                            width: 100,
                          },
                          {
                            Header: "Mins",
                            id: "deviceLastSeenMins",
                            accessor: (row: ExExtendedIChlData) => row.last_seen_mins.present_value,
                            width: 100,
                          },
                        ]
                      },
                      {
                        Header: "System",
                        columns: [
                          {
                            Header: "Required",
                            id: "chillerRequired",
                            accessor: (row: ExExtendedIChlData) => row.chiller_required.present_value,
                            Cell: ({ value }: { value: string }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value === 'true' ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                          },
                          {
                            Header: "Removed Sequence",
                            id: "chillerRemoved",
                            accessor: (row: ExExtendedIChlData) => row.chiller_removed_from_sequence.present_value,
                            Cell: ({ value }: { value: string }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value === 'true' ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                          },
                          {
                            Header: "Enable",
                            id: "chillerEnable",
                            accessor: (row: ExExtendedIChlData) => row.chiller_enable_status.present_value,
                            Cell: ({ value }: { value: string }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value === 'true' ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                          },
                          {
                            Header: "Run",
                            id: "chillerEnabled",
                            accessor: (row: ExExtendedIChlData) => row.chiller_enabled.present_value,
                            Cell: ({ value }: { value: string }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value === 'true' ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                          },
                          {
                            Header: "Status",
                            id: "chillerStatus",
                            accessor: (row: ExExtendedIChlData) => row.status.present_value,
                            Cell: ({ value }: { value: string }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value === 'true' ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                          },
                        ],
                      },
                      {
                        Header: "Alarm",
                        columns: [
                          {
                            Header: "Partial",
                            id: "partialAlarmStatus",
                            accessor: (row: ExExtendedIChlData) =>
                              row.partial_alarm.present_value,
                          },
                          {
                            Header: "Shutdown",
                            id: "shutdownAlarmStatus",
                            accessor: (row: ExExtendedIChlData) =>
                              row.shutdown_alarm.present_value,
                          },
                          {
                            Header: "Chiller Shutdown",
                            id: "chillerShutdownAlarmStatus",
                            accessor: (row: ExExtendedIChlData) =>
                              row.chiller_shutdown_alarm.present_value,
                          },
                        ],
                      },
                    ]}
                    />
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Chiller Pointset
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Actions</Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Generate Report</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                    <div className="-striped -highlight -fixed">
                  <ReactTable data={flattenedPointset}
                     columns={[
                      {
                        Header: "CHL",
                        columns: [
                          {
                            Header: "Online",
                            id: "deviceOnline",
                            accessor: (row: ExExtendedIChlData) => row.online.present_value,
                            Cell: ({ value }: { value: boolean }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                            width: 100,
                          },
                          {
                            Header: "Name",
                            id: "deviceName",
                            accessor: (row: ExExtendedIChlData) => row.name.present_value,
                            width: 100,
                          },
                          
                          // {
                          //   Header: "Alarm",
                          //   id: "ahuAlarm",
                          //   accessor: (row: ExtendedIChlData) => row.alarm.present_value,
                          //   width: 100,
                          // }
                        ]
                      },
                      {
                        Header: "Last Seen",
                        columns: [
                          {
                            Header: "Timestamp",
                            id: "deviceTimestamp",
                            accessor: (row: ExExtendedIChlData) => row.timestamp.present_value,
                            width: 200,
                          },
                          {
                            Header: "Days",
                            id: "deviceLastSeenDays",
                            accessor: (row: ExExtendedIChlData) => row.last_seen_days.present_value,
                            width: 100,
                          },
                          {
                            Header: "Hours",
                            id: "deviceLastSeenHours",
                            accessor: (row: ExExtendedIChlData) => row.last_seen_hours.present_value,
                            width: 100,
                          },
                          {
                            Header: "Mins",
                            id: "deviceLastSeenMins",
                            accessor: (row: ExExtendedIChlData) => row.last_seen_mins.present_value,
                            width: 100,
                          },
                        ]
                      },
                      {
                        Header: "Entering Water Temperature",
                        columns: [
                          {
                            Header: "Sensor",
                            id: "enteringWaterTemperatureSensor",
                            accessor: (row: ExExtendedIChlData) =>
                              row.entering_water_temp.present_value + '°C',
                            width: 120,
                          },
                          // {
                          //   Header: "Setpoint",
                          //   id: "supplyAirTemperatureSetpoint",
                          //   accessor: (row: ExtendedIChlData) =>
                          //     row.supply_air_temperature_sp.present_value,
                          //   width: 120,
                          // },
                        ],
                      },
                      {
                        Header: "Leaving Water Temperature",
                        columns: [
                          {
                            Header: "Sensor",
                            id: "leavingWaterTemperatureSensor",
                            accessor: (row: ExExtendedIChlData) =>
                              row.leaving_water_temp.present_value + '°C',
                            width: 120,
                          },
                          // {
                          //   Header: "Setpoint",
                          //   id: "returnAirTemperatureSetpoint",
                          //   accessor: (row: ExExtendedIChlData) =>
                          //     row.return_temperature_setpoint.present_value + '°C',
                          //   width: 120,
                          // },
                        ],
                      },
                      {
                        Header: "Isolation Valve",
                        columns: [
                          {
                            Header: "Enable",
                            id: "isolationValveEnable",
                            accessor: (row: ExExtendedIChlData) =>
                              row.isolation_valve_enable.present_value,
                          },
                          {
                            Header: "Open",
                            id: "isolationValveOpen",
                            accessor: (row: ExExtendedIChlData) =>
                              row.isolation_valve_open.present_value,
                          },
                          {
                            Header: "Failed",
                            id: "isolationValveFailed",
                            accessor: (row: ExExtendedIChlData) =>
                              row.isolation_valve_failed.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Suction Pressure",
                        columns: [
                          {
                            Header: "A",
                            id: "suctionPressureA",
                            accessor: (row: ExExtendedIChlData) =>
                              row.suction_pressure_a.present_value + 'Kpa',
                          },
                          {
                            Header: "B",
                            id: "suctionPressureB",
                            accessor: (row: ExExtendedIChlData) =>
                              row.suction_pressure_b.present_value + 'Kpa',
                          },
                        ],
                      },
                      {
                        Header: "Discharge Pressure",
                        columns: [
                          {
                            Header: "A",
                            id: "dischargePressureA",
                            accessor: (row: ExExtendedIChlData) =>
                            row.discharge_pressure_a.present_value  + 'Kpa',
                          },
                          {
                            Header: "B",
                            id: "dischargePressureB",
                            accessor: (row: ExExtendedIChlData) =>
                            row.discharge_pressure_b.present_value  + 'Kpa',
                          },
                        ],
                      },
                      {
                        Header: "Machine Operation",
                        columns: [
                          {
                            Header: "Hours",
                            id: "machineOperationHours",
                            accessor: (row: ExExtendedIChlData) =>
                            row.machine_operation_hours.present_value + 'hr',
                          },
                          {
                            Header: "Starts",
                            id: "machineOperationStarts",
                            accessor: (row: ExExtendedIChlData) =>
                            row.machine_starts.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Pump",
                        columns: [
                          {
                            Header: "Duty",
                            id: "pumpDuty",
                            accessor: (row: ExExtendedIChlData) =>
                            row.pump_duty.present_value,
                          },
                          {
                            Header: "Flow Proving",
                            id: "pumpFlowProving",
                            accessor: (row: ExExtendedIChlData) =>
                            row.pump_flow_proving.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Compressor A",
                        columns: [
                          {
                            Header: "Hours",
                            id: "compressorAHours",
                            accessor: (row: ExExtendedIChlData) =>
                            row.machine_operation_hours.present_value + 'hr',
                          },
                          {
                            Header: "Starts",
                            id: "compressorAStarts",
                            accessor: (row: ExExtendedIChlData) =>
                            row.machine_starts.present_value,
                          },
                        ],
                      },
                      {
                        Header: "Compressor B",
                        columns: [
                          {
                            Header: "Hours",
                            id: "compressorBHours",
                            accessor: (row: ExExtendedIChlData) =>
                            row.machine_operation_hours.present_value + 'hr',
                          },
                          {
                            Header: "Starts",
                            id: "compressorBStarts",
                            accessor: (row: ExExtendedIChlData) =>
                            row.machine_starts.present_value ,
                          },
                        ],
                      },
                    ]}
                    />
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container> 
            </>
    );
  }
}