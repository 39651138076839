import {
  createContext,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import update from "immutability-helper";
import { useGraphicComponentsContext } from "../providers/graphic-components-provider";
import { useSelectorContext } from "../providers/selector-provider";
import { DraggableComponentProps } from "../../types/draggable-component";
import React from "react";
import { ItemMap } from "../../types/item-map";

interface Props {
  children?: React.ReactNode;
}

interface ContextProps {
  removeComponent: () => void;
}

const ComponentDeletionContext = createContext({
  removeComponent: () => {},
});

const ComponentDeletionProvider: FC<Props> = ({ children }) => {
  // Workaround to make selectedComponent and components accessible inside event handler
  const { selectedComponent: sc, setSelectedComponent: setSelComp } =
    useSelectorContext();
  const { components: comps, setComponents: setComps } =
    useGraphicComponentsContext();

  const [_selectedComponent, _setSelectedComponent] =
    useState<DraggableComponentProps | null>(sc);
  const [_components, _setComponents] = useState<ItemMap>(comps);

  const selectedComponent = useRef(_selectedComponent);
  const components = useRef(_components);
  const setSelectedComponent = (data: DraggableComponentProps | null) => {
    selectedComponent.current = data;
    _setSelectedComponent(data);
  };
  const setComponents = (data: ItemMap) => {
    components.current = data;
    _setComponents(data);
  };
  useEffect(() => {
    setSelectedComponent(sc);
    setComponents(comps);
  }, [sc, comps]);
  // END

  const [context] = useState<ContextProps>({ removeComponent });

  useEffect(() => {
    document.addEventListener("keypress", onKeyPress, true);
    return () => {
      document.removeEventListener("keypress", onKeyPress, true);
    };
  }, []);

  async function removeComponent() {
    if (!selectedComponent.current) return;
    await setComps(
      update(components.current, {
        $unset: [selectedComponent.current.id],
      })
    );
    setSelComp(null);
  }

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Backspace" && selectedComponent.current) {
      removeComponent();
    }
  };

  return (
    <ComponentDeletionContext.Provider value={context}>
      {children}
    </ComponentDeletionContext.Provider>
  );
};

export const useComponentDeletionContext = () =>
  useContext(ComponentDeletionContext);

export default ComponentDeletionProvider;
