import React, { Component } from "react";

import cx from "classnames";

type Props = {
    name?: string;
}
  
type State = {
    activeSearch: boolean;
};

export default class SearchBox extends Component<Props, State> {
    unsubscribe: () => void;
  
    constructor(props: Props) {
      super(props);

      this.state = {
        activeSearch: false,
      };
  
      this.unsubscribe = () => { };
    }

    render() {
        return (
          <>
          <div className={cx("search-wrapper", {
            active: this.state.activeSearch,
            })}>
            <div className="input-holder">
              <input type="text" className="search-input" placeholder="Type to search"/>
              <button onClick={() =>
                  this.setState({ activeSearch: !this.state.activeSearch })
                }
                className="search-icon">
                <span />
              </button>
            </div>
            <button onClick={() =>
                this.setState({ activeSearch: !this.state.activeSearch })
              }
              className="close"/>
          </div>
          </>
        );
    }
}