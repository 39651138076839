import { useDragLayer, XYCoord } from "react-dnd";
import { LabelTextfield, Label, TextField } from ".";
import { snapToGrid } from "../utilities/snap-to-grid";
import {
  ComponentTypes,
  DraggableComponentProps,
} from "../../types/draggable-component";

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null,
  isSnapToGrid: boolean
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  let { x, y } = currentOffset;

  if (isSnapToGrid) {
    x -= initialOffset.x;
    y -= initialOffset.y;
    const gridPos = snapToGrid(x, y);
    x = gridPos.x;
    y = gridPos.y;
    x += initialOffset.x;
    y += initialOffset.y;
  }

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const DragPreview = ({ snapToGrid = false }) => {
  const { /*itemType, isDragging,*/ item, initialOffset, currentOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem() as DraggableComponentProps,
      itemType: monitor.getItemType() as ComponentTypes,
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    }));

  function renderItem() {
    if (item) {
      switch (item.type) {
        case ComponentTypes.VERTICAL_LABEL_TEXTFIELD:
        case ComponentTypes.HORIZONTAL_LABEL_TEXTFIELD:
          return <LabelTextfield {...item} left={0} top={0} />;
        case ComponentTypes.LABEL:
          return <Label {...item} left={0} top={0} />;
        case ComponentTypes.TEXTFIELD:
          return <TextField {...item} left={0} top={0} />;

        default:
          return null;
      }
    }
  }

  return (
    <div className="fixed pointer-events-none z-50 left-0 top-0 w-full h-full">
      <div style={getItemStyles(initialOffset, currentOffset, snapToGrid)}>
        {renderItem()}
      </div>
    </div>
  );
};

export default DragPreview;
